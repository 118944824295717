import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Grid, makeStyles, TextField, Typography, Stepper, StepLabel, Step, Icon, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import classnames from 'classnames';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { showConfirmMessage, hideLoader, showErrorMessage, showLoader } from 'services/loader/actions';
import labelAdjectiveImg1 from '../../../images/counterfeit-toggle.png';
import LabelManagementApi from '../../../services/labelManagement/api';
import { updateLabelData } from 'services/labelManagement/actions';
import { PERMISSION_TYPE } from 'constants/modules';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: 'auto',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
    },
    titleContainer: {
        padding: theme.spacing(0, 0, 2)
    },
    titleText: {
        letterSpacing: '1px',
        fontSize: 18,
        fontWeight: '500'
    },
    itemRow: {
        padding: theme.spacing(2, 0),
        // borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
        '& .MuiTypography-caption': {
            color: theme.palette.almostBlack[600]
        }
    },
    inputFieldContainer: {
        // maxWidth: 360
    },
    logoRatioClass: {
        width: 320,
        height: 80,
        // margin: '0 auto'
    },
    activityTypeChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    stepperRoot: {
        padding: theme.spacing(0, 0, 3),
        '& .MuiStep-vertical': {
            height: 20,
            display: 'flex',
            '& .MuiStepLabel-root': {
                display: 'flex',
                alignItems: 'center',
                '& .MuiStepLabel-labelContainer': {
                    width: '250px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiStepLabel-label': {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }
            }
        },
        '& .MuiStepConnector-vertical': {
            padding: theme.spacing(0.5, 0),
            marginLeft: 9,
            '& .MuiStepConnector-lineVertical': {
                borderLeftStyle: 'dashed',
                borderLeftWidth: 2
            }
        }
    },
    stepIcon: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        boxSizing: 'border-box'
    },
    firstStep: {
        position: 'relative',
        '&::after': {
            content: '"DEFAULT"',
            fontSize: 11,
            color: theme.palette.almostBlack[600],
            position: 'absolute',
            right: '2px',
            bottom: '0px'
        },
        '& $stepIcon': {
            borderColor: theme.palette.secondary.main
        },
        '& .MuiStepLabel-labelContainer': {
            borderBottom: `1px solid ${theme.palette.almostBlack[400]}`
        }
    },
    finalStep: {
        position: 'relative',
        '&::after': {
            content: '"END STATUS"',
            fontSize: 11,
            color: theme.palette.almostBlack[600],
            position: 'absolute',
            right: '2px',
            bottom: '0px'
        },
        '& $stepIcon': {
            borderColor: theme.palette.error.main
        },
        '& .MuiStepLabel-labelContainer': {
            borderBottom: `1px solid ${theme.palette.almostBlack[400]}`
        }
    },
    buttonContainer: {
        width: 278
    },
    deleteJobStatusIcon: {
        '& .MuiIcon-root': {
            fontSize: '20px'
        }
    },
    infoSpan: {
        fontSize: '16px',
        color: theme.palette.error.light,
        marginLeft: theme.spacing(1),
        cursor: 'pointer'
    },
    popperImage: {
        objectFit: 'cover',
    }
}))

const JobStatuses = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {data, perms} = props;

    const isCreateAllowed = useMemo(()=>_.get(perms, PERMISSION_TYPE.CREATE, false), [perms]);
    const isEditAllowed = useMemo(()=>_.get(perms, PERMISSION_TYPE.EDIT, false), [perms]);
    const isDeleteAllowed = useMemo(()=>_.get(perms, PERMISSION_TYPE.DELETE, false), [perms]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditMode, setIsEditMode] = useState(true);

    const [jobStatuses, setJobStatuses] = useState([]);

    useEffect(()=>{
        setJobStatuses(_.get(data, 'job_status', []));
    },[data])

    const [dialogProps, setDialogProps] = useState({ options: undefined, successCallBack: undefined, title: undefined, open: false, currValue:'' });

    const handleAddNewJobStatus = (newJobStatus, newUpdatedArray) => {
        dispatch(showLoader('Adding job status'));
        LabelManagementApi.addJobStatus(newJobStatus).then(resp=>{
            dispatch(updateLabelData('job_status', [...jobStatuses, newJobStatus]))
            setJobStatuses([...jobStatuses, newJobStatus]);
            setDialogProps({ ...dialogProps, open: false, currValue: '' });
            dispatch(hideLoader());
        }).catch(err=>{
            dispatch(hideLoader());
            dispatch(showErrorMessage(`Something went Wrong!`, 'Close', ()=>dispatch(hideLoader())));
        })
    }

    const handleAddJobStatusBtnClick = () => {
        setDialogProps({ options: [...jobStatuses, 'Open', 'Closed'], successCallBack: handleAddNewJobStatus, open: true, title: 'Job Status', currValue:'' })
    }

    const handleClose = () => {
        setDialogProps({ options: undefined, successCallBack: undefined, title: undefined, open: false, currValue:'' })
        dispatch(hideLoader());
    };

    const manageDeleteAction = (v, list) => {
        dispatch(showLoader('Please wait...'))
        LabelManagementApi.deleteJobStatus(v).then(resp=>{
            let tempList = [...list];
            _.remove(tempList, i => i === v);
            setJobStatuses(tempList);
            dispatch(updateLabelData('job_status', tempList));
            dispatch(hideLoader());
        }).catch(err=>{
            dispatch(showErrorMessage('Something went wrong!', 'Close', ()=>dispatch(hideLoader())));
        })
    }

    const handleDeleteItem = (list, v, title) => {
        dispatch(showLoader('Please wait...'));
        LabelManagementApi.checkJobStatus(v).then(resp=>{
            if(_.isEqual(_.get(resp, 'data.status', ''), 'success')){
                dispatch(showConfirmMessage(
                    'Are you sure to delete the option "' + v + '" from ' + title + '?', 'Delete action is irreversible. So be cautionate of the action.', 
                    'Delete', () => manageDeleteAction(v, list),
                    'Cancel', () => dispatch(hideLoader())))
            }
            else{
                dispatch(showErrorMessage(`Cannot delete the option "${v}". ${_.get(resp, 'response.data.more_info', '')}`, 'Close', () => dispatch(hideLoader())))
            }
        }).catch(err=>{
            dispatch(showErrorMessage(`Cannot delete the option "${v}". ${_.get(err, 'response.data.more_info', '')}`, 'Close', () => dispatch(hideLoader())))
        })
    }

    const handleEditItem = (list, v, title) => {
        dispatch(showLoader('Please wait...'));
        LabelManagementApi.checkJobStatus(v).then(resp=>{
            if(_.isEqual(_.get(resp, 'data.status', ''), 'success')){
                setDialogProps({ options: [...jobStatuses, 'Open', 'Closed'], successCallBack: handleAddNewJobStatus, open: true, title: 'Job Status', currValue: v})
            }
            else{
                dispatch(showErrorMessage(`Cannot edit the option "${v}". ${_.get(resp, 'response.data.more_info', '')}`, 'Close', () => dispatch(hideLoader())))
            }
        }).catch(err=>{
            dispatch(showErrorMessage(`Cannot edit the option "${v}". ${_.get(err, 'response.data.more_info', '')}`, 'Close', () => dispatch(hideLoader())))
        })
    }

    return (<Grid container item xs={12}>
        <Grid container item xs={12} lg={8} className={classes.root}>
            <Grid container alignItems="center" justify='flex-start'>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <Typography variant='subtitle1' className={classes.titleText}>Job Statuses</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} container justify='flex-end'>
                    {
                        isCreateAllowed
                            ? <Grid container className={classes.buttonContainer} justify="flex-end" alignItems="center">
                                <Button variant="contained" size='small' color="primary" onClick={handleAddJobStatusBtnClick} disableElevation startIcon={<Icon>add</Icon>}>Add New</Button>
                            </Grid>
                            : null
                    }
                    {/* {
                        isEditMode
                            ? <>
                                <Button disableElevation variant='outlined' size='small' color='primary' style={{ marginRight: 12 }} onClick={() => setIsEditMode(false)}>Cancel</Button>
                                <Button disableElevation variant='contained' size='small' color='primary'>Save</Button>
                            </>
                            : <Button disableElevation variant='contained' size='small' color='primary' onClick={() => setIsEditMode(true)}>Edit</Button>
                    } */}
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={12} lg={8} className={classnames(classes.root)}>

            <Grid container alignItems='flex-start' className={classes.itemRow}>
                <Stepper orientation='vertical' className={classes.stepperRoot}>
                    <Step completed>
                        <StepLabel icon={<div className={classes.stepIcon} />} className={classes.firstStep}>Open</StepLabel>
                    </Step>

                    {
                        jobStatuses.map((item, idx) => (
                            <Step completed key={item + idx}>
                                <StepLabel icon={<div className={classes.stepIcon} />}>
                                    {item}
                                    <span>
                                      { isEditAllowed && <IconButton size='small' className={classes.deleteJobStatusIcon} onClick={() => handleEditItem(jobStatuses, item, 'Job Status', setJobStatuses)} title='Edit'><Icon>edit</Icon></IconButton>}
                                      { isDeleteAllowed && <IconButton size='small' className={classes.deleteJobStatusIcon} onClick={() => handleDeleteItem(jobStatuses, item, 'Job Status', setJobStatuses)} title='Delete'><Icon>delete</Icon></IconButton>}
                                    </span> 
                                </StepLabel>
                            </Step>
                        ))
                    }

                    <Step completed>
                        <StepLabel icon={<div className={classes.stepIcon} />} className={classes.finalStep}>Closed</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Paper>
                    <img src={labelAdjectiveImg1} className={classes.popperImage} alt='Example Image for Label Adjective' />
                </Paper>
            </ClickAwayListener>
        </Popper>
        {
            dialogProps && dialogProps.open && <AddActivityTypeDialog open={dialogProps.open} handleClose={handleClose} activityTypes={dialogProps.options} title={dialogProps.title} successCallback={dialogProps.successCallBack} currValue={_.get(dialogProps, 'currValue', '')} />
        }

    </Grid>);
}

export default JobStatuses;

const AddActivityTypeDialog = (props) => {

    const { open, handleClose, activityTypes, successCallback, title, currValue } = props;
    const [error, setError] = useState('');
    const [value, setValue] = useState(currValue);

    const isAlreadyAvailable = (v) => !_.isEmpty(activityTypes.filter(item => _.isEqual(_.lowerCase(item).replace(/\s+/g, ''), _.lowerCase(v).replace(/\s+/g, ''))));

    const isFirstTime = useRef(true);

    const validate = useCallback(() => {
        if (!isFirstTime.current) {
            if (_.isEmpty(value)) {
                setError(title + ' cannot be empty');
            }
            else {
                if (isAlreadyAvailable(value)) {
                    setError(title + ' cannot be duplicate')
                }
                else {
                    setError('')
                }
            }
        }
    }, [title, value, error, setError])

    useEffect(() => {
        if (!isFirstTime.current) {
            validate();
        } else {
            isFirstTime.current = false;
        }
    }, [value]);

    return (
        <Dialog open={open} onClose={handleClose} md={2} lg={2} fullWidth aria-labelledby="add-activity-form-dialog">
            <DialogTitle id='add-activity-form-dialog'>
                {_.isEmpty(currValue) ? `Add ${title}` : `Update ${title} - ${currValue}`}
            </DialogTitle>
            <DialogContent>
                <TextField autoFocus variant='outlined' margin='none' 
                    error={!_.isEmpty(error) && (!_.isEqual(currValue, value) && !_.isEmpty(currValue))} value={value} helperText={!_.isEqual(currValue, value) && !_.isEmpty(currValue) ? error : ''} size='small' onChange={e => setValue(e.target.value)} type='text' fullWidth />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={handleClose}>Cancel</Button>
                <Button color='primary' 
                    disabled={_.isEqual(currValue, value) && !_.isEmpty(currValue)}
                    onClick={(_.isEmpty(error) && !_.isEmpty(value)) ? () => { successCallback(value, [...activityTypes, value]); handleClose(); } : () => null}>
                        {_.isEmpty(currValue) ? 'Add' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}