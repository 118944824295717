import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Icon, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import BasicDetails from './BasicDetails';
import ActivityTypes from './ActivityTypes';
import JobStatuses from './JobStatuses';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import { showLoader, hideLoader } from 'services/loader/actions';
import HomePageConfig from './HomeConfig';
import { usePermission } from 'hooks/usePermission';

const MODULES = {
  LABEL: 'label',
  ACTIVITY: 'activity',
  JOB: 'job',
  CONFIGURE_HOME: 'home'
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  tabs: {
    marginTop: theme.spacing(2)
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    minHeight: '42px',
    letterSpacing: '2px',
    textTransform: 'none',
    width: '220px',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  tabSelected: {
    background: `${theme.palette.primary.light}38`,
    borderRadius: '0 50px 50px 0',
    color: theme.palette.primary.dark
  },
  tabPanel: {
    width: `calc(100% - 220px)`
  },
  pageTitle:{
    marginLeft: '252px', // [tabRootWidth(220) + tabPanelHeadingPadding(24) + typographyPadding(8)]
    color:'#666',
    letterSpacing:'2px',
    padding: theme.spacing(2, 0, 0),
    '& span':{
        verticalAlign:'middle',
        fontSize:theme.typography.pxToRem(22)
    }
},
titleIcon:{
    marginRight:theme.spacing(1.5)
},

}));

const getModuleIdx = (moduleName) => {
  switch (moduleName) {
    case MODULES.LABEL:
      return 0;
    case MODULES.ACTIVITY:
      return 1;
    case MODULES.JOB:
      return 2;
    case MODULES.CONFIGURE_HOME:
      return 3;
    default:
      return -1;
  }
}

const getModuleNameByIdx = (moduleIdx) => {
  switch (moduleIdx) {
    case 0:
      return MODULES.LABEL;
    case 1:
      return MODULES.ACTIVITY;
    case 2:
      return MODULES.JOB;
    case 3:
      return MODULES.CONFIGURE_HOME;
    default:
      return undefined;
  }
}

const LabelManagement = ({ history, match, moduleName }) => {

  const module = _.get(match, 'params.module', MODULES.LABEL);
  const activeTabIdx = getModuleIdx(module);

  // const [labelData, setLabelData] = useState(undefined);
  const labelData = useSelector(state => _.get(state, 'labelManagement.data'));
  const isLoading = useSelector(state => _.get(state, 'labelManagement.isLoading'));
  const homeDashboardId = useSelector(state => _.get(state, 'user.userDetails.home_dashboard_id'))
  // const isError = useSelector(state => _.get(state, 'labelManagement.isError'));

  const appSettingsPerms = usePermission(moduleName);

  const classes = useStyles();
  const dispatch = useDispatch();
  const tabClasses = { root: classes.tabRoot, selected: classes.tabSelected }

  const onChangeTab = (e, tabIdx) => {
    history.push(`/appsettings/${getModuleNameByIdx(tabIdx)}`);
  }

  useEffect(()=>{
    if(isLoading){
      dispatch(showLoader('Please wait...'));
    }else{
      dispatch(hideLoader());
    }
  },[isLoading])

  return (
    <div className={classnames(classes.root, "page-content")}>
      <Grid container>
        <Grid item container xs={12}>
          <Typography variant="h5" className={classes.pageTitle}>
            <span className={classnames("material-icons", classes.titleIcon)}>miscellaneous_services</span>
            <span>App Settings</span>
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTabIdx}
            classes={{ indicator: classes.tabsIndicator }}
            className={classes.tabs}
            onChange={onChangeTab}
            aria-label="App Settings Tabs"
          >
            <Tab icon={<Icon>business</Icon>} classes={tabClasses} label="App Info" disableRipple></Tab>
            <Tab icon={<Icon>comment_bank</Icon>} classes={tabClasses} label="Activity Types" disableRipple></Tab>
            <Tab icon={<Icon>rule</Icon>} classes={tabClasses} label="Job Statuses" disableRipple></Tab>
            <Tab icon={<Icon>home</Icon>} classes={tabClasses} label="Configure Homepage" disableRipple></Tab>
          </Tabs>
          <TabPanel value={activeTabIdx} index={0} className={classes.tabPanel}>
            <BasicDetails perms={appSettingsPerms} data={labelData} />
          </TabPanel>
          <TabPanel value={activeTabIdx} index={1} className={classes.tabPanel}>
            <ActivityTypes perms={appSettingsPerms} data={labelData} />
          </TabPanel>
          <TabPanel value={activeTabIdx} index={2} className={classes.tabPanel}>
            <JobStatuses perms={appSettingsPerms} data={labelData} />
          </TabPanel>
          <TabPanel value={activeTabIdx} index={3} className={classes.tabPanel}>
            <HomePageConfig homeDashboardId={homeDashboardId} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default LabelManagement;