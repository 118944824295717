import React, { useState, useEffect, useRef} from 'react';
import { makeStyles, Grid, Typography, IconButton, Icon, Slide, useTheme, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FilterPanel from './FilterPanel';
import _ from 'lodash';
import DashBoardApi, { getFilterDefinitionFromUIFilters } from '../../services/dashboards/api.js';
import PopUpDashboardApi from '../../services/popUpDashboard/api.js';
import { updateDashboardInReducerAndFetchData } from '../../services/dashboards/actions';
import DataSourceApi from '../../services/datasource/api.js';
import { COMPONENT_NAME_FOR_FILTERS, API_ENDPOINTS } from '../../constants';
import classnames from 'classnames';
import { updatePopupDashboardInReducerAndFetchData } from 'services/popUpDashboard/actions';
import Axios from 'axios';
import NumberRangeFilter from './NumberRangeFilter';
import DateRangeFilter from './DateRangeFilter'

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        height: '100%',
        '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '& ::-webkit-scrollbar-thumb': {
            background: `${theme.palette.almostBlack[400]}3f`,
            height: '10px',
            borderRadius: '5px'
        },
        '&:hover ::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px',
            borderRadius: '5px'
        }
    },
    filterContainerGrid:{
        display: 'grid',
        gridTemplateRows: '40px calc(100% - 92px) 52px',
        background: theme.palette.almostBlack[0],
        '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '& ::-webkit-scrollbar-thumb': {
            background: `${theme.palette.almostBlack[400]}3f`,
            height: '10px',
            borderRadius: '5px'
        },
        '& :hover ::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px',
            borderRadius: '5px'
        }
    },
    filterHeaderSection:{
        height: 40,
        padding: theme.spacing(0,1)
    },
    filterFooterSection:{
        height: 52,
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.almostBlack[400]}`,
    },
    filterContainer: {
        background: theme.palette.almostBlack[0],
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(1, 2),
        // borderLeft: `1px solid ${theme.palette.almostBlack[400]}`,
        boxSizing: 'border-box',
        // position: 'absolute',
        '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '& ::-webkit-scrollbar-thumb': {
            background: `${theme.palette.almostBlack[400]}3f`,
            height: '10px',
            borderRadius: '5px'
        },
        '& :hover ::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px',
            borderRadius: '5px'
        }
    },
    filterPanelHeading: {
        fontSize: theme.typography.pxToRem(18),
        letterSpacing: 1,
        color: theme.palette.almostBlack[700]
    },
    noFiltersText: {
        width: '100%',
        textAlign: 'center',
        padding: theme.spacing(4, 0)
    }
}))

const InPageFilters = (props) => {

    const { open, refreshDashboardFxn, toggleFilterPanel, className, isPopUp, dashboardState } = props;
    const inPageFiltersReduxState = isPopUp ? _.get(dashboardState, 'popUpFilters', []) : dashboardState.inPageFilters; 
    const [inPageFilters, setInPageFilters] = useState(inPageFiltersReduxState);
    const dashboardDetails = dashboardState.details; 

    const [options, setOptions] = useState({});
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
    const [isFooterEnabled, setIsFooterEnabled] = useState(false);
    const filteredOptions = (items) => _.reduce(items, (acc, item) => { if (!_.isNil(item.column_data)) { acc.push(_.toString(item.column_data)) } return acc; }, [])

    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen, //2000,
        exit: theme.transitions.duration.leavingScreen,
    };

    const fetchSuggestions = (dataSourceId, columnName, actualKeyValue = "") => {
        let keyValue = _.isNumber(actualKeyValue) ? _.toString(actualKeyValue) : actualKeyValue;
        const offset = _.isNil(keyValue) ? 0 : keyValue.length === 0 ? _.get(options, columnName, []).length : 0;
        setIsLoadingSuggestions(true);
        DataSourceApi.searchDataSourceForColumnValues(dataSourceId, columnName, keyValue, offset, (data) => {
            if (keyValue === "") {
                let tempOptions = options; //Object.assign({}, options);
                // if (_.isNil(tempOptions[columnName]) || _.isEmpty(tempOptions[columnName])) {
                //     tempOptions[columnName] = [];
                // }
                if(_.isEmpty(_.get(tempOptions, columnName, undefined))){
                    tempOptions[columnName] = []
                }
                tempOptions[columnName] = [...filteredOptions(data.items, ...tempOptions[columnName])];
                setOptions(tempOptions);
                setIsLoadingSuggestions(false);
            }
            else {
                let tempOptions = Object.assign({}, options);
                if (_.isNil(tempOptions[columnName]) || _.isEmpty(tempOptions[columnName])) {
                    tempOptions[columnName] = [];
                }
                tempOptions[columnName] = [...filteredOptions(data.items, ...tempOptions[columnName])];
                setOptions(tempOptions);
                setIsLoadingSuggestions(false);
            }
        })
    }

    const _getInPageFilterDefinition = (ipFilters) => {
        return _.filter(ipFilters, (ipFilter) => !_.isEmpty(_.get(ipFilter, 'inPageFilters', [])))
            .map((inPageFilterObj) => ({
                ds_id: inPageFilterObj.dataSourceId,
                rules: inPageFilterObj.inPageFilters
            }
            ));
    }

    const makeFilterDefFromInPageFilters = (paramIPFilters) => {
        let tempParamFilter = Object.assign([], paramIPFilters);
        tempParamFilter.map((ipFilter, idx) => {
            ipFilter.filters = ipFilter.inPageFilters;
            return true;
        })
        let ipFilters = tempParamFilter;
        return ipFilters;
    }

    const getAvailDsIds = (graphDetailList) => {
        return _.uniq(_.map(graphDetailList, (graph) => _.get(graph, 'ds_id')));
     }

    const getDashboardDataForUpdateApiOnInPageFiltersChange = (pageFilters) => ({
        dashboard_name: _.get(dashboardDetails, 'dashboard_name', 'Untitled Dashboard'),
        dashboard_layout: _.get(dashboardDetails, 'dashboard_layout'),
        graphs: _.get(dashboardDetails, 'graphs'),
        [isPopUp ? 'popup_filters' : 'in_page_filters']: _getInPageFilterDefinition(pageFilters),
        filter_definitions: getFilterDefinitionFromUIFilters(makeFilterDefFromInPageFilters(pageFilters), getAvailDsIds(_.get(dashboardDetails, 'graph_details', [])), false)
    });

    const handleApplyFilter = async (dataSrcIdx, filterIdx, value, multiple = false, isSearchComponent = false, isClearing = false) => {
        let actualValue = [];
        if (isSearchComponent) {
            if(isClearing){
                actualValue = []
            }
            else{
                await Axios.post(API_ENDPOINTS.ELASTIC_SEARCH_API, { query: _.isArray(value) ? value[0] : value, doc_type: _.trim(inPageFilters[dataSrcIdx].inPageFilters[filterIdx].dataSourceName, '"'), retrieve_field: inPageFilters[dataSrcIdx].inPageFilters[filterIdx].elasticConfig }, { headers: { 'content-type': 'application/json' } }).then(resp => {
                    const result = _.get(resp, 'data.data.result');
                    //set something should not match with existing informations if results is empty
                    actualValue = _.isEmpty(result) ? ['தமிழ்🍚😋'] : result;
                }).catch(err => {
                    console.log(err);
                    actualValue = []
                })
            }
        }
        else {
            actualValue = value
        }
        const filters = handleFilterValueChange(dataSrcIdx, filterIdx, actualValue, multiple, value, isSearchComponent);
        setInPageFilters(filters);
    }

    const handleApply = () => {
        const dashBoardDetailsObj = Object.assign({}, getDashboardDataForUpdateApiOnInPageFiltersChange(inPageFilters));
        if(isPopUp){
            dispatch(updatePopupDashboardInReducerAndFetchData(inPageFilters));
            dispatch(PopUpDashboardApi.updatePopupDashboardFilters(dashboardDetails.dashboard_id, dashBoardDetailsObj, ()=>{
                refreshDashboardFxn();
            }, true))
        }
        else{
            dispatch(updateDashboardInReducerAndFetchData(inPageFilters));
            dispatch(DashBoardApi.updateDashboardFilters(dashboardDetails.dashboard_id, dashBoardDetailsObj, ()=>{
                refreshDashboardFxn();
            }, true))
        }
    }

    useEffect(() =>{
        if(_.isEqual(inPageFilters, inPageFiltersReduxState)){
            // console.log("yes they are equal");
            setIsFooterEnabled(false);
        }
        else{
            // console.log("no they arent equal");
            setIsFooterEnabled(true);
        }
    },[inPageFilters, inPageFiltersReduxState])

    const handleFilterValueChange = (dataSourceIdx, filterIdx, value, multiple, queryValue, isSearchComponent) => {

        let tempFilters = _.cloneDeep(inPageFilters);
        let filterObj = tempFilters[dataSourceIdx];
        let tempFilter = filterObj.inPageFilters[filterIdx];
        if (isSearchComponent) {
            tempFilter.query = _.isEmpty(queryValue) ? '' : queryValue;
        }
        // let value = _.isNumber(actualValue)?_.toString(actualValue) : actualValue;

        if (multiple && multiple === true) {
            if (tempFilter.value.indexOf(value) !== -1) {
                let tempArray = [...tempFilter.value];
                _.remove(tempArray, key => key === value);
                tempFilter.value = tempArray
            }
            else if (_.isNil(value)) {
                tempFilter.value = [];
            }
            else {
                tempFilter.value = [...tempFilter.value, value]
            }
        }
        else {
            if (_.isNil(value) || _.isEmpty(value) || value === '') {
                tempFilter.value = []
            }
            else {
                if (_.isArray(value)) {
                    tempFilter.value = value;
                }
                else {
                    tempFilter.value = [value];
                }
            }
        }


        return _.cloneDeep(tempFilters);

    }

    const initialOptionsFetchedList = useRef([])

    const renderFilters = () => {

        const filtersToRender = inPageFilters.map((filterObj, dataSourceIdx) => {
            return filterObj.inPageFilters.reduce((acc, filter, idx) => {
                if (!_.get(filter, 'isHidden', true)) {
                    
                    if (filter.component === COMPONENT_NAME_FOR_FILTERS.DROPDOWN.id) {
                        const optionsToRender = _.filter(_.concat(_.get(filter, 'options', []), options[_.get(filter, 'field_name', "")], _.get(filter, 'value', [])), o => !_.isNil(o))
                        if(initialOptionsFetchedList.current.indexOf(_.get(filter, 'field_name','')) === -1){
                            fetchSuggestions(filterObj.dataSourceId, _.get(filter, 'field_name', ''), '');
                            initialOptionsFetchedList.current.push(_.get(filter, 'field_name',''));
                        }
                        acc.push(<FilterPanel key={filterObj.dataSourceId + idx} title={_.get(filter, 'filterName', 'Filter Name')} filterDesc={_.get(filter, 'filterDesc', '')} handleSearch={(e) => fetchSuggestions(filterObj.dataSourceId, _.get(filter, 'field_name', ''), e)} isLoading={isLoadingSuggestions} options={optionsToRender} /* isSearchNeeded={_.get(filter, 'options', []).length > 5 ? true : false} */ isSearchNeeded={true} selectedOptions={_.get(filter, 'value', [])} handleCheckBoxClick={(e) => handleApplyFilter(dataSourceIdx, idx, e, true)} handleClearThisFilter={() => handleApplyFilter(dataSourceIdx, idx, [])} />)
                    }
                    else if (filter.component === COMPONENT_NAME_FOR_FILTERS.AUTOCOMPLETE.id) {
                        const optionsToRender = _.filter(_.concat(_.get(filter, 'options', []), options[_.get(filter, 'field_name', "")], _.get(filter, 'value', [])), o => !_.isNil(o))
                        acc.push(<FilterPanel key={filterObj.dataSourceId + idx} title={_.get(filter, 'filterName', 'Filter Name')} filterDesc={_.get(filter, 'filterDesc', '')} isSearchNeeded={true} options={optionsToRender} isLoading={isLoadingSuggestions} selectedOptions={_.get(filter, 'value', [])} handleSearch={(e) => fetchSuggestions(filterObj.dataSourceId, _.get(filter, 'field_name', ''), e)} handleCheckBoxClick={(e) => handleApplyFilter(dataSourceIdx, idx, e)} optionKey="value" textKey="text" renderAsChips={true} handleClearThisFilter={() => handleApplyFilter(dataSourceIdx, idx, [])} />)
                    }
                    else if (filter.component === COMPONENT_NAME_FOR_FILTERS.SEARCH.id) {
                        acc.push(<FilterPanel key={filterObj.dataSourceId + idx} title={_.get(filter, 'filterName', 'Filter Name')} filterDesc={_.get(filter, 'filterDesc', '')} isSearchNeeded={true} options={[]} isFetching={false} selectedOptions={_.get(filter, 'query', [])} handleSearch={(e) => null} handleCheckBoxClick={(e) => handleApplyFilter(dataSourceIdx, idx, e, false, true)} renderAsChips={true} isMultiple={false} handleClearThisFilter={() => handleApplyFilter(dataSourceIdx, idx, [], false, true, true)} />)
                    }
                    else if (filter.component === COMPONENT_NAME_FOR_FILTERS.NUMBER_INPUT.id) {
                        acc.push(<FilterPanel key={filterObj.dataSourceId + idx} title={_.get(filter, 'filterName', 'Filter Name')} filterCondition={_.get(filter, 'condition', '')} filterDesc={_.get(filter, 'filterDesc', '')} isSearchNeeded={true} options={[]} isFetching={false} selectedOptions={_.get(filter, 'value[0]', [])} handleSearch={() => null} handleCheckBoxClick={(e) => handleApplyFilter(dataSourceIdx, idx, e, false, false)} handleClearThisFilter={() => handleApplyFilter(dataSourceIdx, idx, [])} renderAsChips={true} isNumber={true} isMultiple={false} />)
                    }
                    else if (filter.component === COMPONENT_NAME_FOR_FILTERS.NUMBER_RANGE_INPUT.id) {
                        acc.push(<NumberRangeFilter key={filterObj.dataSourceId + idx} title={_.get(filter, 'filterName', 'Filter Name')} filterDesc={_.get(filter, 'filterDesc', '')} options={[]} isFetching={false} value={_.get(filter, 'value', [])} handleCheckBoxClick={e => handleApplyFilter(dataSourceIdx, idx, e, false, false)} handleClearThisFilter={() => handleApplyFilter(dataSourceIdx, idx, [])} />)
                    }
                    else if (filter.component === COMPONENT_NAME_FOR_FILTERS.DATE_RANGE_INPUT.id) {
                        acc.push(<DateRangeFilter key={filterObj.dataSourceId + idx} title={_.get(filter, 'filterName', 'Filter Name')} filterDesc={_.get(filter, 'filterDesc', '')} dataType={_.get(filter, 'dataType', "")} rangeSelected={_.get(filter, 'value', [])} onChangeCommitted={e => handleApplyFilter(dataSourceIdx, idx, e, false, false)} handleClearThisFilter={() => handleApplyFilter(dataSourceIdx, idx, [])} />)
                    }
                }
                return acc;
            }, [])
        })

        return _.isEmpty(filtersToRender[0]) ? <Typography variant="subtitle1" className={classes.noFiltersText}>No Filters Added</Typography> : filtersToRender;
    }

    if (open) {
        return (
            <Slide in={true} timeout={transitionDuration} style={{ transitionDelay: 0 }} direction="left" mountOnEnter unmountOnExit>
                <Grid container className={classnames(classes.filterContainerGrid, { [className]: !_.isNil(className) })}/*  direction='row' justify='space-between' */>
                    <Grid item xs={12} container alignItems='center' className={classes.filterHeaderSection}>
                        <Grid item xs={10}>
                            <Typography variant='h6' className={classes.filterPanelHeading}>Filters</Typography>
                        </Grid>
                        <Grid item xs={2} container justify='flex-end'>
                            <IconButton size='small' onClick={toggleFilterPanel}> <Icon fontSize='small' >arrow_forward</Icon> </IconButton>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container alignContent="flex-start" className={classes.filterContainer}>
                        {renderFilters()}
                    </Grid>
                    {
                        isFooterEnabled
                            ?   (
                                <Grid item xs={12} container alignItems='center' className={classes.filterFooterSection}>
                                    <Grid item xs={6} container justify='center' alignItems='center'>
                                        <Button variant="outlined" color="primary" size='medium' disableElevation onClick={()=>setInPageFilters(inPageFiltersReduxState)}>Cancel</Button>
                                    </Grid>
                                    <Grid item xs={6} container justify='center' alignItems='center'>
                                        <Button variant="contained" color="primary" size='medium' disableElevation onClick={handleApply}>Apply</Button>
                                    </Grid>
                                </Grid>
                            )
                            :   null
                    }
                    
                </Grid>
            </Slide>
        )
    }
    else {
        return null
    }

}

export default InPageFilters;
