import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import SearchBox from '../SearchBox';

const useStyles = makeStyles((theme)=>({
    pageHeader:{
        paddingTop:theme.spacing(2),
        marginBottom:theme.spacing(4),
        [theme.breakpoints.down('xs')]:{
            paddingTop:theme.spacing(0),
            marginBottom:theme.spacing(1)
        }
    },
    titleContainer:{
        display:'flex',
        alignItems:'center'
    },
    pageTitle:{
        color:'#666',
        letterSpacing:'2px',
        '& span':{
            verticalAlign:'middle',
            fontSize:theme.typography.pxToRem(22)
        }
    },
    titleIcon:{
        marginRight:theme.spacing(1.5)
    },
    staticContent:{
        marginBottom:theme.spacing(2)
    },
    actionContainer:{
        display: 'flex',
        justifyContent:"flex-end",
        '& Button':{
            marginLeft:theme.spacing(1)
        },
        '& > div':{
           marginTop:'2px'
        },
        [theme.breakpoints.down('xs')]:{
            marginTop: theme.spacing(2),
            justifyContent:"center",
            '& Button':{
                // maxWidth:400,
                marginLeft: theme.spacing(0),
                width:'100%'
            }
        }
    },
    searchAndAddGrid:{
        [theme.breakpoints.down('sm')]:{
            marginTop:theme.spacing(2)
        }
    },
    searchBarContainer:{
        padding: '0 !important',
        [theme.breakpoints.down('xs')]:{
            padding:'0 !important'
        }
    },
    searchBarWidth:{
        [theme.breakpoints.down('xs')]:{
            maxWidth: "none !important"
        }
    }
}));


const PageHeader = ({title, titleIcon, onChangeSearch, className, actions, children})=>{
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justify="center"
            className={classnames(classes.pageHeader, className)}
            alignItems="center">
            <Grid item xs={12} md={12} lg={12} className={classes.staticContent}>
                <Grid container>
                    <Grid item md={4} sm={12} className={classes.titleContainer}>
                        <Typography variant="h5" className={classes.pageTitle} component="div">
                            <span className={classnames("material-icons", classes.titleIcon)}>{titleIcon}</span>
                            <span>{title}</span>
                        </Typography>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className={classes.searchAndAddGrid}>
                        <Grid container>
                            <Grid item md={6} sm={8} xs={12}>
                                <SearchBox placeholder="Search" searchBarClassName={classes.searchBarWidth} containerClassName={classes.searchBarContainer} onChange={onChangeSearch}></SearchBox>
                            </Grid>
                            <Grid item md={6} sm={4} xs={12} className={classes.actionContainer}>
                                <div>
                                   {actions}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
              children && 
              <Grid item xs={12} md={12} lg={12} sm={12}>
                  {children}
              </Grid>
            }
        </Grid>
    )
}

PageHeader.propTypes = {
    title:PropTypes.string.isRequired,
    titleIcon:PropTypes.string.isRequired,
    className:PropTypes.string,
    onChangeSearch:PropTypes.func,
    actions:PropTypes.node,
    children:PropTypes.node
}

export default PageHeader;