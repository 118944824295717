import React from 'react';
import { makeStyles, DialogTitle, DialogContent, Typography, Icon, IconButton, Drawer, Grid, TextField, MenuItem, Button } from '@material-ui/core';
import { DropzoneArea } from "material-ui-dropzone";
import { Formik } from "formik";
import * as Yup from "yup";
import {useSelector} from 'react-redux';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    muiDialogPaper: {
        // borderRadius: '20px 0px 0px 20px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
        width: 500,
        boxSizing: 'border-box',
        '& .MuiDialogContent-root': {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    closeButtonInDialog: {
        position: 'absolute',
        top: '6px',
        right: '6px'
    },
    inputContainer: {
        padding: theme.spacing(1),
        '& .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-8':{
            width: '100%'
        }
    },
    buttonInTitle: {
        // marginLeft: theme.spacing(2),
        // borderRadius: 8
    },
}));

const AddActivityForm = (props) => {

    const { open, onClose, onSubmit } = props;
    const classes = useStyles();

    const activityTypeOptions = useSelector(state=>_.get(state, 'labelManagement.data.activity_type',[]));
    const jobStatusOptions = useSelector(state=>_.get(state, 'labelManagement.data.job_status',[]));

    // const [type, setType] = useState("");
    // const handleTypeChange = (e) => {
    //     setType(e.target.value);
    // };

    // const [jobStatus, setJobStatus] = useState("");
    // const handleJobStatusChange = (e) => {
    //     setJobStatus(e.target.value);
    // };

    // const onFormSubmit = (data) => {
    //     console.log('data from formik', data)
    //     if(document !== 'undefined'){
    //         const form = document.getElementById('addActivityForm')
    //         const dataToBeAttached = new FormData(form);
    //         dataToBeAttached.append('product_id_list', productIdList);
    //         onSubmit(dataToBeAttached)
    //     }
    // }

    const initialValues = {
        activity_name: '',
        activity_type: '',
        job_status: '',
        comments: '',
        file: []
    }

    const validationSchema = Yup.object().shape({
        activity_name: Yup.string().required("Activity Name is Required"),
        activity_type: Yup.string().required("Activity Type is Required"),
        job_status: Yup.string().required("Job Status is Required"),
        comments: Yup.string().required("Comments is Required"),
        file: Yup.array().required("Attach atleast a File"),
    })

    // const onSubmit = (v) => {
    //     console.log(v)
    // }

    return (<>
        {/* <Dialog open={open} onClose={onClose} classes={{ paper: classes.muiDialogPaper }}>

            <DialogTitle>
                <Typography variant='h4'>Title</Typography>
                <Typography variant='h5'>Sub Title</Typography>
            </DialogTitle>


            <IconButton onClick={onClose} size='small' className={classes.closeButtonInDialog}>
                <Icon>close</Icon>
            </IconButton>
        </Dialog> */}

        <Drawer anchor='right' open={open} classes={{ paper: classes.muiDialogPaper }} disableBackdropClick onClose={onClose} variant='temporary'>
            <DialogTitle disableTypography>
                <Typography variant='h4'>Add Activity</Typography>
                {/* <Typography variant='h6'>Sub Title</Typography> */}
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {
                            props => {
                                const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
                                return (<>
                                    <Grid item xs={12} className={classes.inputContainer}>
                                        <TextField label="Activity Name" name='activity_name' value={values.activity_name} onChange={handleChange} onBlur={handleBlur} error={errors.activity_name && touched.activity_name} helperText={errors.activity_name && touched.activity_name && errors.activity_name} fullWidth variant="outlined"></TextField>
                                    </Grid>
                                    <Grid item xs={6} className={classes.inputContainer} >
                                        <TextField label="Activity Type" 
                                            name='activity_type' value={values.activity_type} onChange={handleChange} onBlur={handleBlur} error={errors.activity_type && touched.activity_type} helperText={errors.activity_type && touched.activity_type && errors.activity_type} 
                                        /* value={type} */ fullWidth select variant="outlined" /* onChange={(e)=>handleTypeChange(e, setFieldValue)} */>
                                            {
                                                activityTypeOptions.map((item, idx)=><MenuItem key={item+idx} value={item}>{_.startCase(item)}</MenuItem>)
                                            }
                                            {/* <MenuItem value="screenshot">Screenshot</MenuItem>
                                            <MenuItem value="image">Image</MenuItem>
                                            <MenuItem value="pdf">PDF</MenuItem>
                                            <MenuItem value="document">Document</MenuItem> */}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} className={classes.inputContainer} >
                                        <TextField label="Job Status"
                                         name='job_status' value={values.job_status} onChange={handleChange} onBlur={handleBlur} error={errors.job_status && touched.job_status} helperText={errors.job_status && touched.job_status && errors.job_status} 
                                         /* value={type} */ fullWidth select variant="outlined" /* onChange={(e)=>handleJobStatusChange(e, setFieldValue)} */
                                         >
                                             {
                                                ['open', ...jobStatusOptions, 'closed'].map((item, idx)=><MenuItem key={item+idx} value={item}>{_.startCase(item)}</MenuItem>)
                                            }
                                            {/* <MenuItem value="Investigation">Investigation</MenuItem> 
                                            <MenuItem value="CFA">CFA</MenuItem>
                                            <MenuItem value="NFA">NFA</MenuItem>
                                            <MenuItem value="URL Removed">URL Removed</MenuItem>
                                            <MenuItem value="Closed">Closed</MenuItem> */}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} className={classes.inputContainer} >
                                        <TextField label="Comments" fullWidth 
                                            name='comments' value={values.comments} onChange={handleChange} onBlur={handleBlur} error={errors.comments && touched.comments} helperText={errors.comments && touched.comments && errors.comments} 
                                            variant="outlined" multiline rows={4}></TextField>
                                    </Grid>
                                    <Grid item xs={12} className={classes.inputContainer}>
                                        <DropzoneArea
                                            name="file"
                                            // acceptedFiles={[
                                            //     "image/jpg",
                                            //     "image/png",
                                            //     "image/gif",
                                            //     "application/pdf"
                                            // ]}
                                            // filesLimit="1"
                                            // maxFileSize="2000000"
                                            showPreviewsInDropzone={true}
                                            dropzoneText={errors.file && touched.file ? "Attach atleast a file" : "Browse to find or drag file here"}
                                            onChange={(event) => {
                                                // setFieldValue('file', )
                                                const data = [];
                                                event.map((e, index) => {
                                                    // var reader = new FileReader();
                                                    // reader.readAsDataURL(event[index]);
                                                    // reader.onload = function (e) {
                                                    //     data.push(reader.result);
                                                    //     console.log('attached file', reader.result)
                                                    // };
                                                    data.push(e);
                                                    return true;
                                                })
                                                setFieldValue('file', data)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.inputContainer} container justify='center'>
                                        <Button variant='contained' disableElevation color='primary' onClick={handleSubmit} fullWidth className={classes.buttonInTitle}>Submit</Button>
                                    </Grid>
                                </>)
                            }
                        }
                    </Formik>
                </Grid>
            </DialogContent>


            <IconButton onClick={onClose} size='small' className={classes.closeButtonInDialog}>
                <Icon>close</Icon>
            </IconButton>
        </Drawer>

    </>)
}

export default AddActivityForm;