import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import SearchBox from '../SearchBox';
import {
    Grid, Button, Icon, Link, Collapse, Fab
} from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme)=>({
    headSection: {
        marginBottom: theme.spacing(1.5),
        '&[disabled]':{
            pointerEvents:'none',
            opacity:0.7
        },
        [theme.breakpoints.down('sm')]:{
            marginBottom: 0
        }
    },
    filterSection: {
      marginBottom: theme.spacing(1.5),
      '&[disabled]':{
        pointerEvents:'none',
        opacity:0.7
      }
    },
    toggleLink:{
        '& span':{
            verticalAlign:'middle'
        },
        [theme.breakpoints.down('sm')]:{
            paddingLeft:theme.spacing(1)
        }
    },
    titleText: {
        letterSpacing:1,
        color: theme.palette.almostBlack[700]
    },
    searchBar:{
        maxWidth:'unset!important',
    },
    searchContainer:{
        flex:1,
        [theme.breakpoints.up('md')]:{
            '& > .searchbar-container':{
               padding:(props)=> props.hideAddBtn ? "0" :'0 20px 0 0'
            }
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            flex:'unset',
            '& > .searchbar-container':{
                padding:'10px'
            },
        }
    },
    buttonContainer:{
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            marginBottom:theme.spacing(1),
            textAlign:'center',
            flex:'unset',
            display:'none'
        }
    },
    fab:{
        position:'fixed',
        zIndex:100,
        bottom:'24px',
        right:'24px',
        display:'none',
        [theme.breakpoints.down('sm')]:{
            display:'inline-flex'
        }
    }
}));

const ListPageFilterSection = props => {
    
    const {onChangeSearchBox, addBtnClick, addBtnTxt, disabled, searchPlaceholder, children, hideAddBtn} = props;
    const classes = useStyles({hideAddBtn});
    const [openAdvFilters, setOpenAdvFilters] = useState(false);

    const toggleAdvFilters =()=>{
        setOpenAdvFilters(!openAdvFilters);
    }

    return (
        <>
            <Grid container item xs={12} className={classes.headSection} disabled={disabled}>
                <div className={classes.searchContainer}>
                    <SearchBox onChange={onChangeSearchBox} searchBarClassName={classes.searchBar} placeholder={searchPlaceholder}></SearchBox>
                </div>
                <div className={classes.buttonContainer}>
                   { !hideAddBtn && <Button onClick={addBtnClick} color="primary" variant="contained" size="medium" startIcon={<i className="material-icons"> add </i>} disableElevation>
                        {addBtnTxt}
                    </Button> }
                </div>
            </Grid>
            <Grid item xs={12} className={classes.filterSection} disabled={disabled}>
                <Collapse in={openAdvFilters}>
                    {children}
                </Collapse>
                <Link href="#" onClick={toggleAdvFilters} variant="body2" className={classes.toggleLink}>
                    <Icon fontSize="small">{openAdvFilters ? "expand_more" : "expand_less"}</Icon> {openAdvFilters?"Hide":"Show"} advance filters
                </Link>
           </Grid>
           {!hideAddBtn && <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                <Fab
                    variant="round"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    className={classes.fab}
                    onClick={addBtnClick}
                    disabled={disabled}
                    >
                    <Icon fontSize="default">add</Icon>
                </Fab>
            </Zoom>}
        </>
    );
};

ListPageFilterSection.propTypes = {
    onChangeSearchBox:PropTypes.func.isRequired,
    addBtnClick:PropTypes.func.isRequired,
    addBtnTxt:PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    searchPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    hideAddBtn: PropTypes.bool
};

export default ListPageFilterSection;