import {put, call, takeLatest, takeEvery} from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../../../constants';
import { showSnackbarWithTimeout } from 'services/snackbar/actions';
import { showLoader, hideLoader, showErrorMessage } from '../../loader/actions';
import api from '../api';
import Axios from 'axios';
import { cancelled } from 'redux-saga/effects';
import { getErrMsg } from 'utils';

function* fetchRoles(action){
    const {filters, successCallback, errorCallback} = action.payload;
    const cancelSource = Axios.CancelToken.source();
    try{
        const req = yield call(api.fetchAllRoles, filters, cancelSource);
        yield call(successCallback, req);
    }
    catch(err){
        yield call(errorCallback, err);
    }
    finally{
        if(yield cancelled()){
            cancelSource.cancel('cancelled');
        }
    }
}

export function* fetchAllRolesWatcher(){
    yield takeLatest(SAGA_ACTIONS.FETCH_ALL_ROLES, fetchRoles);
}

function* createRole(action){
    yield put(showLoader("Please wait saving..."));
    const {roleDetails, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.createRole, roleDetails);
        yield put(hideLoader());
        yield call(successCallback, resp);
        yield put(showSnackbarWithTimeout('Saved Successfully!', 2500));
    }
    catch(err){
        yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* createRoleWatcher(){
    yield takeEvery(SAGA_ACTIONS.CREATE_ROLE, createRole);
}

function* fetchRole(action){
    yield put(showLoader("Please wait, getting role details..."));
    const {roleId, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.fetchRole, roleId);
        yield call(successCallback, resp);
        yield put(hideLoader());
        yield call(successCallback, resp);
    }
    catch(err){
       yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* fetchRoleWatcher(){
    yield takeEvery(SAGA_ACTIONS.FETCH_ROLE, fetchRole);
}

function* updateRole(action){
    yield put(showLoader("Please wait updating role..."));
    const {roleId, roleDetails, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.updateRole, roleId, roleDetails);
        yield put(hideLoader());
        yield call(successCallback, resp);
        yield put(showSnackbarWithTimeout('Updated Successfully!', 2500));
    }
    catch(err){
        yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* updateRoleWatcher(){
    yield takeEvery(SAGA_ACTIONS.UPDATE_ROLE, updateRole);
}


function* deleteRole(action){
    yield put(showLoader("Deleting role..."));
    const {roleId, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.deleteRole, roleId);
        yield call(successCallback, resp);
        yield put(hideLoader());
        yield call(successCallback, resp);
    }
    catch(err){
       yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* deleteRoleWatcher(){
    yield takeEvery(SAGA_ACTIONS.DELETE_ROLE, deleteRole);
}


//all the saga watchers
export default [fetchAllRolesWatcher(), createRoleWatcher(), fetchRoleWatcher(), updateRoleWatcher(), deleteRoleWatcher()];
