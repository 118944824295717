import React, {useState, useImperativeHandle} from 'react';
import _ from 'lodash';
import { Chip } from '@material-ui/core';

export const withCellRenderState = (CellRenderer) => {
    return React.forwardRef((props, ref) => {
         const [value, setValue] = useState(props.value);
         useImperativeHandle(ref, () => {
             return {
                 refresh: (params) => {
                     if (params.value !== value) {
                         setValue(params.value)
                     }
                     return true;
                 }
             };
         });
         return (
             <CellRenderer {...props}></CellRenderer>
         );
    });
 };
 
 export const ActionCellRenderer = (props) => {

     const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
     const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions');
 
     const isEditable = _.get(props, 'data.is_editable', true);

     const onClickAnchorEl = (event) => { 
         onClickMoreOptions(event, _.get(props, 'data', {}));
     }
 
     return (
        <div className={containerClassName}>
           {
             isEditable ? <a href="#no" onClick={onClickAnchorEl} title="Show Actions">
               <i className="material-icons">more_horiz</i>
             </a> : 
             <i className="material-icons">lock</i>
           }
        </div>
     )
 }
 
export const NameCellRenderer = (props) => {
     const {value, data} = props;
     const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
     const isDefaultRole = _.get(data, 'is_default', false);

     return (
        <div className={containerClassName} title={value}>
           {_.startCase(value)} {isDefaultRole && <Chip color="primary" size="small" label={"Default"} />}
        </div>
     )
 }
