import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import _ from 'lodash';
import { PAGE_MODES } from '../../constants';
import { Radio } from '@material-ui/core';
import classnames from 'classnames';

const initialState = {
  mouseX: null,
  mouseY: null,
  node:null
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path fill="currentColor" d="M19,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H19A2,2 0 0,1 21,8H21L4,8V18L6.14,10H23.21L20.93,18.5C20.7,19.37 19.92,20 19,20Z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path fill="currentColor" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H20M19.25,13H16V9H14V13H10.75L15,17.25" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path fill="currentColor" d="M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  // const style = useSpring({
  //   from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
  //   to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  // });

  return (
    // <animated.div style={style}>
      <Collapse {...props} />
    // </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  root:{
     '&.Mui-selected > .MuiTreeItem-content > .MuiTreeItem-label':{
       border:`1px solid ${theme.palette.primary.main}`
     }
  },
  iconContainer: {
    color:theme.palette.grey[500]
    // '& .close': {
    //   opacity: 0.3,
    // },
  },
  content:{
    padding:2,
    alignItems:"flex-start"
  },
  label:{
    userSelect: 'none',
    [theme.breakpoints.down('xs')]:{
      fontSize:14
    },
    fontSize:15
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  }
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
  root: {
    width:'100%',
    flexGrow: 1
  },
  radioBtn:{
    padding:0,
    marginRight:4
  },
  iconContainer:{
    width:40,
    justifyContent: 'flex-start'
  },
  treeItemRoot:{
    '&.Mui-selected > .MuiTreeItem-content > .MuiTreeItem-label':{
      border:'none'
    }
  }
});

const WrapRadioBtn = ({name, showRadioBtn=false, className, onClickRadio, value, checked, children})=>{
  return (
    <>
     {showRadioBtn && <Radio name={name} className={className} value={value} checked={checked} onClick={onClickRadio} />}
     {children}
    </>
  )
}

export default function TeamsTreeView({
  data, onNodeSelect, onNodeAction, showViewOption, name, defaultExpanded=[],
  isCreateAllowed, isEditAllowed, isDeleteAllowed, makeInput, value, onChange
}) {

  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  const expandedTreeList = useRef(defaultExpanded);

  const isShowContextMenu = showViewOption || isCreateAllowed || isEditAllowed || isDeleteAllowed;

  const handleClick = (event, node) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      node:node
    });
  };

  const handleClose = (e, actionName) => {
    setState((prevState) => {
      onNodeAction(prevState.node, actionName);
      return initialState
    });
  };

  const wrapRaioBtn = (icon, onClickRadio, value, checked = false, name) =>{
      return (
        <WrapRadioBtn 
          name={name}
          showRadioBtn={makeInput} 
          value={value} 
          onClickRadio={onClickRadio} 
          checked={checked}
          className={classes.radioBtn}>
          {icon}
        </WrapRadioBtn>
      )
  }

  const onClickRadioBtn = (e) =>{
     e.stopPropagation();
     onChange(e);
  }

  const renderTree = (nodes, onContextMenu, makeInput, fieldVal, name) => {

    const isRadioChecked = _.isEqual(fieldVal, nodes.id);

    return (
      <StyledTreeItem 
          key={nodes.id} 
          nodeId={nodes.id} 
          label={nodes.name} 
          classes={{
            root:classnames({[classes.treeItemRoot]: makeInput}),
            iconContainer:classnames({[classes.iconContainer]: makeInput})
          }}
          collapseIcon={wrapRaioBtn(<MinusSquare />, onClickRadioBtn, nodes.id, isRadioChecked, name)}
          expandIcon={wrapRaioBtn(<PlusSquare />, onClickRadioBtn, nodes.id, isRadioChecked, name)}
          endIcon={wrapRaioBtn(<CloseSquare />, onClickRadioBtn, nodes.id, isRadioChecked, name)}
          onContextMenu={(e)=>{ 
            e.stopPropagation();
            onContextMenu(e, nodes); 
          }}>
          {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, onContextMenu, makeInput, fieldVal, name)) : null}
      </StyledTreeItem>
    )
  };

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={expandedTreeList.current}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeSelect={onNodeSelect}
    >
      {
        _.map(data, (parentNode)=> renderTree(parentNode, handleClick, makeInput, value, name))
      }

      { isShowContextMenu && 
          <Menu
              keepMounted
              open={state.mouseY !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
            >
              {
                showViewOption && 
                <MenuItem dense onClick={(e)=> handleClose(e, PAGE_MODES.VIEW)}>View</MenuItem>
              }
              {isEditAllowed &&  <MenuItem dense onClick={(e)=> handleClose(e, PAGE_MODES.EDIT)}>Edit</MenuItem>}
              {isCreateAllowed && <MenuItem dense onClick={(e)=> handleClose(e, PAGE_MODES.CREATE)}>Add sub-unit</MenuItem>}
              {isDeleteAllowed && <MenuItem dense onClick={(e)=> handleClose(e, PAGE_MODES.DELETE)}>Delete</MenuItem>}
          </Menu>
      }
    </TreeView>
  );
}

TeamsTreeView.propTypes = {
  data: PropTypes.array,
  makeInput:PropTypes.bool
}