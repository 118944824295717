import React, { useEffect, useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Tabs, Tab, Badge, Icon, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import SharedList from './SharedList';
import ShareForm from './ShareForm';
import {reducer, initialState, permissions, formModules} from './reducer';
import _ from 'lodash';
import { ROLE_MODULES } from 'constants/modules';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogTitle:{
      paddingBottom:0,
      borderBottom:`1px solid ${theme.palette.grey[300]}`,
      '& h6':{
        [theme.breakpoints.down('xs')]: {
          fontSize:16
        }
      }
    },
    dialogContent:{
      paddingTop:theme.spacing(2),
      [theme.breakpoints.up('md')]: {
         width:500
      }
    },
    dialogFooter:{
        padding:theme.spacing(2),
        borderTop:`1px solid ${theme.palette.grey[300]}`
    },
    tab:{
      textTransform:'none',
      [theme.breakpoints.down('xs')]: {
        fontSize:13
      }
    },
    customTabTitle:{
      fontSize:14,
      fontWeight:'500',
      [theme.breakpoints.down('xs')]: {
        fontSize:13
      }
    },
    badge:{
      marginLeft:theme.spacing(1.5)
    },
    dialogIco:{
      verticalAlign:'middle'
    },
    backIcoBtn:{
      marginRight:theme.spacing(1),
      padding:theme.spacing(0.8),
      background:theme.palette.primary.main,
      color:theme.palette.common.white,
      '&:hover':{
        background:theme.palette.primary.main,
      }
    }
}));

function checkIsUserModule(formModule){
  return _.isEqual(formModule, ROLE_MODULES.USER_MANAGEMENT);;
}

const ShareDialog = ({open, handleClose}) => {

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const [state, dispatch] = useReducer(reducer, initialState);
    const {tabIdx, sharedUsersList, permission, message, sharedTeamsList, selectedTeams, selectedUsers} = state;

    const onChangeTab = (e, tabIdxToChange) =>{
       if(!_.isEqual(tabIdxToChange, tabIdx))
          dispatch({type:'changeTab', payload:{ tabIdx: tabIdxToChange }});
    }

    const onChangePermSelBox = (e) =>{
       dispatch({type:'changePermSelBox', payload:{ value: e.target.value }});
    }

    const onChangeMsgInput = (e)=>{
      dispatch({type:'changeMsgInput', payload:{ value: e.target.value }});
    }

    const onSubmitForm = (selectedUsers) =>{
        // const successCallback = ()=>{ 
        //   handleClose(null, true); 
        // };
        // const closeErrDialog = () => { dispatch(hideLoader()); };
        // const selectedUserIds = _.map(selectedUsers, (user)=>_.get(user, 'user_id'));
        // dispatch(addTeamMembers(teamCode, selectedUserIds, successCallback, closeErrDialog));
    }

    const onClickActionBtn = () => {
      
    }

    const resetForm = useCallback((retainVals)=>{
       dispatch({type:'resetForm', payload:retainVals});
    }, [dispatch]);

    //reset exsting details on form dialog close event
    useEffect(()=>{
       if(!open)
          resetForm();
    }, [open, resetForm]);

    const sharedUsersCount = sharedUsersList.length;
    const sharedTeamsCount = sharedTeamsList.length;
    const activeFormModule = formModules[tabIdx];

    const onChangeAutoComplete = useCallback((e, value) => {
      dispatch({
        type:'changeOnAutoComplete', 
        payload:{ 
          formModule:activeFormModule,
          data:_.uniqBy(value, (item)=>_.get(item, checkIsUserModule(activeFormModule) ?  'user_id' : 'tu_code'))
        }
      });
    }, [dispatch, activeFormModule]);

    const isFormMode = useMemo(()=> !_.isEmpty(selectedUsers) || !_.isEmpty(selectedTeams), [selectedTeams, selectedUsers]);

    const onClickCancelBtn = useCallback(() => {
      if(isFormMode)
         resetForm({tabIdx});
      else
         handleClose();
   }, [dispatch, tabIdx, handleClose, isFormMode]);
   
    return (
     <Dialog 
        classes={{
          paper: classes.dialog
        }}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        maxWidth={"sm"} open={open} onClose={handleClose} aria-labelledby="role-form-title">

            <DialogTitle className={classes.dialogTitle} disableTypography={true} id="role-dialog-title">
                
              <Typography variant="h6">
                <IconButton className={classes.backIcoBtn} onClick={()=>{}} aria-label="back button">
                   <Icon fontSize="small">person_add_alt_1</Icon>
                </IconButton>
                  Share with users and team units
              </Typography>
              <Tabs 
                orientation="horizontal"
                value={tabIdx}
                indicatorColor="primary"
                onChange={onChangeTab}
                variant="fullWidth"
                aria-label="sharing tabs users, teamunits"
                >
                <Tab disableRipple className={classes.tab} label={
                  <Typography className={classes.customTabTitle}>
                        Users {Boolean(sharedUsersCount) && <Badge color="error" className={classes.badge} badgeContent={sharedUsersCount} />}
                  </Typography>
                } />
                <Tab disableRipple className={classes.tab} label={
                  <Typography className={classes.customTabTitle}>
                        Team Units {Boolean(sharedTeamsCount) && <Badge color="error" className={classes.badge} badgeContent={sharedTeamsCount} />}
                  </Typography>
                } />
             </Tabs>                            
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
            <ShareForm 
              isFormMode={isFormMode}
              isMobileView={fullScreen}
              onChangeAutoComplete={onChangeAutoComplete}
              onChangePermSelBox={onChangePermSelBox}
              onChangeMsgInput={onChangeMsgInput}
              selectedUsers={selectedUsers}
              selectedTeams={selectedTeams}
              message={message}
              permissions={permissions}
              permission={permission}
              formModule={activeFormModule} />
            {
              !isFormMode && 
              <SharedList 
                permissions={permissions}
                isMobileView={fullScreen}
              />
            }
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
            <Button variant={isFormMode ? "outlined" : "contained"} 
                onClick={onClickCancelBtn} color="primary" disableElevation>
               { isFormMode? "Cancel" : "Done" }
            </Button>
            {isFormMode && <Button variant="contained" 
                onClick={onClickActionBtn} 
                color="primary" disableElevation>
                Share
            </Button>}
            </DialogActions>        
      </Dialog>
    );
}

ShareDialog.propTypes = {
    open:PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default ShareDialog;