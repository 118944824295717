import React from "react";
import { Dialog, DialogContent, Container, makeStyles } from "@material-ui/core";
// import { Formik } from "formik"; 
// import * as Yup from "yup";



const useStyles = makeStyles((theme) => ({
  muiButtonRoot: {
    height: "32px",
    // borderRadius: "10px",
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 1.5),
    "& h6": {
      fontWeight: 600,
      textTransform: "capitalize",
      lineHeight: theme.typography.pxToRem(14),
      fontSize: theme.typography.pxToRem(14),
      marginLeft: theme.spacing(0.5),
    },
  },
  textRight: {
    textAlign: "right",
  },
  subTitle: {
    textTransform: 'uppercase',
    color: theme.palette.almostBlack[700]
  },
  inputStyle: {
    width: "100%",
  },

  dialogHeight: {
    minHeight: "80vh",
    maxHeight: "80vh",
    padding : theme.spacing(3)
  },
  "& .MuiDialogContent-root": {
    overflowY: "inherit",
    borderRadius : '20px',
    padding : '0px'
  },
  muiDialogPaper:{
    // borderRadius: '20px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
},
closeButtonInDialog: {
  position: 'absolute',
  top: '0',
  right: '0'
},
title : {
  marginTop : theme.spacing(2)
}
}));

const CustomDialog = (props) => {
  const classes = useStyles();
  const {open, onClose, maxWidth,fullWidth,children,disableBackdrop,disableEscapeKey} = props;
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        classes={{ paper: classes.muiDialogPaper }}
        scroll="body" 
        disableBackdropClick={disableBackdrop ? disableBackdrop : true}
        disableEscapeKeyDown={disableEscapeKey ? disableEscapeKey : true}
      >
        <Container>
            {/* <DialogTitle id="alert-dialog-title" style={{padding : 'opx'}}>
            <IconButton
                    aria-label="close"
                    className={classes.closeButtonInDialog}
                    onClick={onClose}
                  >
                   <Icon>
                       close
                   </Icon>
                  </IconButton>
            </DialogTitle> */}
            <DialogContent className={classes.dialogContent}>
              {children}
            </DialogContent>
        </Container>
      </Dialog>
    </>
  );
};

export default CustomDialog;
