import { SAGA_ACTIONS,ACTION_TYPES } from "../../../constants";


export const createJobs = (data , bpId) =>({
    type : SAGA_ACTIONS.CREATE_JOB,
    payload : data,
    bpId
})
export const resetCreateJobs = ()=>({
    type : ACTION_TYPES.RESET_CREATE_JOB,
})