import React, { useRef, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Icon, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getImgUrl } from 'utils';
import { Field } from 'formik';
import Validator from './validator';
import {useDispatch} from 'react-redux';
import { changeCurrentUsrPwd } from 'services/users/actions';
import { hideLoader } from 'services/loader/actions';

const useStyles = makeStyles(theme => ({
    contentWrapper:{
        maxWidth:'450px',
        width:'100%',
        padding:theme.spacing(2),
        margin:'0 auto',
        boxSizing:'border-box'
    },
    cardContent:{
        textAlign:'center',
        paddingTop:theme.spacing(3)
    },
    avatar:{
        margin:'20px auto 8px',
        width:80,
        height:80,
        // border:`2px solid ${theme.palette.primary.main}`
    },
    textField:{
        marginTop:theme.spacing(2.5),
        maxWidth:300
    },
    button:{
        marginTop:theme.spacing(4),
        marginBottom:theme.spacing(2)
    },
    successIco:{
        margin:'16px auto',
        background:theme.palette.success.main
    }
}));

function ChangePassword() {
    const classes = useStyles();
    const userDetails = useSelector((state) => _.get(state, 'user.userDetails'));

    const [isPwdUpdated, setPwdUpdated] = useState(false);

    const dispatch = useDispatch();
    const formRef = useRef(null);

    const userDisplayName = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name')], ' '));
    const token = useSelector(state=>_.get(state, 'session.authToken', ''));
    const userProfilePic =  _.get(userDetails, 'profile_pic_url') && getImgUrl(token, _.get(userDetails, 'profile_pic_url'));

    const formFields = [
        {name:"current_password", label:"Current Password*", type:"password"},
        {name:"new_password", label:"New Password*", type:"password"},
        {name:"re_type_password", label:"Re-type Password*", type:"password"}
    ];
    
    const onSubmitForm=(values)=>{
        const successCallback = () => {
            setPwdUpdated(true);
        };
        const failureCallback = () => {
            dispatch(hideLoader());
            formRef.current.resetForm();
        };
        dispatch(changeCurrentUsrPwd(values, successCallback, failureCallback));
    }

    const onClickChngPwd = ()=>{
        formRef.current && formRef.current.submitForm();
    }

    return (
        <div className="page-content">
           <Box className={classes.contentWrapper} component="div" m={10} lg={12}>
             <Card variant="outlined">
                 <CardContent className={classes.cardContent}>
                     {isPwdUpdated ?
                     <>
                        <Avatar alt={"success"} className={classes.successIco}>
                            <Icon>done</Icon>
                        </Avatar>
                        <Typography variant="body1" gutterBottom>Password Updated Successfully</Typography>
                     </>
                     :<>
                        <Typography variant="h6" gutterBottom>Change Password</Typography>
                        <Avatar alt={userDisplayName} src={userProfilePic} className={classes.avatar}></Avatar>
                        <Typography variant="body1" gutterBottom>{userDisplayName}</Typography>
                        <Validator onSubmit={onSubmitForm} ref={formRef}>
                            {(formik) => {
                                return _.map(formFields, (formField)=>(
                                    <Field key={formField.name} type='text' name={formField.name}>
                                        {({ form, field }) => (
                                                <TextField 
                                                    variant='outlined' 
                                                    margin='none' 
                                                    size='small' 
                                                    fullWidth 
                                                    name={field.name}
                                                    label={formField.label}
                                                    type={formField.type}
                                                    autoComplete="off"
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    value={field.value || ""}
                                                    className={classes.textField}
                                                    helperText={
                                                        form.touched[field.name] && form.errors[field.name]
                                                    }
                                                    error={Boolean(form.errors[field.name] && form.touched[field.name])} />
                                        )}
                                </Field>
                                ));
                            }}
                        </Validator>
                        <Button onClick={onClickChngPwd} className={classes.button} color="primary" variant="contained" disableElevation>
                            Change Password
                        </Button>
                     </>}
                 </CardContent>
             </Card>
           </Box>
        </div>
    );
}

export default ChangePassword;