import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import { getUserPermissions, getModulePerms } from 'utils';

export function usePermission(moduleName) {
    const userPermissions = useSelector(getUserPermissions);
    const permissionMap = useMemo(()=>{
        return getModulePerms(moduleName, userPermissions);
    }, [moduleName, userPermissions]);
    return permissionMap;
}