import React, {useState, useCallback, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import { deleteUser, fetchAllUsers } from 'services/users/actions';
import { hideLoader } from 'services/loader/actions';
import { Typography, Avatar, ListItem, ListItemAvatar, List, ListItemText, FormControlLabel, Radio, TextField, Grid } from '@material-ui/core';
import { getImgUrl } from 'utils';
import _, { throttle } from 'lodash';
import { red } from '@material-ui/core/colors';
import Validator from './validator';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'formik';

const useStyles = makeStyles((theme)=>({
    dialogTitle:{
        paddingBottom:0,
        paddingLeft:theme.spacing(0),
        paddingRight:0,
        paddingTop:theme.spacing(0),
        borderBottom:`1px solid ${theme.palette.grey[300]}`,
        '& h6':{
          [theme.breakpoints.down('xs')]: {
            fontSize:16
          }
        }
    },
    dialogFooter:{
        padding:theme.spacing(2),
        borderTop:`1px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            padding:theme.spacing(1)
        }
    },
    deletBtn:{
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        }
    },
    autocomplete:{
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop:theme.spacing(2)
        }
    },
    headerTypo:{
        paddingLeft:theme.spacing(2),
        paddingTop:theme.spacing(1)
    },
    greyText:{
        color:theme.palette.grey[600],
        [theme.breakpoints.down('xs')]: {
            marginBottom:theme.spacing(1.5)
        }
    },
    rbLabel:{
        fontSize:'14px'
    },
    optionContent:{
        marginLeft:theme.spacing(3.3),
        marginBottom: theme.spacing(2)
    },
    userPic:{
        width:37,
        height:37,
        marginRight:theme.spacing(1)
    }
}));

function DeleteDialog({open, userId, firstName, lastName, email, profilePic, accessToken, handleClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const formRef = useRef();
    const dispatch = useDispatch();
    const [transferUsrList, setTransferUsrList] = useState([]);

    const classes = useStyles();
    
    const fetchTransUsrList = useMemo(() => {
        return throttle((userIdToSkip, searchTxt, callback) => {
           dispatch(fetchAllUsers({except_user_id:userIdToSkip, search_text:searchTxt, limit:4, offset:0}, callback, ()=>{}));
        }, 500);
    }, [dispatch]);

    const handleOwnerInpChng = useCallback((e) => {
        fetchTransUsrList(userId, e.target.value, (resp)=>{
            setTransferUsrList(_.get(resp, 'data.data.data', []));
        });
    }, [userId, fetchTransUsrList]);

    const onSubmitForm = (values) =>{
        dispatch(deleteUser(userId, values, ()=>{
            //delete success callback
            handleClose(null, true);
        }, ()=>{
            //error dialog close button callback
            dispatch(hideLoader())
        }));
    }

    const onClickDeleteBtn = ()=>{
        formRef.current && formRef.current.submitForm();
    }

    const getPicUrl = useCallback((picLoc)=>{
        return _.isEmpty(picLoc) ? "" : getImgUrl(accessToken, picLoc);
    }, [accessToken]);

    const profilePicUrl = getPicUrl(profilePic);

    const onChangeTransOption = (e, valueToUpdate, setFieldValueFunc)=>{
       if(e.target.checked)
         setFieldValueFunc('is_transfer_data', valueToUpdate, true);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            aria-labelledby="delete-user-dialog"
        >
            <DialogTitle className={classes.dialogTitle} disableTypography id="delete-user-dialog-title">
                <Typography variant="h6" className={classes.headerTypo}>Delete User</Typography>
                <List dense>
                    <ListItem dense>
                        <ListItemAvatar>
                            <Avatar alt={firstName} src={profilePicUrl} size="small" />
                        </ListItemAvatar>
                        <ListItemText 
                            primary={`${firstName} ${lastName}`}
                            secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                  {email}
                                </Typography>
                                {" — To Delete?"}
                            </React.Fragment>
                            } />
                    </ListItem>
                </List>
            </DialogTitle>
            <DialogContent>
            <Typography className={classes.greyText} component="div" variant="caption">Before deleting this user, you may want to transfer their data to another owner, just in case.</Typography>
            <Validator onSubmit={onSubmitForm} ref={formRef}>
                {(formik) => {
                   const setFieldValue = _.get(formik, 'setFieldValue');
                   const isTransData =  _.get(formik, 'values.is_transfer_data');
                   return (
                    <>
                      <div>
                        <FormControlLabel 
                            classes={{label:classes.rbLabel}}
                            control={
                               <Radio 
                                 onClick={(e)=>{ onChangeTransOption(e, true, setFieldValue); }}
                                 checked={isTransData} 
                                 color="primary" size="small" />
                            } 
                            label="Transfer ownership of the user's data to another user (for example, a manager)."  />
                      </div>

                      <div className={classes.optionContent}>
                        <Field type='text' name='transfer_user_id'>
                                {({ field, form }) => (
                                    <Autocomplete
                                        id="userslist"
                                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.full_name)}
                                        filterOptions={(x) => x}
                                        options={transferUsrList}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        size={"small"}
                                        renderOption={(option) => {
                                          const picUrl = getPicUrl(option.profile_pic_url);
                                          return (
                                            <Grid key={option.user_id} container alignItems="center">
                                                <Grid item>
                                                    <Avatar src={picUrl} size="small" className={classes.userPic} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2">
                                                      {option.full_name}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {option.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                          )
                                        }}
                                        onChange={(e, value)=>{
                                           field.onChange(
                                                { 
                                                    target: { 
                                                        name: field.name, 
                                                        value: _.get(value, 'user_id')
                                                    }
                                                }
                                            ); 
                                        }}
                                        onBlur={field.onBlur}
                                        // value={field.value}
                                        getOptionSelected={(option) => option.user_id === field.value}
                                        className={classes.autocomplete}
                                        onInputChange={handleOwnerInpChng}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                dense="true"
                                                error={Boolean(form.errors[field.name] && form.touched[field.name])}
                                                helperText={
                                                    form.errors[field.name] &&
                                                    form.touched[field.name] &&
                                                    String(form.errors[field.name])
                                                }                                                
                                                variant="outlined"
                                                label={"New owner's name"}
                                            />
                                        )}
                                    />
                                )
                            }
                        </Field>
                        <Typography variant="body2" gutterBottom>Following modules data's will be transferred :</Typography>
                        <Typography variant="body2" gutterBottom> &#x25cf; &nbsp;Dashboards</Typography>
                        <Typography variant="body2" gutterBottom> &#x25cf; &nbsp;Jobs</Typography>
                      </div>
                      <div>
                        <FormControlLabel 
                            classes={{label:classes.rbLabel}}
                            control={
                               <Radio 
                                 onClick={(e)=>{ onChangeTransOption(e, false, setFieldValue); }}
                                 checked={!isTransData} 
                                 color="primary" size="small" />
                            } 
                            label="Don't transfer data"  />
                      </div>
                      <div className={classes.optionContent}>
                        <Alert severity="warning">
                            All data not transferred will be deleted.
                        </Alert>
                      </div>
                    </>
                   )
                }}
            </Validator>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
                <Button size={fullScreen ? "small" : "medium"} className={classes.deletBtn} onClick={onClickDeleteBtn} variant="contained" disableElevation autoFocus>
                    Delete
                </Button>
                <Button size={fullScreen ? "small" : "medium"} className={classes.otherActionBtn} onClick={handleClose} variant="outlined" disableElevation color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteDialog.propTypes = {
    open:PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    userId:PropTypes.string,
    firstName:PropTypes.string,
    lastName:PropTypes.string,
    email:PropTypes.string,
    profilePic:PropTypes.string,
    accessToken:PropTypes.string
};

export default DeleteDialog;