import React from 'react'
import { BoxItem } from './BoxItem';
import _ from 'lodash';

const style = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  transform: "translate3d(0, 0, 0)" 
}

export const Container = ({containerId, extCols, removeCol, targetScale, getChartColDetail, onChangeColOrder, onClickItem}) => {

    const moveCard = (dragIndex, hoverIndex) => {
       onChangeColOrder(extCols[dragIndex], containerId, dragIndex, hoverIndex, targetScale);
    }

    const handleDelete = (colName) => {
       removeCol(containerId, colName, targetScale);
    }

    const onClickBoxItem = (colName) => {
       onClickItem && onClickItem(colName, containerId, targetScale);
    }

    const isAggreApplied = (colName) => {
       return !_.isEmpty(_.get(getChartColDetail(colName, targetScale), 'aggregation_type'));
    }

    const renderColumn = (colName, index) => {
      const aggreFlag = isAggreApplied(colName);
      return (
        <BoxItem
          key={colName}
          accepts={containerId}
          index={index}
          onClickBoxItem={onClickBoxItem}
          id={colName}
          text={colName}
          isAggreApplied={aggreFlag}
          handleDelete={handleDelete}
          moveCard={moveCard}
        />
      )
    }

    return (
       <div style={style}>{extCols.map((col, i) => renderColumn(col, i))}</div>
    )
}

export default Container;
