import {SAGA_ACTIONS} from '../../../constants';

export const fetchJobs = (query = '') => ({
    type: SAGA_ACTIONS.FETCH_JOBS,
    query
});

export const fetchJobsByJobId = (jobId = '', recIdx=-1) => ({
    type: SAGA_ACTIONS.FETCH_JOB_BY_JOB_ID,
    jobId,
    recIdx
})