import React from 'react';
import { Dialog, DialogContent, CircularProgress, Typography, Button } from '@material-ui/core';
import {LOADER_MODE} from '../../constants';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme)=>({
    container:{
        textAlign:'center',
        padding:theme.spacing(2)
    },
    loaderTxt:{
        marginTop:theme.spacing(1.5)
    },
    successIco:{
        color:theme.palette.success.main,
        fontSize:theme.typography.pxToRem(50)
    },
    errIcon:{
        color:theme.palette.error.main,
        fontSize:theme.typography.pxToRem(50)
    },
    actionBtn:{
        marginTop:theme.spacing(4),
        marginRight:theme.spacing(2)
    },
    subtitleTxt:{
        fontSize:theme.typography.pxToRem(13),
        marginTop:theme.spacing(1)
    },
    errBtn:{
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
          backgroundColor: red[700],
        }
    }
}));

const Loader = ({showLoader, loaderMode, loaderTxt, loaderDescTxt, successBtnTxt, errorBtnTxt, successBtnCallback, errorBtnCallback, icon})=>{

    const classes = useStyles();

    const getLoaderInfo = (loaderMode) =>{
        switch(loaderMode){
            case LOADER_MODE.LOADING:
                return (
                    <CircularProgress color="secondary" />
                )
            case LOADER_MODE.CONFIRM:
                return (
                    <i className={classnames("material-icons", classes.errIcon)}>{icon}</i>
                )
            case LOADER_MODE.SUCCESS:
                return (
                    <i className={classnames("material-icons", classes.successIco)}>check_circle</i>
                )
            case LOADER_MODE.ERROR:
                return (
                    <i className={classnames("material-icons", classes.errIcon)}>error</i>
                )
            default:
                return (
                    <CircularProgress color="secondary" />
                )
        }
    }

    const getFooter = (loaderMode)=>{
        switch(loaderMode){
            case LOADER_MODE.CONFIRM:
                return (
                    <>
                    <Button variant="contained" className={classnames(classes.actionBtn, classes.errBtn)} onClick={successBtnCallback} disableElevation>{successBtnTxt}</Button>
                    <Button variant="contained" className={classnames(classes.actionBtn)} onClick={errorBtnCallback} color="default" disableElevation>{errorBtnTxt}</Button>
                    </>
                )
            case LOADER_MODE.SUCCESS:
                return (
                  <Button variant="contained" className={classes.actionBtn} onClick={successBtnCallback} color="default" disableElevation>{successBtnTxt}</Button>
                )
            case LOADER_MODE.ERROR:
                return (
                    <Button variant="contained" className={classnames(classes.actionBtn)} onClick={errorBtnCallback} color="default" disableElevation>{errorBtnTxt}</Button>
                )
            default:
                return undefined;
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={showLoader}
            aria-labelledby="Loader Popup">
            <DialogContent>
                <div className={classes.container}>
                  {getLoaderInfo(loaderMode)}
                  <Typography className={classes.loaderTxt} variant="body1">{loaderTxt}</Typography>
                  {loaderDescTxt && <Typography className={classes.subtitleTxt} variant="subtitle1">{loaderDescTxt}</Typography>}
                  {getFooter(loaderMode)}
                </div>
            </DialogContent>
          </Dialog>
    )
}

export default Loader;