import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import {TeamMembersGraphic} from 'illustrators';
import MembersList from './MembersList';
import AddMembersDialog from './AddMembersDialog';

const useStyles = makeStyles((theme)=>({
    root:{
       border: `1px solid ${theme.palette.grey[300]}`,
       padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
       marginLeft: theme.spacing(2),
       marginRight: theme.spacing(2),
       marginBottom: theme.spacing(2),
       minHeight: 264,
       [theme.breakpoints.down('sm')]:{
          marginLeft: theme.spacing(0),
          marginRight: theme.spacing(0)
       }
    },
    header:{
      paddingBottom:theme.spacing(1)
    },
    addMemBtn:{
        float:'right',
        [theme.breakpoints.down('xs')]:{
          float:'left'
        }
    },
    noTeamIco:{
        fontSize:'8em',
        margin:'46px auto 24px',
        display:'block',
    }
}));

function TeamMembers({selectedTeamId, selectedTeamName, showBackBtn, isEditAllowed, onBackBtnClick, showAddMemberBtn}) {

    const classes = useStyles();
    const [openAddMemDialog, setOpenAddMemDialog] = useState(false);
    const [listRefreshKey, setListRefreshKey] = useState(0);

    const closeAddMemDialog = (e, isRefreshList) => {
        setOpenAddMemDialog(false);
        if(isRefreshList)
            setListRefreshKey((prevState)=> prevState + 1);
    }

    const onClikAddMemBtn = ()=>{
        setOpenAddMemDialog(true);
    }
    
    return (
        <Grid item className={classes.root}>
            {
               _.isEmpty(selectedTeamId) ? 
                <Grid item xs={12}>
                  <TeamMembersGraphic className={classes.noTeamIco} />
                  <Typography align="center" component="div" color="textSecondary" variant="body2">
                      Select the team unit to see members.
                  </Typography>
                </Grid> :
                <>
                    <Grid item container xs={12} className={classes.header}>
                        <Grid item xs={12} lg={8}>
                           <Typography variant="h6" gutterBottom>
                            { showBackBtn && 
                                <IconButton size="small" onClick={onBackBtnClick}>
                                   <Icon>keyboard_backspace</Icon>
                                </IconButton>
                            }
                            &nbsp;{selectedTeamName}
                            </Typography>
                        </Grid>
                        {showAddMemberBtn && <Grid item xs={12} lg={4}>
                            <Button className={classes.addMemBtn} onClick={onClikAddMemBtn} size="small" variant="contained" color="primary" disableElevation>
                                Add Team Member
                            </Button>
                        </Grid>}
                    </Grid>
                    <Grid item xs={12} className={classes.userListMapper}>
                        <MembersList 
                          key={listRefreshKey}
                          teamCode={selectedTeamId} 
                          teamName={selectedTeamName}
                          showDelOption={isEditAllowed}
                        />
                    </Grid>
                    <AddMembersDialog
                        open={openAddMemDialog}
                        handleClose={closeAddMemDialog}
                        teamCode={selectedTeamId}
                    />
                </>
            }
        </Grid>
    );
}

TeamMembers.propTypes = {
    selectedTeam:PropTypes.object,
    showBackBtn: PropTypes.bool,
    onBackBtnClick: PropTypes.func,
    showAddMemberBtn:PropTypes.bool,
    isEditAllowed:PropTypes.bool
}

export default TeamMembers;