import { ACTION_TYPES } from "../../../../constants";
import _ from 'lodash';
import Axios from 'axios';

const getDefaultState = () => ({
    isFetching:false,
    isError:true,
    errMsg:'',
    data:{}
});

const graphsData = (state = getDefaultState(), action) =>{
    switch(action.type){
        case ACTION_TYPES.FETCHING_DASHBOARD_DATA_PENDING:
            return {
                ...state,
                isFetching:true,
                isError:false,
                errMsg:'',
                // data:{}
            }
        case ACTION_TYPES.FETCHING_DASHBOARD_DATA_FULFILLED:
            return {
                ...state,
                isFetching:false,
                isError:false,
                errMsg:'',
                data:_.get(action, 'payload.data.data', {})
            }
        case ACTION_TYPES.FETCHING_DASHBOARD_DATA_REJECTED:
            const resp = _.get(action, 'payload');
            if(Axios.isCancel(resp) || JSON.stringify(resp) === '{}')
                return {...state}
            else
                return {
                    ...state,
                    isFetching:false,
                    isError:true,
                    errMsg:_.get(resp, 'response.data.message', 'Something went wrong on server'),
                    data:{}
                }
        default:
            return state;
    }
}

export default graphsData;