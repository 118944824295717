import React, {useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';
import LoadingIndicator from 'components/LoadingIndicator';

const useStyles = makeStyles((theme)=>({ 
    table: props => ({
        position:'relative',
        '&.ag-theme-material':{
            '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
                width: '10px',
                height: '10px',
                '-webkit-appearance': 'none',
                backgroundColor: _.get(props, 'scrollBar.trackColor', 'rgba(255, 255, 255, 0)'),//, //'transparent'
            },
            '& ::-webkit-scrollbar-thumb': {
                backgroundColor: _.get(props, 'scrollBar.thumbColor', 'rgba(213, 213, 220, 1)'), //theme.palette.almostBlack[400],
                height: '80px',
                borderRadius: '5px'
            }
        },
        '& .ag-header': {
            textTransform: 'uppercase',
            letterSpacing: '2px',
            fontSize: theme.typography.pxToRem(11),
            background: _.get(props, 'tableStyles.headerBgColor'),
            border: 0
        },
        '&.ag-theme-material .ag-header-cell': {
            color: _.get(props, 'tableStyles.headerTxtColor'),
        },
        '&.ag-theme-material .ag-cell': {
            fontSize: theme.typography.pxToRem(14),
            '& .ag-react-container':{
                height:'100%'
            }
        },
        '&.ag-theme-material .ag-row': {
            background: _.get(props, 'tableStyles.rowBgColor'),
            color: _.get(props, 'tableStyles.rowTxtColor'),
            borderColor: _.get(props, 'tableStyles.rowSeparatorColor'),
            cursor: 'pointer'
        },
        '&.ag-theme-material .ag-row:hover':{
            backgroundColor:'rgba(250,250,251,1)'
        }
    }),
    loaderContainer:{
        position: 'absolute',
        bottom:-16,
        width: '100%',
        textAlign: 'center',
        background:theme.palette.common.white
    }
}));

const AgGridCustom = (props) => {
    
    const {
        columnDefs, rowData, onRowClicked, loading, 
        rowHeight, frameworkComponents, disableClickSelectionRenderers
    } = props;

    const gridOptions = useRef();

    const autoReSizeAllColumns = (gridColumnApi) => {
        let allColumnIds = [];
        gridColumnApi.getAllColumns().forEach((column) => {
          if(!_.get(column, 'colDef.hide', false))
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds);
    };    

    const setOrUnsetResizeColsToFit = useCallback((sizeColumnsToFit, gridApi, columnApi) => {
        if (!sizeColumnsToFit)
            autoReSizeAllColumns(columnApi);
        else
            gridApi.sizeColumnsToFit();
    }, []);

    const updateRowData = (rowData, gridApi) => {
        gridApi.setRowData(rowData);
    }

    useEffect(()=>{
        if(_.get(gridOptions, 'current.api'))
            updateRowData(rowData, gridOptions.current.api);
    }, [rowData]);

    useEffect(() => {
        if (_.get(gridOptions, 'current.api')) {
            const gridApi = gridOptions.current.api;
            gridApi.setColumnDefs(columnDefs);
            gridApi.resetRowHeights();
            gridApi.sizeColumnsToFit();
        }
    }, [columnDefs]);

    const onGridReady = useCallback(params => {
        gridOptions.current = params;
        setOrUnsetResizeColsToFit(true, params.api, params.columnApi); 
    }, [setOrUnsetResizeColsToFit]);
    
    const classes = useStyles({
        tableStyles: {
            headerBgColor: 'rgba(158,156,156,0.08)',
            headerTxtColor: 'rgba(68,68,79,1)',
            rowBgColor: 'rgba(255,255,255,1)',
            rowTxtColor: 'rgba(0,0,0,0.87)',
            rowSeparatorColor: 'rgba(226,226,226,1)',
        },
        scrollBar: {
            trackColor:'rgba(255, 255, 255, 0)',
            thumbColor:'rgba(213, 213, 220, 1)',
        }
    });

    const onCellClicked = (e) => {
        if (e.column && _.indexOf(disableClickSelectionRenderers, e.column.colDef.cellRenderer) > -1) {
            e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection=true; 
        }
        else{
            e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection=false; 
        }
    }

    return (
        <div className={classnames("ag-theme-material", classes.table)}>
          <AgGridReact
            domLayout='autoHeight'
            columnDefs={columnDefs}
            sizeColumnsToFit={true}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
            animateRows={true}
            onGridReady={onGridReady}
            headerHeight={30}
            suppressCellSelection={true}
            onRowClicked={onRowClicked && onRowClicked}
            onCellClicked={onCellClicked}
            rowHeight={rowHeight}>
          </AgGridReact>
            {
                loading && 
                <div className={classes.loaderContainer}>
                <LoadingIndicator></LoadingIndicator>
                </div> 
            }
        </div>
    );
}

AgGridCustom.propTypes = {
    columnDefs:PropTypes.array.isRequired,
    rowData:PropTypes.array.isRequired,
    loading:PropTypes.bool,
    onRowClicked:PropTypes.func,
    rowHeight:PropTypes.number,
    frameworkComponents: PropTypes.object,
    disableClickSelectionRenderers:PropTypes.arrayOf(PropTypes.string)
};

export default AgGridCustom;