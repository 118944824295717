import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, Grid, Typography, makeStyles, Icon, IconButton, Button, DialogContent, Tabs, Tab, Slide } from '@material-ui/core';
import ProductTable from '../../../components/ProductTable'
import { renderFlagComponent } from '../../../components/Flags';
import { ACTION_COL_OPTIONS, JOB_PRIORITY } from '../../../constants';
import AddActivityForm from './AddActivityForm';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import { fetchProductsForJob, fetchActivitiesForJob, clearJobData } from '../../../services/viewJobData/actions';
import { getDetailedPaginationProperties } from '../../../utils'
import { showLoader, hideLoader } from '../../../services/loader/actions';
import api from '../../../services/viewJobData/api'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions';
import { fetchJobsByJobId } from '../../../services/jobs/actions';
import AttachmentsPopUp from './AttachmentsPopUp';
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules';
import { usePermission } from 'hooks/usePermission';

const useStyles = makeStyles((theme) => ({
    muiDialogContainer:{
        alignItems: 'flex-end'
    },
    muiDialogPaper: {
        // borderRadius: '20px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        position: 'relative',
        boxSizing: 'border-box',
        // height: 'calc(100% - 64px)',
        '& .MuiDialogTitle-root, .MuiDialogContent-root':{
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        }
    },
    breadCrumbsContainer:{
        position: 'absolute',
        top: '16px',
        left: '40px'
    },
    buttonInTitle: {
        marginLeft: theme.spacing(2),
        // borderRadius: 8
    },
    dataWithLabelContainer: {
        // padding: theme.spacing(0, 1)
    },
    label: {
        color: theme.palette.almostBlack[600],
        fontSize: theme.typography.pxToRem(14)
    },
    closeButtonInDialog: {
        position: 'absolute',
        top: '6px',
        right: '6px'
    },
    tabsContainer: {
        borderBottom: `1px solid ${theme.palette.almostBlack[600]}22`,
        marginTop: theme.spacing(2)
    },
    muiTabsRoot: {
        height: '100%'
    },
    muiTabsScroller: {
        height: '100%'
    },
    muiTabsFlexContainer: {
        height: '100%'
    },
    muiTabRoot: {
        minWidth: 'unset',
        // padding: `6px ${theme.spacing(4)}px`
    },
    muiTab_textColorInherit: {
        color: theme.palette.almostBlack[900],
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'capitalize',
        fontWeight: '500',
        letterSpacing: theme.typography.pxToRem(0.1),
        opacity: 1
    },
    muiTab_selected: {
        color: theme.palette.primary.main,
        fontWeight: '600'
    },
    muiTabsIndicator: {
        display: 'flex',
        justifyContent: 'center',
        height: '3px',
        // border: '2px solid black',
        background: 'transparent',
        '& > div': {
            maxWidth: `calc(100% - (2 * ${theme.spacing(1)}px))`,
            width: '100%',
            height: '3px',
            borderRadius: '3px 3px 0px 0px',
            background: theme.palette.primary.main
        },
    },
    tableBody2:{
        color: theme.palette.almostBlack[800],
        letterSpacing: theme.typography.pxToRem(0.1)
    },
    flagIcon:{
        width: 16,
        height: 16,
        marginLeft: 6
    }
}));

const useStylesForDataLabel = makeStyles((theme) => ({
    dataText: {
        color: theme.palette.almostBlack[800],
        fontSize: theme.typography.pxToRem(18)
    },
    jobPriIco: ({priority})=>{
        const getPriorityColor = (priority) =>{
          switch(priority){
            case JOB_PRIORITY.LOW:
              return theme.palette.success.main;
            case JOB_PRIORITY.HIGH:
              return theme.palette.error.main;
            case JOB_PRIORITY.MEDIUM:
              return theme.palette.info.main;
            case JOB_PRIORITY.ESCALATED:
              return theme.palette.error.main;
          }
        }
        return {
          marginLeft: theme.spacing(0.5),
          color: getPriorityColor(priority)
        }
      }
}));

const getJobPriorityIco = (priority) => {
    switch (priority) {
        case JOB_PRIORITY.LOW:
            return 'arrow_downward';
        case JOB_PRIORITY.HIGH:
            return 'arrow_upward';
        case JOB_PRIORITY.MEDIUM:
            return 'more_horiz';
        case JOB_PRIORITY.ESCALATED:
            return 'more_time';
    }
}

const DataWithLabel = (props) => {
    const { label, data, isJobPriority } = props;
    const classes = useStylesForDataLabel({priority:data});

    function _renderJobStatusWithIco(jobPriority) {
        return(
            <div style={{display: 'flex'}}>
                <Typography variant='body2' className={classes.dataText}>{_.startCase(data)}</Typography>
                <Icon className={classes.jobPriIco}>{getJobPriorityIco(jobPriority)}</Icon>
            </div>
        )
    }

    return (<>
        <Typography variant='subtitle2' className={classes.label}>{label}</Typography>
        {
            !isJobPriority
                ?   <Typography variant='body2' className={classes.dataText}>{data}</Typography>
                :   _renderJobStatusWithIco(data)
        }
    </>)
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewJobDialog = (props) => {

    const { open, onClose, data, rowIndex } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tab, setTab] = useState('products');
    const [isFormOpen, setIsFormOpen] = useState(false);

    const [selectedActivity, setSelectedActivity] = useState({});

    const [paginationFooterDetails, setPaginationFooterDetails] = useState({});
    const [tablePaginationQuery, setTablePaginationQuery] = useState({products:{page:1, limit: 10}, activities:{page:1, limit: 10}});

    const jobPerms = usePermission(ROLE_MODULES.JOBS);
    const isEditAllowed = useMemo(()=>_.get(jobPerms, PERMISSION_TYPE.EDIT, false), [jobPerms]);
    const isExportAllowed = useMemo(()=>_.get(jobPerms, PERMISSION_TYPE.EXPORT, false), [jobPerms]);

    const setPageLimit = (selectedLimit, tableName) => {
        setTablePaginationQuery({...tablePaginationQuery, [tableName]: {page: 1, limit: selectedLimit}})
    }

    const goToPage = (pageNo, tableName) =>{
        setTablePaginationQuery({...tablePaginationQuery, [tableName]: {...tablePaginationQuery[tableName], page:pageNo}})
    }

    const handleClose = () => {
        onClose();
        dispatch(clearJobData())
        setTablePaginationQuery({products:{page:1, limit: 10}, activities:{page:1, limit: 10}});
    }

    const productsData = useSelector(state => state.jobData.products);
    const activitiesData = useSelector(state => state.jobData.activities);
    const labelAdjective = useSelector(state => _.get(state, 'labelManagement.data.company_adjective', 'Counterfeit'));

    const jobTitle = _.get(data, 'job_title', 'Job Title');
    const jobSubTitle = _.get(data, 'job_id', 'Job ID');

    const kpiData = [
        { label: 'Job Status', data: _.get(data, 'job_status', '') },
        { label: 'Start Date', data: new Date(_.get(data, 'job_start_date', '')).toLocaleString("en-MY") }, //_.get(data, 'job_start_date', '')
        { label: 'End Date', data: new Date(_.get(data, 'job_due_date', '')).toLocaleString("en-MY")  }, // _.get(data, 'job_due_date', '')
        { label: 'Job Priority', data: _.get(data, 'job_priority', ''), isJobPriority:true },
        { label: 'Created By', data: _.get(data, 'created_by_name', '') },
        { label: 'Assigned To', data: _.get(data, 'assigned_watcher_name', '') },
    ];

    const tabsStyleProps = {
        root: classes.muiTabsRoot,
        scroller: classes.muiTabsScroller,
        flexContainer: classes.muiTabsFlexContainer,
        indicator: classes.muiTabsIndicator,
    }
    const tabStyleProps = {
        root: classes.muiTabRoot,
    }

    function formatURL(url) {
        if (!url.match(/^https?:\/\//i)) {
            url = 'https://' + url;
        }
        return url
    }

    const actions = [
        {
            action: ACTION_COL_OPTIONS.LINK,
            icon: 'open_in_new',
            onClick: (e, record) => { if (window !== 'undefined') { window.open(formatURL(record.product_url), '_blank') } }
        }
    ];

    const activityActions = [
        {
            action: ACTION_COL_OPTIONS.DOWNLOAD_ATTACHMENT,
            icon: 'attachment',
            onClick: (e, record) => {
                if (document !== 'undefined'){
                    // console.log("on Clicking Attachment Icon",record);
                    if(!_.isEmpty(_.get(record, 'file_summary', []))){
                        setSelectedActivity(record);
                    }
                    else{
                        dispatch(showSnackbarWithTimeout('No Attachments Found', 3000));
                    }
                }
            }
        }
    ]

    const handleCounterfeitUpdate = (value, record) => {
        dispatch(showLoader('Updating Data...'));
        api.updateCounterfeitData({confirm_counterfiet: value, product_id: record.product_id}, data.bp_id).then((response)=>{
            // console.log(response);
            dispatch(fetchProductsForJob({filters:{product_id: data.product_list}, offset:(tablePaginationQuery.products.page-1)*tablePaginationQuery.products.limit, limit:tablePaginationQuery.products.limit, bp_id: data.bp_id}))
            dispatch(hideLoader())
        }).catch((err)=>{
            console.log(err);
            dispatch(hideLoader())
        })
    }

    // function downloadURI(attachments) {
    //     if(!_.isEmpty(attachments)){
    //         attachments.map((attachment, idx) => {
    //             var link = document.createElement('a');
    //             link.download = `attachment${idx+1}-${attachment.uploaded_file_name}`
    //             link.href = attachment.data_uri
    //             link.click();
    //             return true;
    //         })
    //     }
    //     else{
    //         dispatch(showSnackbarWithTimeout('No Attachments Found', 3000));
    //     }
    //   }

    const onAddActivityFormSubmit = (submittedData) => {

        dispatch(showLoader('Adding Activity...'));

        const formData = new FormData();
        formData.append('comments', submittedData.comments);
        formData.append('activity_name', submittedData.activity_name);
        formData.append('activity_type', submittedData.activity_type);
        formData.append('job_status', submittedData.job_status);
        submittedData.file.map(file=>formData.append('file', file))
        data.product_list.map(id=>formData.append('product_id_list', id))

        api.addActivity(formData, data.bp_id, data.job_id).then((response) => {
            console.log(response);
            dispatch(fetchActivitiesForJob({ job_id: data.job_id, offset: (tablePaginationQuery.activities.page - 1) * tablePaginationQuery.activities.limit, limit: tablePaginationQuery.activities.limit }))
            dispatch(fetchJobsByJobId(data.job_id, rowIndex))
            setIsFormOpen(false);
            dispatch(hideLoader());
            setTab('activities')
            dispatch(showSnackbarWithTimeout('Activity added Successfully!', 4000))
        }).catch(err => {
            dispatch(hideLoader());
            // console.log(err, err.reponse, err.response.data, err.response.data.data)
            dispatch(showSnackbarWithTimeout(_.get(err, 'response.data.more_info', 'Something Went Wrong!'), 4000))
        })

    }

    const productTableHeaders = [
        { name: `Confirm ${_.isEmpty(labelAdjective)?'Counterfeit':labelAdjective}`, disableSwitch:!isEditAllowed, dataKey: "confirm_counterfiet", isSwitch: true, width: "10%", align: "left", trueCondition: 1, falseCondition: 0, onChange:_.isEqual(_.lowerCase(_.get(data, 'job_status', '')), 'closed') ? ()=>dispatch(showSnackbarWithTimeout("Can't perform this action for a Closed Job", 4000)) : handleCounterfeitUpdate},
        { name: "Product Name", dataKey: "product_name", align: "left", width: "30%"},
        { name: "Source", dataKey: "source", align: "left", width: "10%" },
        { name: "Seller Name", dataKey: "seller_name", align: "left", width: "10%" },
        { name: "Discovery Date & Time", dataKey: "product_identified_date", align: "left", width: "15%", isDate: true },
        { name: "Amount", dataKey: "product_price", align: "left", width: "10%", format: (text)=>`RM ${text}` },
        { name: "Status", dataKey: "product_status", align: "left", width: "10%", isFlag:true, textKey:'product_status', flagCondition: {yes: 'escalated', no: 'closed', indeterminate: 'investigation'}, flagComponents: {yes: (text)=>renderFlagComponent({color:'error', ...text}), no: (text)=>renderFlagComponent({color:'error', ...text}), indeterminate: (text)=><Typography variant='body2' className={classes.tableBody2} component='span'>{text.text}</Typography>} },
        { name: "Link", align: "center", isActionCol: true, width: "5%", actions: actions },
    ];

    const activityTableHeaders = [
        { name: "Created Date & Time", dataKey: "created_at", isDate: true, width: "15%", align: "left", style: { paddingLeft: 16 } },
        { name: "Activity Name", dataKey: "activity_name", align: "left", width: "15%"},
        { name: "Activity Type", dataKey: "activity_type", align: "left", width: "15%"},
        { name: "Job Status", dataKey: "job_status", align: "left", width: "10%" },
        { name: "Comments", dataKey: "comments", align: "left", width: "40%" },
        { name: "Attachments", align: "center", isActionCol: true, width: "5%", actions: activityActions },
    ];

    const handleFormOpening = () => setIsFormOpen(true);

    useEffect(()=>{
        if(!_.isEmpty(data)){
            // dispatch(fetchProductsForJob({filters:{product_id: data.product_list}, offset:(tablePaginationQuery.products.page-1)*tablePaginationQuery.products.limit, limit:tablePaginationQuery.products.limit, bp_id: data.bp_id}))
            dispatch(fetchActivitiesForJob({job_id: data.job_id, offset:(tablePaginationQuery.activities.page-1)*tablePaginationQuery.activities.limit, limit:tablePaginationQuery.activities.limit}))
        }
        // return ()=>{
        //     // setTablePaginationQuery({products:{page:1, limit: 10}, activities:{page:1, limit: 10}});
        //     dispatch(clearJobData())
        // }
    }, [data, tablePaginationQuery.activities])

    useEffect(()=>{
        if(!_.isEmpty(data)){
            dispatch(fetchProductsForJob({filters:{product_id: data.product_list}, offset:(tablePaginationQuery.products.page-1)*tablePaginationQuery.products.limit, limit:tablePaginationQuery.products.limit, bp_id: data.bp_id}))
            // dispatch(fetchActivitiesForJob({job_id: data.job_id, offset:(tablePaginationQuery.activities.page-1)*tablePaginationQuery.activities.limit, limit:tablePaginationQuery.activities.limit}))
        }
        return ()=>{
            // setTablePaginationQuery({products:{page:1, limit: 10}, activities:{page:1, limit: 10}});
            dispatch(clearJobData());
            setTab('products');
        }
    }, [data, tablePaginationQuery.products])

    useEffect(()=>{
        if(!_.isNil(productsData) && !_.isEmpty(productsData)){
            const tempPageProps = getDetailedPaginationProperties(tablePaginationQuery.products.limit, productsData.data.total_size, tablePaginationQuery['products'].page);
            setPaginationFooterDetails({...paginationFooterDetails, products:tempPageProps})
        }
    }, [productsData])

    useEffect(()=>{
        if(!_.isNil(activitiesData) && !_.isEmpty(activitiesData)){
            const tempPageProps = getDetailedPaginationProperties(tablePaginationQuery.activities.limit, activitiesData.data.total_size, tablePaginationQuery['activities'].page);
            setPaginationFooterDetails({...paginationFooterDetails, activities:tempPageProps})
        }
    }, [activitiesData])

     return (<>
        <Dialog open={open} disableBackdropClick TransitionComponent={Transition} fullScreen disableEscapeKeyDown={false} onClose={handleClose} maxWidth='xl' scroll='paper' fullWidth classes={{ paper: classes.muiDialogPaper, container: classes.muiDialogContainer }}>
            <DialogTitle>
                <Grid container>
                    <Grid item sm={8}>
                        <Typography variant='h4'>{jobTitle}</Typography>
                        <Typography variant='h6'>Job ID: {jobSubTitle}</Typography>
                    </Grid>
                    <Grid item container sm={4} justify='flex-end' alignItems='center'>
                        {
                          isEditAllowed && <Button variant='contained' disableElevation color='primary' 
                            {...(_.isEqual(_.lowerCase(_.get(data, 'job_status', '')), 'closed'))?{onClick:()=>dispatch(showSnackbarWithTimeout("Can't add activity for a Closed Job!", 4000))}:{onClick:handleFormOpening}}
                            className={classes.buttonInTitle}>Add Activity</Button>
                        }
                        {isExportAllowed && <Button variant='outlined' disableElevation color='primary' disabled className={classes.buttonInTitle}>Export as PDF</Button>}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {
                        kpiData.map((kpi, idx) => (
                            <Grid key={kpi.label + idx} item md={2} xs={4} className={classes.dataWithLabelContainer}><DataWithLabel label={kpi.label} data={kpi.data} isJobPriority={kpi.isJobPriority}/></Grid>
                        ))
                    }
                </Grid>
                <Grid container item sm={12} className={classes.tabsContainer}>
                    <Tabs classes={tabsStyleProps} onChange={(e, v) => setTab(v)} value={tab} TabIndicatorProps={{ children: <div />, classes: { ...tabStyleProps } }}>
                        <Tab label="Product List" value='products' classes={tabStyleProps} disableRipple />
                        <Tab label="Activity List" value='activities' classes={tabStyleProps} disableRipple />
                    </Tabs>
                </Grid>
                <TabPanel value={tab} tabKey='products'>
                    <ProductTable
                        elevation={0}
                        isLoading={productsData.isFetching}
                        page={tablePaginationQuery['products'].page}
                        limit={tablePaginationQuery['products'].limit}
                        goToPage={(pageNo)=>goToPage(pageNo, 'products')}
                        setPageLimit={(limit)=>setPageLimit(limit, 'products')}
                        paginationFooterDetails={paginationFooterDetails['products']}
                        data={_.get(productsData, 'data.items', '-') !== "-" ? productsData.data.items : []}
                        wholeData={productsData}
                        enableBorderBottom
                        // keyForCheckBoxDisabling='job_status'
                        // hasCheckBoxDisabled={()=> _.isEqual(_.lowerCase(_.get(data, 'job_status', '')), 'closed')}
                        bodyScroll
                        headers={productTableHeaders}
                    />
                </TabPanel>
                <TabPanel value={tab} tabKey='activities'>
                    <ProductTable
                        elevation={0}
                        isLoading={activitiesData.isFetching}
                        page={tablePaginationQuery['activities'].page}
                        limit={tablePaginationQuery['activities'].limit}
                        goToPage={(pageNo)=>goToPage(pageNo, 'activities')}
                        setPageLimit={(limit)=>setPageLimit(limit, 'activities')}
                        paginationFooterDetails={paginationFooterDetails['activities']}
                        data={_.get(activitiesData, 'data.items', '-') !== "-" ? activitiesData.data.items : []}
                        wholeData={activitiesData}
                        enableBorderBottom
                        bodyScroll
                        headers={activityTableHeaders}
                    />
                </TabPanel>
            </DialogContent>

            <IconButton onClick={handleClose} size='small' className={classes.closeButtonInDialog}>
                <Icon>close</Icon>
            </IconButton>
        </Dialog>

        <AttachmentsPopUp data={selectedActivity} handleClose={()=>setSelectedActivity({})} />

        <AddActivityForm open={isFormOpen} onClose={()=>setIsFormOpen(false)} onSubmit={onAddActivityFormSubmit} productIdList={data.product_list} />
    </>)
}

const TabPanel = (props) => {

    const { children, value, tabKey } = props
    return (
        <div hidden={value !== tabKey}>
            {children}
        </div>
    )
}

export default ViewJobDialog;
