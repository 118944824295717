import React from 'react';
import AmazonDB from './Amazon';
import Hive from './Hive';
import MongoDB from './MongoDB';
import MySQL from './MySQL';
import Oracle from './Oracle';
import PostgreSQL from './PostgreSQL';
import { DATA_CONNECTION_TYPES } from '../../../constants';
import Elastic from './Elastic';
import AmazonRedShift from './AmazonRedShift';
import AmazonElasticSearch from './AmazonElasticSearch';
import MicroSoft from './MicroSoft';
import MapRFS from './MapRFS';
import Azure from './Azure';
import HDFS from './HDFS';
import NAS from './NAS';



const ConnectionForm = ({formRef, initialFormData, connectionType, isViewMode, onSubmitForm}) => {
    switch (connectionType) {
        
        case DATA_CONNECTION_TYPES.S3:
          return <AmazonDB ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.HIVE:
          return <Hive ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.MONGO:
          return <MongoDB ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.MYSQL:
          return <MySQL ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.ORACLE:
          return <Oracle ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.POSTGRES:
          return <PostgreSQL ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.ELASTIC:
          return <Elastic ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.REDSHIFT:
          return <AmazonRedShift ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.AMAZONELASTIC:
          return <AmazonElasticSearch ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.MSSQL:
        return <MicroSoft ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.MAPRFS:
        return <MapRFS ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.ADL:
        return <Azure ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.HDFS:
        return <HDFS ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
        case DATA_CONNECTION_TYPES.NAS:
        return <NAS ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
          default:
          return null;
    }
}

export default ConnectionForm;