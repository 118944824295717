import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Tabs, Tab, Typography, Badge } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { PAGE_MODES } from '../../../../constants';
import Validator from './validator';
import { Form } from 'formik';
import _ from 'lodash';
import { showSnackbarWithTimeout } from 'services/snackbar/actions';
import {useDispatch} from 'react-redux';
import { hideLoader } from 'services/loader/actions';
import { createNewUser, fetchUserViaSaga, updateUser } from 'services/users/actions';
import BasicDetails from './BasicDetails';
import Roles from './Roles';
import Slide from '@material-ui/core/Slide';
import classnames from 'classnames';
import TeamUnit from './TeamUnit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getDialogTitle = (action) => {
    switch(action){
        case PAGE_MODES.CREATE:
            return "Add User";
        case PAGE_MODES.EDIT:
            return "Edit User";
        case PAGE_MODES.VIEW:
            return "View User";
        default:
            return "";
    }
}

const useStyles = makeStyles((theme) => ({
    dialog: {
      [theme.breakpoints.up('md')]:{
        position: 'absolute',
        top: 20
      }
    },
    tab:{
      textTransform:'none',
      [theme.breakpoints.down('xs')]: {
        fontSize:13
      }
    },
    customTabTitle:{
      fontSize:14,
      fontWeight:'500',
      [theme.breakpoints.down('xs')]: {
        fontSize:13
      }
    },
    dialogTitle:{
      paddingBottom:0,
      borderBottom:`1px solid ${theme.palette.grey[300]}`,
      '& h6':{
        [theme.breakpoints.down('xs')]: {
          fontSize:16
        }
      }
    },
    dialogContent:{
      paddingTop:theme.spacing(2)
    },
    dialogFooter:{
        padding:theme.spacing(2),
        borderTop:`1px solid ${theme.palette.grey[300]}`
    },
    badge:{
      marginLeft:theme.spacing(1)
    },
    hideBtn:{
      display:'none'
    }
}));

const FormDialog = ({open, userId, action, handleClose, isRoleListLoading, roleList, isEditAllowed, changeToEditMode}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogTitle = getDialogTitle(action);
    const classes = useStyles();
    const [activeTabIdx, setActiveTabIdx] = useState(0);
    const [extUsrState, setExtUsrState] = useState({isFetchingUsr:false, userDetails:{}});
    const formRef = useRef();
    const dispatch = useDispatch();

    const { isFetchingUsr, userDetails } = extUsrState;

    const isEditMode = _.isEqual(action, PAGE_MODES.EDIT);
    const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE);
    const isViewMode = _.isEqual(action, PAGE_MODES.VIEW);

    const onChangeTab = (e, tabIdx) => {
        setActiveTabIdx(tabIdx);
    }

    const onSubmitForm = (values) =>{
       const successCallback = ()=>{ handleClose(null, true); };
       const closeErrDialog = () => { dispatch(hideLoader()); };
       if(action === PAGE_MODES.CREATE){
         const {password, retype_password, ...userDetails} = values;
         dispatch(createNewUser(userDetails, successCallback, closeErrDialog));
       }
       else if(action === PAGE_MODES.EDIT){
         let userDetails = values;
         if(typeof _.get(userDetails, 'profile_picture') === "string"){
           userDetails["profile_pic_url"] = _.get(userDetails, 'profile_picture');
         }
         const roleIds = _.join(_.get(userDetails, 'role_id', []), ',');
         dispatch(updateUser(userId, {...userDetails, role_id:roleIds}, successCallback, closeErrDialog));
       }
    }

    const onClickEditBtn = () => {
      if(action === PAGE_MODES.VIEW){
        changeToEditMode(userId);
      }
    }

    const onFormSubmitErr = () => {
       if(!_.isEmpty(formRef.current, 'state.errors')){
         if(activeTabIdx > 0){
           setActiveTabIdx(0);
         }
         dispatch(showSnackbarWithTimeout("Please fix all the form errors", 2000));
       }
    }

    const onClickActionBtn = () => {
      formRef.current && formRef.current.submitForm();
    }

    useEffect(()=>{
      if(!_.isEqual(action, PAGE_MODES.CREATE) && !_.isEmpty(action) && _.isEmpty(userDetails)) 
      {
          setExtUsrState((prevState)=>({...prevState, isFetchingUsr:true}));
          dispatch(fetchUserViaSaga(userId, (resp)=>{
            const {first_name, last_name, email, phone, is_email_verified, is_active, profile_pic_url, role_id, tu_code, tu_details} = _.get(resp, 'data.data');
            const userDetails = {
                first_name, last_name, email, phone, is_active, is_email_verified, tu_code:_.get(tu_code, '[0]', ''),
                role_id : role_id, password:"", retype_password:"", profile_picture:profile_pic_url,
                tu_details
            };
            setExtUsrState((prevState)=>({...prevState, isFetchingUsr:false, userDetails}));
          }, ()=>{
            setExtUsrState((prevState)=>({...prevState, isFetchingUsr:false}));
            dispatch(hideLoader());
            handleClose(null, true);
          }));
      }
    }, [action, userDetails, userId]);

    //reset exsting user details on form dialog close event
    useEffect(()=>{
       if(!open)
       {
         setExtUsrState((prevState)=>({...prevState, isFetchingUsr:false, userDetails:{}}));
         setActiveTabIdx(0);
       }
    }, [open]);

    const openDialog = open && !isFetchingUsr;

    return (
     <Dialog 
        classes={{
          paper: classes.dialog
        }}
        TransitionComponent={Transition}
        fullScreen={fullScreen} maxWidth={"sm"} open={openDialog} onClose={handleClose} aria-labelledby="user-form-title">
        {!isFetchingUsr ? 
          <Validator formMode={action} initialFormData={userDetails} onSubmit={onSubmitForm} ref={formRef}>
                {(formik) => {
                  return (
                    <>
                          <DialogTitle className={classes.dialogTitle} disableTypography={true} id="user-dialog-title">
                            <Typography variant="h6">
                              {dialogTitle}
                            </Typography>
                            <Tabs 
                              orientation="horizontal"
                              value={activeTabIdx}
                              indicatorColor="primary"
                              onChange={onChangeTab}
                              variant="fullWidth"
                              aria-label="User form tabs"
                              >
                              <Tab className={classes.tab} label={"Basic Details"}></Tab>
                              <Tab className={classes.tab} label={
                                    <Typography className={classes.customTabTitle}>
                                          Roles {_.get(formik, 'values.role_id.length', 0) > 0 && <Badge color="primary" className={classes.badge} badgeContent={_.get(formik, 'values.role_id.length', 0)} />}
                                    </Typography>
                              }></Tab>
                              <Tab className={classes.tab} label={"Team Unit"}></Tab>
                            </Tabs>
                          </DialogTitle>
                          <DialogContent className={classes.dialogContent}>
                          
                                  <Form id="user-form" autoComplete="new-password">
                                    <Effect
                                      formik={formik}
                                      onSubmissionError={onFormSubmitErr}
                                    />
                                    <div hidden={activeTabIdx !== 0} className={classes.tabPanel}>
                                      <BasicDetails 
                                        isViewMode={isViewMode} 
                                        isEditMode={isEditMode} 
                                        isCreateMode={isCreateMode} 
                                        formik={formik}
                                      />
                                    </div>
                                    <div hidden={activeTabIdx !== 1} className={classes.tabPanel}>
                                      <Roles 
                                        isViewMode={isViewMode} 
                                        isEditMode={isEditMode} 
                                        isCreateMode={isCreateMode} 
                                        isRoleListLoading={isRoleListLoading}
                                        roleList={roleList}
                                        formRef={formRef}
                                      />
                                    </div>
                                    <div hidden={activeTabIdx !== 2} className={classes.tabPanel}>
                                       <TeamUnit
                                         isViewMode={isViewMode} 
                                         teamDetails={_.get(userDetails, 'tu_details', [])}
                                         isCreateMode={isCreateMode || isEditMode} 
                                        />
                                    </div>
                                  </Form>
                          </DialogContent>
                          <DialogActions className={classes.dialogFooter}>
                            <Button variant="outlined" size={fullScreen ? "small" : "medium"} onClick={handleClose} color="primary">
                            { isViewMode ? "Close" : "Cancel"}
                            </Button>

                            <Button variant="contained" 
                                onClick={ isViewMode ? onClickEditBtn : onClickActionBtn } 
                                size={fullScreen ? "small" : "medium"} 
                                className={classnames({[classes.hideBtn]: isViewMode && !isEditAllowed})}
                                color="primary" disableElevation>
                              { isViewMode ? "Edit" : "Save"}
                            </Button>
                          </DialogActions>
                      </>
                  )
              }
            } 
        </Validator> : <></> }
        
      </Dialog>
    );
}

function Effect(props) {
    const effect = () => {
      if (props.formik.submitCount > 0 && !props.formik.isValid) {
        props.onSubmissionError();
      }
    };
    useEffect(effect, [props.formik.submitCount]);
    return null;
}

FormDialog.propTypes = {
    open:PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    changeToEditMode:PropTypes.func.isRequired,
    isRoleListLoading:PropTypes.bool.isRequired,
    roleList:PropTypes.array,
    userId:PropTypes.string,
    action: PropTypes.string,
    isEditAllowed:PropTypes.bool
}

export default FormDialog;