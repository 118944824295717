import { ACTION_TYPES } from "../../constants";

const initialState = {products: {isFetching: false, isError: false, error: undefined, data: {}}, activities: {isFetching: false, isError: false, error: undefined, data: {}}}

const jobData = (state = initialState, action) => {
    switch(action.type){
        case ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_PENDING: {
            return {
                ...state,
                [action.key]: {
                    isFetching: true,
                    error: undefined,
                    isError: false,
                    data: {}
                }
            }
        }

        case ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_FULFILLED: {

            const data = {items: action.key==='products'?action.payload.items:action.payload.job_activities, total_size: action.key==='products'?action.payload.total_size:action.payload.total_count}

            return {
                ...state,
                [action.key]: {
                    isFetching: false,
                    error: undefined,
                    isError: false,
                    data: Object.assign([], data)
                }
            }
        }

        case ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_REJECTED: {
            return {
                ...state,
                [action.key]: {
                    isFetching: false,
                    error: action.payload,
                    isError: true,
                    data: {}
                }
            }
        }

        case ACTION_TYPES.CLEAR_JOB_DATA: {
            return {products: {isFetching: true, isError: false, error: undefined, data: {}}, activities: {isFetching: true, isError: false, error: undefined, data: {}}}
        }

        default: {
            return state
        }
    }
}

export default jobData;