import { ACTION_TYPES } from "../../../constants";
import { buildUIFiltersFromApiResp, buildIPFiltersFromApiResp } from "../api";
import _ from 'lodash';
import graphsDataRespReducer from './graphsData';



const getDefaultState = () => ({
    isFetching: false,
    isError: false,
    errMsg: '',
    details: {},
    filters: [],
    inPageFilters: [],
    popUpFilters:[],
    graphsDataResp: {},
    // updateDashboard:{},
});

const popupDashboard = (state = getDefaultState(), action) => {
    let existingState = _.cloneDeep(state);
    switch (action.type) {
        case ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_PENDING:
            return {
                ...existingState,
                isFetching: true,
                isError: false,
                errMsg: '',
                details: {},
                graphsDataResp: {},
                // updateDashboard:{},
                filters: []
            }
        case ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_FULFILLED:
            return {
                ...existingState,
                isFetching: false,
                isError: false,
                errMsg: '',
                details: _.get(action, 'payload.data.data', {}),
                filters: buildUIFiltersFromApiResp(_.get(action, 'payload.data.data.filter_definitions', [])),
                inPageFilters: buildIPFiltersFromApiResp(_.get(action, 'payload.data.data.in_page_filters', [])),
                popUpFilters: buildIPFiltersFromApiResp(_.get(action, 'payload.data.data.popup_filters',[]))
            }
        case ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DETAILS_REJECTED:
            return {
                ...existingState,
                isFetching: false,
                isError: true,
                errMsg: '',
                details: {},
                filters: []
            }
        case ACTION_TYPES.UPDATE_POPUP_DASHBOARD_DETAILS:
            return {
                ...existingState, popUpFilters: action.payload
            }
        case ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_PENDING:
            return {
                ...existingState,
                graphsDataResp: graphsDataRespReducer(state.graphsDataResp, action)
            }
        case ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_FULFILLED:
            return {
                ...existingState,
                graphsDataResp: graphsDataRespReducer(state.graphsDataResp, action)
            }
        case ACTION_TYPES.FETCHING_POPUP_DASHBOARD_DATA_REJECTED:
            return {
                ...existingState,
                graphsDataResp: graphsDataRespReducer(state.graphsDataResp, action)
            }
        case ACTION_TYPES.RESET_POPUP_DASHBOARD:
            return {
                ...existingState,
                isFetching: false,
                isError: false,
                errMsg: '',
                details: {},
                filters: [],
                inPageFilters: [],
                popUpFilters:[],
                graphsDataResp: {}
            }
        default:
            return state;
    }
}

export default popupDashboard;