import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme=>({
    primary:{ background: theme.palette.primary.main },
    secondary:{ background: theme.palette.secondary.main },
    success:{ background: theme.palette.success.main },
    error:{ background: theme.palette.error.main },
    warning:{ background: theme.palette.warning.main },
    info:{ background: theme.palette.info.main },
    primaryTextFlag:{ color: theme.palette.primary.main, background: `${theme.palette.primary.light}1e` },
    secondaryTextFlag:{ color: theme.palette.secondary.main, background: `${theme.palette.secondary.light}1e` },
    successTextFlag:{ color: theme.palette.success.main, background: `${theme.palette.success.light}1e` },
    errorTextFlag:{ color: theme.palette.error.main, background: `${theme.palette.error.light}1e` },
    warningTextFlag:{ color: theme.palette.warning.main, background: `${theme.palette.warning.light}1e` },
    infoTextFlag:{ color: theme.palette.info.main, background: `${theme.palette.info.light}1e` },
    textFlag:{
        padding: theme.spacing(1 , 1.5),
        // color: theme.palette.almostBlack[0],
        borderRadius: 4
    },
    iconButtonDiv:{
        borderRadius: '50%',
        padding: theme.spacing(1),
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        flexGrow: 0,
        '& .MuiIcon-root':{
            fontSize: theme.typography.pxToRem(32),
            color: theme.palette.almostBlack[0]
        }
    },
    mediumIcon:{
        width: 28,
        height: 28,
        padding: theme.spacing(0.5),
        '& .MuiIcon-root':{
            fontSize: theme.typography.pxToRem(24)
        }
    },
    smallIcon:{
        width: 20,
        height: 20,
        padding: theme.spacing(0.5),
        '& .MuiIcon-root':{
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 'bold'
        }
    },
    tinyIcon:{
        width: 8,
        height: 8,
        padding: theme.spacing(0),
        '& .MuiIcon-root':{
            fontSize: theme.typography.pxToRem(0),
        }
    },
    textWithIconContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& $tinyIcon~$textWithIcon':{
            paddingLeft: theme.spacing(1)
        }
    },
    textWithIcon:{
        paddingLeft: theme.spacing(2),
    },
}));

const textVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'subtitle1', 'subtitle2'];

const FlagComponent = (props) => {

    const {color, text, icon, size, textVariant, className} = props;
    const variant = _.includes(textVariants, textVariant) ? textVariant : 'body2'
    const classes = useStyles();

    function _renderFlag(){
        if((icon && _.isObject(icon)) && _.isNil(text)){
            let iconSizeClass = `${size}Icon`
            return (<div className={classnames(classes.iconButtonDiv, classes[color], classes[iconSizeClass], className)}>{icon}</div>)
        }
        else if(text && _.isNil(icon)){
            let colorClassName = `${color}TextFlag`
            return (<Typography variant={variant} component='span' className={classnames(classes.textFlag, classes[colorClassName])}>{text}</Typography>)
        }
        else if(size==='tiny' && !_.isNil(text)){
            let iconSizeClass = `${size}Icon`
            return (
                <div className={classes.textWithIconContainer}>
                    <div className={classnames(classes.iconButtonDiv, classes[color], classes[iconSizeClass])}></div>
                    <Typography variant={variant} component='span' className={classnames(classes.textWithIcon)}>{text}</Typography>
                </div>
            )
        }
        else if((icon && _.isObject(icon)) && !_.isNil(text)){
            let iconSizeClass = `${size}Icon`
            return (
                <div className={classes.textWithIconContainer}>
                    <div className={classnames(classes.iconButtonDiv, classes[color], classes[iconSizeClass])}>{icon}</div>
                    <Typography variant={variant} component='span' className={classnames(classes.textWithIcon)}>{text}</Typography>
                </div>
            )
        }
        else{
            return null
        }
    }

    return _renderFlag()
}

FlagComponent.defaultProps = {
    color: 'primary',
    size: 'medium',
    textVariant: 'body2'
}

FlagComponent.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning', 'info']).isRequired,
    icon: PropTypes.node,
    text: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'tiny']),
    textVariant: PropTypes.oneOf(textVariants)
}

export const renderFlagComponent = (props) => <FlagComponent {...props} />

export default FlagComponent;