import {put, call, takeLatest} from 'redux-saga/effects';
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants';
import searchSuggestionsApi from '../api';

function* getStyleCodeSuggestions(action) {
    yield put({type: ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_PENDING});
    try{
        const styleCodes = action.payload.product_type === 'new' ?  yield call(searchSuggestionsApi.searchSuggestionsInProductsSearchApi, action.payload) :  yield call(searchSuggestionsApi.searchSuggestionsInProductsSearchApi, action.payload);
        yield put({type: ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_FULFILLED, payload: styleCodes.data.data, key:action.payload.product_type === 'new'?'tss_style_code':'objectID'})
    }
    catch(err){
        yield put({type: ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_REJECTED})
    }
}

function* getRetailerSuggestions(action) {
    yield put({type: ACTION_TYPES.GET_RETAILER_SUGGESTIONS_PENDING});
    try{
        const retailers = yield call(searchSuggestionsApi.searchSuggestionsInProductsSearchApi, action.payload);
        yield put({type: ACTION_TYPES.GET_RETAILER_SUGGESTIONS_FULFILLED, payload: retailers.data.data})
    }
    catch(err){
        yield put({type: ACTION_TYPES.GET_RETAILER_SUGGESTIONS_REJECTED})
        console.log('error', err)
    }
}
function* getProductSuggessions(action) {
    yield put({type: ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_PENDING});
    try{
        const products = yield call(searchSuggestionsApi.searchSuggestionsInProductsSearchApi, action.payload);
        yield put({type: ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_FULFILLED, payload: products.data.data})
        console.log('products', products)
    }
    catch(err){
        yield put({type: ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_REJECTED})
        console.log('errerr', err)
    }
}

function* getResellerSuggessions(action) {
    yield put({type: ACTION_TYPES.GET_RESELLER_SUGGESSIONS_PENDING});
    try{
        const products = yield call(searchSuggestionsApi.searchSuggestionsInProductsSearchApi, action.payload);
        yield put({type: ACTION_TYPES.GET_RESELLER_SUGGESSIONS_FULFILLED, payload: products.data.data})
        console.log('products', products)
    }
    catch(err){
        yield put({type: ACTION_TYPES.GET_RESELLER_SUGGESSIONS_REJECTED})
        console.log('errerr', err)
    }
}




export function* watchGetStyleCodeSuggestions() {
    yield takeLatest(SAGA_ACTIONS.GET_STYLE_CODE_SUGGESTIONS, getStyleCodeSuggestions)
}

export function* watchGetRetailerSuggestions() {
    yield takeLatest(SAGA_ACTIONS.GET_RETAILER_SUGGESTIONS, getRetailerSuggestions)
}

export function* watchGetProductSuggessions() {
    yield takeLatest(SAGA_ACTIONS.GET_PRODUCT_SUGGESSIONS, getProductSuggessions)
}

export function* watchGetResellerSuggessions() {
    yield takeLatest(SAGA_ACTIONS.GET_RESELLER_SUGGESSIONS, getResellerSuggessions)
}

