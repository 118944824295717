import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModulePermissionItem from './ModulePermissionItem';
import { useTheme } from '@material-ui/core';
import { ROLE_MODULE_CONFIG, ROLE_MODULES_LABEL, PERMISSION_TYPE_LABEL } from '../../constants/modules';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';
import update from 'immutability-helper';

const useStyles = makeStyles((theme)=>({
    tableHeaderCell:{
        fontSize:10,
        paddingBottom:theme.spacing(0.5),
        paddingTop:4,
        paddingRight:theme.spacing(2),
        textTransform:'uppercase',
        letterSpacing:2,
        lineHeight:'12px',
        fontWeight:'bold',
        color:theme.palette.grey[600]
    },
    greyBg:{
        backgroundColor:theme.palette.grey[100]
    },
    permissionCell:{
        width:70
    },
    tableContainer:{
        maxHeight:440,
        '&::-webkit-scrollbar,&::-webkit-scrollbar-track': {
            width: '10px',
            height: '10px',
            '-webkit-appearance': 'none',
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'rgba(213, 213, 220, 1)',
            height: '80px',
            borderRadius: '5px'
        }
    }
}));

function PermissionInput({name, value, isMobileView, onChange, viewMode}) {
    const classes = useStyles();
    
    const modulePermissionsVal = useMemo(()=>{
        return _.reduce(value, (result, moduleVal)=>{
            return _.set(result, _.get(moduleVal, 'module_name'), _.get(moduleVal, 'permissions'));
        }, {});
    }, [value]);

    const onChangePermission = useCallback((moduleName, permissions) => {
        const moduleIdx = _.findIndex(value, (module) => _.isEqual(_.get(module, 'module_name'), moduleName));
        const objectToAddOrRep = { module_name:moduleName, permissions: permissions };
        const actionToPerform = moduleIdx > -1 ? { 
            [moduleIdx]: { $set : objectToAddOrRep } 
        } : {
            $push : [objectToAddOrRep]
        };
        onChange({ target:{ name, value: update(value, actionToPerform) } });
    }, [value, name, onChange]);

    const getPermissionItems = useCallback(()=>{
        return _.map(_.keys(ROLE_MODULE_CONFIG), (module)=>{
            const moduleLabel = ROLE_MODULES_LABEL[module];
            return (
                <ModulePermissionItem 
                    key={module} 
                    isMobileView={isMobileView} 
                    moduleName={module} 
                    value={_.get(modulePermissionsVal, module, {})}
                    onChange={onChange}
                    viewMode={viewMode}
                    onChange={onChangePermission}
                    moduleDisplayName={moduleLabel}
                    modulePermissions={ROLE_MODULE_CONFIG[module]}>
                </ModulePermissionItem>
            )
        });
    }, [isMobileView, onChange, viewMode, modulePermissionsVal]);

    if(isMobileView) {
        return (
            <div>
               {getPermissionItems()}
            </div>
        );
    }
    else{
        return (
            <TableContainer className={classes.tableContainer} component={"div"}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>Module Name</TableCell>
                            {
                                _.map(_.values(PERMISSION_TYPE_LABEL), (permission, idx)=>(
                                    <TableCell 
                                        key={idx} 
                                        align="center" 
                                        className={
                                            classnames(
                                                classes.permissionCell, 
                                                classes.tableHeaderCell,
                                                {[classes.greyBg]:idx%2===0}
                                            )
                                        }
                                    >
                                        {permission}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPermissionItems()}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

PermissionInput.propTypes = {
    isMobileView:PropTypes.bool.isRequired,
    value:PropTypes.arrayOf(PropTypes.shape({
        module_name:PropTypes.string,
        permissions:PropTypes.object
    })),
    onChange:PropTypes.func,
    viewMode:PropTypes.bool
};

export default PermissionInput;