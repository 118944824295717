import {put, call, takeLatest} from 'redux-saga/effects';
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants';
import api from '../api';
import _ from 'lodash';

function* fetchLabel(){
    yield put({type:ACTION_TYPES.FETCH_LABEL_DATA_PENDING});
    try{
        const data = yield call(api.fetchLabelData);
        yield put({type: ACTION_TYPES.FETCH_LABEL_DATA_FULFILLED, payload: data});
        yield put({type:ACTION_TYPES.GET_SUGGESTIONS_FULFILLED, filterKey: 'job_status', data:{items:_.get(data, 'data.data.job_status', []), total_size:_.get(data, 'data.data.job_status', []).length}})
    }
    catch(err){
        yield put({type: ACTION_TYPES.FETCH_LABEL_DATA_REJECTED})
    }
}

export function* watchFetchlabel(){
    yield takeLatest(SAGA_ACTIONS.FETCH_LABEL_DATA, fetchLabel)
}