import React, {useEffect, useState, useMemo} from 'react';
import Layout from '../../components/Layout';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {useUrlSearchParams} from '../../hooks/use-url-search-params';
import { getDetailedPaginationProperties } from '../../utils';
import { ACTION_COL_OPTIONS, FILTER_CONSTANTS, JOB_PRIORITY } from '../../constants';
import { Typography, makeStyles, Icon } from '@material-ui/core';
import ViewJobDialog from './ViewJobDialog';
import { fetchJobs } from '../../services/jobs/actions';
import { format, isSameDay } from 'date-fns';
import { usePrevious } from 'hooks';
import { clearAllFilters } from 'services/filtersNew/actions';

const useStyles = makeStyles(theme=>({
  tableBody2:{
    color: theme.palette.almostBlack[800],
    letterSpacing: theme.typography.pxToRem(0.1)
  },
  flagCircle:({priority})=>{
      const getPriorityColor = (priority) =>{
        switch(priority){
          case JOB_PRIORITY.LOW:
            return theme.palette.success.main;
          case JOB_PRIORITY.HIGH:
            return theme.palette.error.main;
          case JOB_PRIORITY.MEDIUM:
            return theme.palette.info.main;
          case JOB_PRIORITY.ESCALATED:
            return theme.palette.error.main;
        }
      }
      return {
        width:theme.spacing(1),
        height:theme.spacing(1),
        display:'inline-block',
        marginRight:theme.spacing(1),
        borderRadius:'50%',
        background: getPriorityColor(priority)
      }
    }
}))


const JobPriorityRenderer = ({priority}) =>{
    const classes = useStyles({priority});
    return (
      <>
        <span className={classes.flagCircle}></span>
        <Typography variant="body2" component="span">
          {_.startCase(priority)}
        </Typography>
      </>
    )
}

const Jobs = () => {
  const dispatch = useDispatch();
  const [sortSettings, setSortSettings] = useState({
    keyToSort: "",
    sortDirection: true,
  });

  const appliedFilters = useSelector((state) => state.filtersNew);
  const [jobDataForDialog, setJobDataForDialog] = useState({});
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);



  // const onSortClick = (dataKey) => {
  //   const tempSortSettings = Object.assign({}, sortSettings);

  //   if (tempSortSettings.keyToSort.length > 0) {
  //     if (tempSortSettings.keyToSort === dataKey) {
  //       if (tempSortSettings.sortDirection === false) {
  //         tempSortSettings.keyToSort = "";
  //         tempSortSettings.sortDirection = true;
  //       } else {
  //         tempSortSettings.sortDirection = false;
  //       }
  //     } else {
  //       tempSortSettings.keyToSort = dataKey;
  //       tempSortSettings.sortDirection = true;
  //     }
  //   } else {
  //     tempSortSettings.keyToSort = dataKey;
  //     tempSortSettings.sortDirection = true;
  //   }
  //   // console.log('tempSortSettings', tempSortSettings)
  //   setSortSettings(tempSortSettings);
  // };

  const sortFilter = (sortSettingObj) => {
    return sortSettingObj && sortSettingObj.keyToSort === ""
      ? {}
      : { [sortSettingObj.keyToSort]: sortSettingObj.sortDirection ? 1 : -1 };
  };

  const onClickRow = (e, record, recIdx) => {
    setJobDataForDialog(record);
    setSelectedRowIdx(recIdx);
    // setEditObjectID(record.objectID);
    // dispatch({type: 'SET_TO_UPDATE_OBJECT', payload: {objectID: record.objectID}});
    // dispatch(setUpdateObj({objectID: record.objectID}))
    setIsModalOpen(true);
  };

  const onCheckBoxClick = (e, record) => {
    e.stopPropagation();
    // console.log("check box clixked", record)
  };

  const actions = [
    {
      action: ACTION_COL_OPTIONS.VIEW,
      icon: "remove_red_eye",
      onClick: onClickRow,
      size: 'medium'
    },
  ];

  // const downloadFxn = (objectIDCollection) => {
  //   // dispatch(showLoader('Preparing Data'));
  //   const objectIDQuery = _.isEmpty(_.get(appliedFilters, "objectID", []))
  //     ? _.isEmpty(_.get(objectIDCollection, "objectID", []))
  //       ? []
  //       : _.get(objectIDCollection, "objectID", [])
  //     : _.get(appliedFilters, "objectID", {});
  //   const fetchNewProductsQuery = {
  //     export: true,
  //     search_query: { ...appliedFilters, objectID: objectIDQuery },
  //     sort: sortFilter(sortSettings),
  //     filter_value: searchQuery,
  //     product_type: "old",
  //     call_from: "saga for old products",
  //   };

  //   Axios.post(API_ENDPOINTS.EXPORTER, fetchNewProductsQuery, {
  //     headers: { isAuthRequired: true, "Content-Type": "application/json" },
  //   })
  //     .then((response) => {
  //       let blobCSV = new Blob([response.data], { type: "text/csv" });
  //       let url = window.URL.createObjectURL(blobCSV);
  //       let a = document.createElement("a");
  //       a.href = url;
  //       a.download = "report.csv";
  //       a.click();
  //       dispatch(hideLoader());
  //     })
  //     .catch((err) => {
  //       dispatch(hideLoader());
  //       dispatch(
  //         showSnackbarWithTimeout("Something went Wrong! Try again later", 3000)
  //       );
  //     });
  // };

  // const exportFxn = (exportList) => {
  //   if (_.isEmpty(exportList)) {
  //     dispatch(
  //       showSnackbarWithTimeout("Please select some products to export", 3000)
  //     );
  //   } else {
  //     if (_.indexOf(exportList, "all") !== -1) {
  //       downloadFxn({});
  //     } else {
  //       downloadFxn({ objectID: exportList });
  //     }
  //   }
  // };

  const [pageQuery, setPageQuery] = useUrlSearchParams({ page: 1, limit: 10 });
  const { page, limit } = pageQuery;

  // const handleSaveEdit = (to_update, record, objectID) => {
  //   if (!_.isEmpty(to_update)) {
  //     setWebsiteOfUpdatedData(_.get(record, "website", ""));
  //     dispatchProductUpdate(objectID,_.get(record, "website", ""),to_update,page,limit,sortSettings,searchQuery);
  //   } 
  //   else {
  //     dispatch(showSnackbarWithTimeout( "Change any data to save or cancel the operation.", 4000 ));
  //   }
  // };

  const handleModalClose = () => {
    setJobDataForDialog({});
    setIsModalOpen(false);
  };

  const headers = [
    { name: "Job Name", dataKey: "job_title", width: "30%", align: "left", style:{paddingLeft: 16}},
    { 
        name: "Priority", dataKey: "job_priority", align: "left", width: "12%", 
        isCustom: true, 
        customRenderFxn:(priority) => <JobPriorityRenderer priority={priority} />,
    },
    { name: "Target Date",dataKey: "job_due_date", align: "left", width: "18%", isDate: true },
    { name: "Job Status", dataKey: "job_status", align: "left", width: "15%" },
    { name: "Assigned To", dataKey: "assigned_watcher_name", align: "left", width: "20%" },
    { name: "",align: "center", isActionCol: true, width: "5%",actions: actions },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const keyForCheckBoxSelectedList = "job_id";

  const [paginationFooterDetails, setPaginationFooterDetails] = useState({});

  const [exportList, setExportList] = useState([]);

  let tempSelectedList = Object.assign([], exportList);

  const handleCheckBoxClick = (e, record) => {
    e.stopPropagation();
    const isAlreadyAvailable =
      _.indexOf(tempSelectedList, _.get(record, keyForCheckBoxSelectedList)) !==
      -1;
    const isAllSelected = _.indexOf(tempSelectedList, "all") !== -1;

    if (!isAlreadyAvailable) {
      if (isAllSelected) {
        tempSelectedList = newProductsData.map((product) => product.objectID);
        _.remove(tempSelectedList, (objectID) => objectID === _.get(record, keyForCheckBoxSelectedList));
      } 
      else {
        tempSelectedList.push(_.get(record, keyForCheckBoxSelectedList));
      }
    } 
    else if (isAlreadyAvailable) {
      _.remove(tempSelectedList, (objectID) => objectID === _.get(record, keyForCheckBoxSelectedList));
    }
    setExportList(tempSelectedList);
  };

  const handleAllSelectCheckBoxClick = () => {
    if (newProductsData !== "-") {
      const isAllSelected = _.indexOf(tempSelectedList, "all") !== -1;
      const exportListLength = exportList.length;
      if (isAllSelected) {
        setExportList([]);
      } else {
        if (exportListLength > 0) {
          setExportList([]);
        } else {
          setExportList(newProductsData.map((product) => product.objectID));
        }
      }
    }
  };

  const clearSelection = () => setExportList([]);

  const handleSelectAllProducts = () => setExportList(["all"]);

  const goToPage = (pageNo) => {
    setPageQuery({ ...pageQuery, page: pageNo });
  };

  const setPageLimit = (selectedLimit) => {
    setSortSettings({ keyToSort: "", sortDirection: true });
    setPageQuery({ ...pageQuery, limit: selectedLimit, page: 1 });
  };

  const paginationProperties = (pageNo, limit, totalCount, selectedList) => {
    return getDetailedPaginationProperties(limit, totalCount, pageNo, selectedList);
  };

  useEffect(() => {
    setExportList([]);
    setPageQuery({ ...pageQuery, page: 1, sort: sortFilter(sortSettings) });
  }, [sortSettings]);

  const wholeData = useSelector((state) => state.jobs.data);
  const newProductsData = _.get(wholeData, "items", "-");
  const isFetchingNewProducts = useSelector((state) => state.jobs.isFetching);
  // const isErrorFetchingNewProducts = useSelector((state) => state.jobs.isError);

  // useEffect(() => {
  //   if (_.get(objectForUpdate, "objectID", "-") !== "-" && !_.isEmpty(newProductsData) && newProductsData !== "-") {
  //     const tempObj = newProductsData.filter((productObj) => productObj.objectID === _.get(objectForUpdate, "objectID", "-"));
  //     setDialogPropsState({ ...dialogPropsState, record: tempObj[0] });
  //     const specificAffiliate = _.find(_.get(tempObj[0], "affiliates"), (affiliate) => affiliate.website === websiteOfUpdatedData);
  //     if ((_.isNil(tempObj[0]) || _.isNil(_.get(tempObj[0], "objectID")) || _.isNil(specificAffiliate)) && websiteOfUpdatedData !== "") {
  //       handleModalClose();
  //       dispatch(showSnackbarWithTimeout(`The Product with StyleCode ${_.get(objectForUpdate, "objectID", "-")} is updated.`, 5000));
  //       setWebsiteOfUpdatedData("");
  //     }
  //   }
  // }, [newProductsData]);

  const formatFilters = (filters) => {
    
    const formattedFilterObj = {};
    const filtersToValidateAndAdd = [FILTER_CONSTANTS.JOB_NAME,FILTER_CONSTANTS.ASSIGNED_TO,FILTER_CONSTANTS.PRIORITY,FILTER_CONSTANTS.JOB_STATUS];

    filtersToValidateAndAdd.forEach(element => {
      if(filters[element].length > 0 ){
        formattedFilterObj[element] = filters[element]
      }
    });

    if(!_.isEmpty(filters[FILTER_CONSTANTS.TARGET_DAYS])){
      if(isSameDay(filters[FILTER_CONSTANTS.TARGET_DAYS].startDate, filters[FILTER_CONSTANTS.TARGET_DAYS].endDate)){
        formattedFilterObj.job_due_date_iso = [format(filters[FILTER_CONSTANTS.TARGET_DAYS].startDate, 'yyyy-MM-dd')]
      }else{
        formattedFilterObj.job_due_date_iso = [format(filters[FILTER_CONSTANTS.TARGET_DAYS].startDate, 'yyyy-MM-dd'), format(filters[FILTER_CONSTANTS.TARGET_DAYS].endDate, 'yyyy-MM-dd')]
      }
    }

    return formattedFilterObj;
  }

  const [finalQuery, setFinalQuery] = useState({});

  const dispatchFetchQueryFxn = useMemo(()=>{
    if(!_.isEmpty(finalQuery)){
      dispatch(fetchJobs(finalQuery))
    }
  },[finalQuery, dispatch])

  useEffect(()=>dispatchFetchQueryFxn, [finalQuery, dispatchFetchQueryFxn])

  useEffect(() => {
    const fetchJobsQuery = { filters: formatFilters(appliedFilters), offset: (page - 1) * limit, limit: parseInt(limit)};
    setFinalQuery(fetchJobsQuery)
  }, [pageQuery]);

  const prevAppliedFilters = usePrevious(appliedFilters);

  useEffect(() => {
    let fetchJobsQuery = {};
    if(!_.isEqual(appliedFilters, prevAppliedFilters)) {
      fetchJobsQuery = {filters: formatFilters(appliedFilters), offset: 0,limit: parseInt(limit)};
      setPageQuery({ ...pageQuery, page: 1 });
    }
    setFinalQuery(fetchJobsQuery)
  }, [appliedFilters, prevAppliedFilters]);

  useEffect(() => {
    if (wholeData !== "undefined") {
      setPaginationFooterDetails({...paginationProperties(page, limit, _.get(wholeData, "total_size", ""), exportList)});
    }
  }, [wholeData, exportList]);

  useEffect(()=>{
    if(!_.isEmpty(jobDataForDialog) && selectedRowIdx !== -1){
      setJobDataForDialog(newProductsData[selectedRowIdx])
    }
  }, [newProductsData, wholeData])

  useEffect(()=>{
    return ()=>dispatch(clearAllFilters())
  },[])

  return (
    <>
      <Layout
        headers={headers}
        layoutTitle="Jobs"
        layoutTitleIcon='work'
        buttonText='Activity'
        page={page}
        limit={limit}
        setPageLimit={setPageLimit}
        goToPage={goToPage}
        paginationFooterDetails={paginationFooterDetails}
        isLoading={isFetchingNewProducts}
        onClickRow={onClickRow}
        isCheckBoxNeeded={false}
        keyForCheckBoxSelectedList="objectID"
        onCheckBoxClick={onCheckBoxClick}
        handleCheckBoxClick={handleCheckBoxClick}
        handleAllSelectCheckBoxClick={handleAllSelectCheckBoxClick}
        newFilters={true}
        // exportList={exportList}
        // exportFxn={exportFxn}
        // bodyScroll={true}
        enableBorderBottom
        showTheseFilters={[ FILTER_CONSTANTS.TARGET_DAYS, FILTER_CONSTANTS.JOB_NAME, FILTER_CONSTANTS.ASSIGNED_TO, FILTER_CONSTANTS.PRIORITY, FILTER_CONSTANTS.JOB_STATUS ]}
        clearSelection={clearSelection}
        handleSelectAllProducts={handleSelectAllProducts}
        sortSettings={sortSettings}
        wholeData={wholeData}
        data={newProductsData !== "-" ? newProductsData : []} />

      <ViewJobDialog open={isModalOpen && !_.isEmpty(jobDataForDialog)} data={jobDataForDialog} rowIndex={selectedRowIdx} onClose={handleModalClose} />
    </>
  );
};

export default Jobs;
