import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import classnames from 'classnames';
import _ from 'lodash';
import SelectBox from 'components/SelectBox';
import DashboardsApi from '../../../services/dashboards/api';
import {usePrevious} from '../../../hooks';
import { useDispatch } from 'react-redux';
import { updateUserDashboard } from '../../../services/users/actions';
import { hideLoader } from 'services/loader/actions';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: 'auto',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
    },
    titleContainer: {
        padding: theme.spacing(0, 0, 2)
    },
    titleText: {
        letterSpacing: '1px',
        fontSize: 18,
        fontWeight: '500'
    },
    desc: {
        color: theme.palette.almostBlack[600]
    },
    itemRow: {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
        '& .MuiTypography-caption':{
            color: theme.palette.almostBlack[600]
        }
    },
    selectbox:{
        '& div':{
           margin:0
        }
    }
}));

const buildDashOptions = (dashboardList) => {
    return _.map(dashboardList, (dashboard)=>({ 
        id:_.get(dashboard, 'dashboard_id'),
        name:_.get(dashboard, 'dashboard_name')
    })) || [];
}

const HomePageConfig = ({homeDashboardId}) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [dashboardState, setDashboardState] = useState({isFetching:true, dashboardList:[]});
    const [dashboardId, setDashboardId] = useState(homeDashboardId);
    const {isFetching, dashboardList} = dashboardState;
    const prevDashbardId = usePrevious(dashboardId);

    const loadDashboardList = useCallback(()=>{ 
        setDashboardState({isFetching:true, dashboardList:[]});
        DashboardsApi.fetchDashboards().then(resp => {
          if(isMounted.current)
            setDashboardState({
                isFetching:false, 
                dashboardList:buildDashOptions(_.get(resp, 'data.data', []))
            });
        });
    }, []);

    useEffect(()=>{
       loadDashboardList();
       return () => {
          isMounted.current = false;
       }
    }, []);

    const onChangeSelectBox = (value) => {
        setDashboardId(value);
    }

    //Api success or failure dialog close btn callback
    const dialogCloseBtnCallback = () => {
        dispatch(hideLoader());
    }

    const onClickSaveBtn = () => {
        dispatch(updateUserDashboard(dashboardId, dialogCloseBtnCallback));
    }

    const isFormModified = !_.isEqual(prevDashbardId, dashboardId);
    
    return (
        <Grid container item xs={12}>
            <Grid container item xs={12} lg={8} className={classes.root}>
                <Grid container alignItems="center" justify='space-between'>
                    <Grid item xs={6}>
                        <Typography variant='subtitle1' className={classes.titleText}>Configure Your Hompage</Typography>
                    </Grid>
                    <Grid item xs={6} container justify='flex-end'>
                        <Button 
                            disableElevation 
                            disabled={!isFormModified} 
                            variant='contained' 
                            size='small' 
                            color='primary' 
                            type='submit'
                            onClick={onClickSaveBtn}
                        >
                           Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} lg={8} className={classnames(classes.root)}>
                <Grid container alignItems='flex-start' className={classes.itemRow}>
                    <Grid item xs={6}>
                        <Grid container alignItems='flex-start'>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2'>Choose Dashboard</Typography>
                            </Grid>
                            <Grid item xs={10} container>
                                <Typography variant='caption'>
                                    You can customize your homepage with a dashboard.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectBox 
                            isLoading={isFetching} 
                            label={isFetching ? "Loading..." : ""} 
                            // disabled={isViewMode} 
                            inputClass={classes.selectbox} 
                            name={'dashboard'}
                            value={dashboardId}
                            onChange={onChangeSelectBox}                            
                            options={dashboardList}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default HomePageConfig;