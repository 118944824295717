import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash';

const Validator = (props) => {
    
    const getFileExtension = (file) => {
      const logoImg = file.name;
      return logoImg.substring(logoImg.lastIndexOf("."), logoImg.length);
    }

    const validateLogoFile = (file, acceptsExts, acceptRes, isTestRatio, maxSize) => {
        return new Promise((resolve, reject)=>{
            const checkResolution = (file, acceptRes)=>{
                return new Promise((resolve, reject)=>{
                    let img = new Image()
                    img.src = URL.createObjectURL(file)
                    img.onload = () => {
                        if(isTestRatio){
                            let neededRatio = acceptRes[0] / acceptRes[1];
                            let currRatio = img.width / img.height;
                            let ratioMatchPercentage = currRatio / neededRatio;
                            let isRatioTestPass = ratioMatchPercentage <= 1.05 && ratioMatchPercentage >= 0.95
                            resolve(isRatioTestPass && img.width >= acceptRes[0] && img.height >= acceptRes[1]);
                        }else{
                            resolve(img.width === acceptRes[0] && img.height === acceptRes[1]);                
                        }
                    }
                });
            }
                        
            //If file is already uploaded
            if(file && typeof file === 'string')
                resolve();
            else if(!file){
                resolve('Choose an Image!');
            }
            else if(!_.isEmpty(acceptsExts) && acceptsExts.indexOf(getFileExtension(file).toLowerCase()) === -1){
                resolve(`Image file should be ${_.join(acceptsExts, ' (or) ')} format!`);
            }
            else if(!_.isEmpty(acceptRes)){
                checkResolution(file, acceptRes).then((isValidRes)=>{
                    if(!isValidRes){
                        resolve(`Image file should be ${_.join(acceptRes, 'X')} Resolution`);
                    }
                    else{
                        resolve();
                    }
                });
            }
        });
    }


    const validateForm  = (values) =>{
        props.checkForChange(values)
        return validateLogoFile(values.logoImg, [".png", ".jpg", "jpeg"], [320, 80], true).then((logoErrMsg)=>{
            return validateLogoFile(values.logoFavicon, [".png", ".jpg", "jpeg", ".ico"], [48, 48], false).then((favIconErrMsg)=>{
                let errors = {};
                if(!values.labelName){
                    errors.labelName='Enter Label Adjective';
                }
                if(!values.labelTitle){
                    errors.labelTitle='Enter Label Title';
                }
                if(logoErrMsg){
                    errors.logoImg=logoErrMsg;
                }
                if(favIconErrMsg){
                    errors.logoFavicon=favIconErrMsg;
                }
                throw errors;
            })
        });
    }

    const initialValues = props.initVals;

    const initialTouched = initialValues &&
        _.keys(initialValues).reduce((touchObj, key, idx) => { 
            touchObj = typeof touchObj === 'object' ? touchObj : {[touchObj]:true};
            return {...touchObj, [key]:true};
        });
        
    return (
        <Formik
            initialValues={initialValues || {labelTitle:'CounterfeitX', labelName:'counterfeit', logoImg:'', logoFavicon:''}}
            initialTouched={initialTouched}
            validate={validateForm}
            enableReinitialize
            onSubmit={props.onSubmit || null}>
            {props.children}
        </Formik>
    );
};

export default Validator;