import React, {useState, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, TextField, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _, { throttle } from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import { hideLoader } from 'services/loader/actions';
import Slide from '@material-ui/core/Slide';
import { fetchAllUsers } from 'services/users/actions';
import { getImgUrl } from 'utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addTeamMembers } from 'services/teams/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialogTitle:{
      borderBottom:`1px solid ${theme.palette.grey[300]}`,
      '& h6':{
        [theme.breakpoints.down('xs')]: {
          fontSize:16
        }
      }
    },
    dialogContent:{
      paddingTop:theme.spacing(2),
      [theme.breakpoints.up('md')]: {
         width:400
      }
    },
    dialogFooter:{
        padding:theme.spacing(2),
        borderTop:`1px solid ${theme.palette.grey[300]}`
    },
    textField:{
      paddingBottom:theme.spacing(1)
    },
    autocomplete:{
      marginTop:theme.spacing(1),
      marginBottom:theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
          marginTop:theme.spacing(2)
      }
   },
   userPic:{
        width:37,
        height:37,
        marginRight:theme.spacing(1)
    },
    info:{
      wordBreak:'break-word'
    }
}));

const AddMembersDialog = ({open, teamCode, handleClose}) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [usersListState, setUsersListState] = useState({loading:false, usersList:[], selectedUsers:[]});
    const accessToken = useSelector(state=>_.get(state, 'session.authToken', ''));
    const [isErrOnSubmit, setErrOnSumbit] = useState(false);

    const {usersList, loading, selectedUsers} = usersListState;

    const getPicUrl = useCallback((picLoc)=>{
        return _.isEmpty(picLoc) ? "" : getImgUrl(accessToken, picLoc);
    }, [accessToken]);
    
    const fetchUsersList = useMemo(() => {
        return throttle((searchTxt, callback) => {
          setUsersListState((prevState)=>({...prevState, loading:true, usersList:[] }));
          dispatch(fetchAllUsers({is_tu_units_assigned:'false', search_text:searchTxt, limit:4, offset:0}, callback, ()=>{}));
        }, 500);
    }, [dispatch]);

    const handleOwnerInpChng = useCallback((e) => {
        fetchUsersList(e.target.value, (resp)=>{
          setUsersListState((prevState)=>({...prevState, loading:false, usersList:_.get(resp, 'data.data.data', []) }));
        });
    }, [fetchUsersList]);

    const onChangeUserAutoComplete = (e, value) =>{   
        setUsersListState((prevState)=>({
          ...prevState, 
          selectedUsers:_.uniqBy(value, (user)=>_.get(user, 'user_id'))
        }));
    }

    const onSubmitForm = (selectedUsers) =>{
        const successCallback = ()=>{ 
          handleClose(null, true); 
        };
        const closeErrDialog = () => { dispatch(hideLoader()); };
        const selectedUserIds = _.map(selectedUsers, (user)=>_.get(user, 'user_id'));
        dispatch(addTeamMembers(teamCode, selectedUserIds, successCallback, closeErrDialog));
    }

    const onClickActionBtn = () => {
      const isUsersEmpty = _.isEmpty(selectedUsers);
      if(!isUsersEmpty)
        onSubmitForm(selectedUsers);

      setErrOnSumbit(isUsersEmpty);
    }

    //reset exsting details on form dialog close event
    useEffect(()=>{
       if(!open)
         setUsersListState({loading:false, usersList:[], selectedUsers:[]});
    }, [open]);

    // const selectedUsersValue = _.map(selectedUsers, (user)=>_.get(user, 'user_id'));

    const showErrMsg = isErrOnSubmit && _.isEmpty(selectedUsers);

    return (
     <Dialog 
        classes={{
          paper: classes.dialog
        }}
        TransitionComponent={Transition}
        maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="role-form-title">

            <DialogTitle className={classes.dialogTitle} disableTypography={true} id="role-dialog-title">
            <Typography variant="h6">
                Add Team Members
            </Typography>                            
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Autocomplete
                  id="userslist"
                  multiple
                  noOptionsText="No users found"
                  loading={loading}
                  loadingText="Finding users..."
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.full_name)}
                  filterOptions={(x) => x}
                  options={usersList}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  size={"small"}
                  renderOption={(option) => {
                    const picUrl = getPicUrl(option.profile_pic_url);
                    return (
                      <Grid key={option.user_id} container alignItems="center">
                          <Grid item>
                              <Avatar src={picUrl} size="small" className={classes.userPic} />
                          </Grid>
                          <Grid item xs>
                              <Typography variant="body2">
                                {option.full_name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                  {option.email}
                              </Typography>
                          </Grid>
                      </Grid>
                    )
                  }}
                  onChange={onChangeUserAutoComplete}
                  value={selectedUsers}
                  className={classes.autocomplete}
                  onInputChange={handleOwnerInpChng}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          dense="true"
                          error={showErrMsg}
                          helperText={showErrMsg && 'No users selected'}                                                
                          variant="outlined"
                          label={"Select Users to add"}
                      />
                  )}
              />
            <Typography className={classes.info} variant="caption" component="div" color="textSecondary">
                Note: Whoever is not assigned to any team unit yet, will be shown in the list otherwise won't.
            </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
            <Button variant="outlined" 
                onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button variant="contained" 
                onClick={onClickActionBtn} 
                color="primary" disableElevation>
                Add
            </Button>
            </DialogActions>        
      </Dialog>
    );
}

AddMembersDialog.propTypes = {
    open:PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    teamCode:PropTypes.string.isRequired
}

export default AddMembersDialog;