import {ACTION_TYPES} from '../../../constants';
import _ from 'lodash';

const initialState = {
    company_name:'',
    company_adjective:'',
    job_status:[],
    activity_type:[],
    logo:'',
    favicon:''
}

const labelManagement = (state={data: initialState, isLoading: false, isError: false}, action) => {
    switch(action.type){
        
        case ACTION_TYPES.FETCH_LABEL_DATA_PENDING:{
            return {...state, isError: false, isLoading: true}
        }
        case ACTION_TYPES.FETCH_LABEL_DATA_FULFILLED:{
            const data = {
                company_name: _.get(action, 'payload.data.data.company_name',''),
                company_adjective: _.get(action, 'payload.data.data.company_adjective',''),
                job_status: _.get(action, 'payload.data.data.job_status', []),
                activity_type: _.get(action, 'payload.data.data.activity_type',[]),
                logo: _.get(action, 'payload.data.data.logo',''),
                favicon: _.get(action, 'payload.data.data.favicon',''),
                ds_id: _.get(action, 'payload.data.data.ds_id','')
            }
            return {data, isLoading: false, isError: false}
        }
        case ACTION_TYPES.FETCH_LABEL_DATA_REJECTED:{
            return {...state, isError: true, isLoading: false}
        }
        case ACTION_TYPES.UPDATE_LABEL_DATA:{
            const tempData = {...state.data};
            tempData[action.dataKey] = action.value;
            return Object.assign({}, {data:tempData, isError: false, isLoading: false})
        }
        
        default:
            return state;
    }
}

export default labelManagement;