import React from 'react';
import { Icon, makeStyles, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  selectBox:{
    '&:before':{
      borderBottom:'none'
    },
    '& .MuiSelect-filled':{
      paddingBottom:12,
      paddingTop:10,
      background:theme.palette.grey[100]
    }
  },
  selectListIco:{
    paddingRight:theme.spacing(1)
  }
}));

function PermSelectBox({permissions, value, onChange, variant, showIconOnly = false}){

    const classes = useStyles();

    const renderValue = (value)=>{
       const spanStyle = {verticalAlign:'middle'};
       const permObj = _.find(permissions, {name:value});
       return (
         <div>
           <Icon style={spanStyle} fontSize="small" className={classnames({[classes.selectListIco]:!showIconOnly})}>
             {_.get(permObj, 'icon')}
           </Icon>
           {!showIconOnly && <span style={spanStyle}>
             {_.get(permObj, 'label')}
           </span>}
         </div>
       )
    }

    const applyVaraint = variant ? { variant } :{};
  
    return (
      <Select
        // labelId="demo-simple-select-label"
        // id="demo-simple-select"
        value={value || ''}
        renderValue={renderValue}
        className={classes.selectBox}
        onChange={onChange}
        {...applyVaraint}
      >
        {_.map(permissions, (permission, idx)=>(
          <MenuItem key={idx} value={_.get(permission, 'name')}>
            <Icon fontSize="small" className={classes.selectListIco}>{_.get(permission, 'icon')}</Icon>{_.get(permission, 'label')}
          </MenuItem>
        ))}
      </Select>
    )
}

PermSelectBox.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string
    })),
    onChange:PropTypes.func,
    showIconOnly:PropTypes.bool,
    variant: PropTypes.string,
    value: PropTypes.string
}

export default PermSelectBox;
  