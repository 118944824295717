import { PERMISSION_TYPE, ROLE_MODULES } from "constants/modules";
import update from 'immutability-helper';

export const initialState = {
    fetchingSharedList:false, 
    tabIdx: 0, selectedUsers:[], 
    selectedTeams:[], permission:PERMISSION_TYPE.EDIT, message:'', 
    sharedUsersList:[], sharedTeamsList:[]
};

export const formModules = [ ROLE_MODULES.USER_MANAGEMENT, ROLE_MODULES.TEAM_MANAGEMENT ];

export const permissions = [
    {
        name: PERMISSION_TYPE.EDIT,
        label: 'Edit',
        icon:'edit'
    },
    {
        name: PERMISSION_TYPE.VIEW,
        label: 'View',
        icon:'visibility'
    }
];

export function reducer(state, action) {
  switch (action.type) {
    case 'changeTab':
      return {...state, tabIdx: action.payload.tabIdx, selectedUsers:[], selectedTeams:[], permission:PERMISSION_TYPE.EDIT, message:''};
    case 'changeOnAutoComplete':
      let changeKey = action.payload.formModule === ROLE_MODULES.USER_MANAGEMENT ? "selectedUsers" : "selectedTeams"; 
      let resetKey = changeKey === 'selectedUsers'? 'selectedTeams' : 'selectedUsers';
      let actionToPerform = { [changeKey]:{ $set:action.payload.data }, [resetKey]:{ $set: [] } };
      return update(state, actionToPerform);
    case 'resetForm':
      const retainVals = action.payload || {};
      return {...initialState, ...retainVals};
    case 'changePermSelBox':
      return update(state, { permission: { $set : action.payload.value } });
    case 'changeMsgInput':
      return update(state, { message: { $set : action.payload.value } });
    default:
      throw new Error();
  }
}
