import { API_ENDPOINTS, COMPONENT_NAME_FOR_FILTERS, ACTION_TYPES } from '../../constants';
import {getLocalStorageItem} from '../localstorage';
import { showLoader, hideLoader, showErrorMessage, showConfirmMessage, showSuccessMessage } from '../loader/actions';
import Axios from 'axios';
import _ from 'lodash';
import { updateDashboard, fetchDashboardGraphsData } from './actions';
const CancelToken = Axios.CancelToken;

var dashboardUpdateTimeout = null;
// var dashboardFetchTimeout = null;

const _filterDefGroupsMaker = (groups) => {

    const _rulesMaker = (group) => {
        // const rules = group.reduce((acc, val) => {
        //                         if (!_.isNil(val) && val !== "") {acc.push({condition: group.condition,field_name: group.field_name,id: group.id, value: group.value});}
        //                         return acc;
        //                 }, []);
        const rules = !_.isEmpty(_.get(group, 'value', [])) ? {condition: group.condition,field_name: _.isEmpty(_.get(group, 'field_name'))?_.get(group, 'elasticConfig', ''):_.get(group,'field_name', _.get(group, 'elasticConfig', '')),id: group.id, value: group.value}:{}             
        // const rules = group.value.reduce((acc, val) => {
        //                     if (!_.isNil(val) && val !== "") {acc.push({condition: group.condition,field_name: group.field_name,id: group.id,value: [val]});}
        //                     return acc;
        //               }, []);
    
        if(!_.isEmpty(rules)){return{rules: [rules],condition: _.includes(group.condition, 'not')?'AND':'OR'}}
    }

    return groups.map(group => _rulesMaker(group)).reduce((acc, group)=>{if(!_.isNil(group)){acc.push(group)} return acc;}, [])
}

export const getFilterDefinitionFromUIFilters = (dsFilterList, dsIds, toFilterSearchComponent) => {
    const _getAllFilterRules = (arrayOfDS) => arrayOfDS.reduce((acc, dsObj) => acc.concat(_.get(dsObj, 'filters', [])), [])
    return dsIds.map((dsId) => ({
            ds_id: dsId, 
            condition: "AND",
            groups: _filterDefGroupsMaker(_getAllFilterRules(dsFilterList))
        }
    ));
}

export const buildUIFiltersFromApiResp = (apiRespFilters) => {
    return apiRespFilters.map((dataSourceFilters) => ({
        dataSourceId:_.get(dataSourceFilters, 'ds_id'),
        filters:_.get(dataSourceFilters, 'groups[0].rules')
    }));
}

export const buildIPFiltersFromApiResp = (apiIPFiltersUnConditioned) => {
    const apiIPFilters = _.isEmpty(apiIPFiltersUnConditioned) ? [] : apiIPFiltersUnConditioned;
    return apiIPFilters.map((dataSourceFilters) => ({
        dataSourceId: _.get(dataSourceFilters, 'ds_id'),
        inPageFilters: _.get(dataSourceFilters, 'rules')
    }))
}

const getInfoFromResp = (response, defaultMsg)=>{
    return _.get(response, 'data.more_info', _.get(response, 'data.message', defaultMsg));
}

const showErrMsg = (dispatch, err) => {
    const errMsg = getInfoFromResp(err.response, 'Something Went Wrong!');
    dispatch(showErrorMessage(`ERROR : ${errMsg}, Please try again.`, "Close", (e) =>{
        dispatch(hideLoader());
    }));
}

const callAddDelGraphsInDashboard = (apiUrl, processingMsg, dispatch, data, successCallback) => {
    dispatch(showLoader(processingMsg));
    Axios.put(apiUrl,
        data,
        // {
        //     dashboard_id:dashboardId,
        //     graphs:graphIds,
        //     dashboard_layout:dashboardLayout,
        //     filter_definitions:filters,
        //     created_by:getLocalStorageItem("userId")
        // },
        {headers:{'isAuthRequired': true,  'content-type':'application/json'}}
    ).then(() =>{
        dispatch(hideLoader());
        successCallback && successCallback();
    }).catch((err)=>{
       showErrMsg(dispatch, err);
    });
}

const delGraphsAndUpdateLayouts = (dispatch, dashboardId, dashboardData, successCallback) => {
    dispatch(showLoader("Removing Graph..."))
    Axios.put(
     API_ENDPOINTS.DASHBOARD_PATH_API,
     dashboardData, { 
        headers:{ 
           'isAuthRequired': true, 'content-type':'application/json', 'path':{ dashboardId } 
        } 
    }).then(()=> {
        dispatch(hideLoader());
        successCallback && successCallback();
    }).catch((err)=>{
        showErrMsg(dispatch, err);
    });
}

const showConfirmMsgOnBeforeDel = (dispatch, confirmMsg, desc, successCallback, cancelCallback) =>{
    dispatch(showConfirmMessage(confirmMsg, desc, "Delete", (e)=>{
        dispatch(hideLoader());
        successCallback && successCallback();
    }, "Cancel", (e)=>{
        cancelCallback && cancelCallback();
    }));
}


const confirmDashboardDel = (dispatch, dashboardName, successCallback) => {
    showConfirmMsgOnBeforeDel(dispatch, 
         `Do you want to delete the "${dashboardName}"?`,null,
         successCallback, 
         (e)=>{ dispatch(hideLoader()); }
    );
}

const deleteDashboard = (dispatch, dashboardId, successCallback) => {
    dispatch(showLoader("Deleting Dashboard..."));
    Axios.delete(API_ENDPOINTS.DASHBOARD_PATH_API, 
    { headers:{ 'isAuthRequired': true, 'path':{ dashboardId } } })
    .then(() =>{
        dispatch(showSuccessMessage('Dashboard Deleted Successfully', "Close", (e)=>{
            dispatch(hideLoader());
            successCallback && successCallback();
        }));
    }).catch((err)=>{
        showErrMsg(dispatch, err);
    });
}

const updateFiltersDashboard = (dispatch, dashboardId, dashboardData, successCallback) => {
    dispatch(showLoader("Applying filters..."));
    Axios.put(
        API_ENDPOINTS.DASHBOARD_PATH_API,
        dashboardData,
        {
          headers:{ 'isAuthRequired': true, 'content-type':'application/json', 'path':{ dashboardId } 
        } 
    }).then(()=>{
        dispatch(hideLoader());
        successCallback && successCallback();
    }).catch((err)=>{
        showErrMsg(dispatch, err);
    });
}


export default {
    fetchDashboards: () => {
        return Axios.get(API_ENDPOINTS.DASHBOARD_API, {headers: {'isAuthRequired': true} })
    },
    createDashboard: (dashboardName, successCallback, errCallback) => {
        Axios.post(API_ENDPOINTS.DASHBOARD_API, 
            { dashboard_name:dashboardName, created_by:getLocalStorageItem("userId") },
            { headers: {'isAuthRequired': true,  'content-type':'application/json'} })
           .then((resp)=>{
              successCallback && successCallback(_.get(resp, 'data', {}));
           }).catch((err)=>{
              errCallback && errCallback(getInfoFromResp(err.response, 'Something Went Wrong!'));
           });
    },
    // fetchDashboard: (apiDomain='', dashboardId, authKey='') =>{
    //     return Axios.get(`${apiDomain}${API_ENDPOINTS.DASHBOARD_PATH_API}`, { headers:{ 'isAuthRequired': true, 'authKey':authKey, 'path':{ dashboardId } } });
    // },
    fetchDashboard: (apiDomain='', dashboardId, authKey='') => {

        const _getFilterDefinitionFromUIFilters = (dsFilterList) => {
            const _getAllFilterRules = (arrayOfDS) => arrayOfDS.map(dsObj => _.get(dsObj, 'rules'))
            return _.filter(dsFilterList, (dsFilter) => !_.isEmpty(_.get(dsFilter, 'rules', [])))
                .map((dataSourceFilters, currIdx, allDSWithFilters) => ({
                    ds_id: dataSourceFilters.ds_id,
                    condition: "AND",
                    groups: _filterDefGroupsMaker(_getAllFilterRules(allDSWithFilters))
                    // groups: _filterDefGroupsMaker(dataSourceFilters.rules)
                }
            ));
        }

        return dispatch => {
            dispatch({type: ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_PENDING})
            Axios.get(`${apiDomain}${API_ENDPOINTS.DASHBOARD_PATH_API}`, { headers:{ 'isAuthRequired': true, 'authKey':authKey, 'path':{ dashboardId } } }).then(resp=>{
                // const ipf = _.cloneDeep(_.get(resp, 'data.data.in_page_filters', []));
                // const popUpFilters = _.cloneDeep(_.get(resp, 'data.data.popup_filters', []));
                // const dashboardId = _.get(resp, 'data.data.dashboard_id');
                // const dsIdList = _.uniq(_.get(resp, 'data.data.graph_details', []).map(graphDetail=>_.get(graphDetail, 'ds_id', '')));
                if(!_.isEmpty(_.get(resp, 'data.data.popup_filters', []))){
                    const dataToAttach = {
                        dashboard_name:_.get(resp, 'data.data.dashboard_name', 'Untitled Dashboard'),
                        dashboard_layout:_.get(resp, 'data.data.dashboard_layout'),
                        graphs:_.get(resp, 'data.data.graphs'),
                        in_page_filters: _.get(resp, 'data.data.in_page_filters', []),
                        popup_filters: [],
                        filter_definitions: _getFilterDefinitionFromUIFilters(_.get(resp, 'data.data.in_page_filters', []))
                    }
                    Axios.put(`${apiDomain}${API_ENDPOINTS.DASHBOARD_PATH_API}`, dataToAttach, { headers:{ 'isAuthRequired': true, 'content-type':'application/json',  'path': {dashboardId}  } }).then(response=>{
                            const tempObj = {data:{data:dataToAttach}};
                            _.merge(tempObj, resp);
                            dispatch({type: ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_FULFILLED, payload: tempObj})
                        }).catch(err => dispatch({type: ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_REJECTED}))
                }
                else{
                    dispatch({type: ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_FULFILLED, payload: resp})
                }
            }).catch(err=>dispatch({type: ACTION_TYPES.FETCHING_DASHBOARD_DETAILS_REJECTED}))
        }
    },
    addGraphsToDashboard: (dashboardId, graphIds, successCallback) => {
        return (dispatch) => {
            callAddDelGraphsInDashboard(
                API_ENDPOINTS.DASHBOARD_ADD_GRAPHS_API,
                "Adding Graphs...", dispatch, {dashboard_id: dashboardId, graphs:graphIds } , successCallback
            );
        }
    },
    rmvGraphsAndUpdtLayoutsInDashboard : (dashboardId, dashboardData, successCallback) => {
        return (dispatch) => {
            delGraphsAndUpdateLayouts(dispatch, dashboardId, dashboardData, successCallback);
        }
    },
    removeGraphsInDashboard: (dashboardId, graphIds, dashboardLayout, dashboardFilters, inPageFilters, successCallback) => {
        return (dispatch) => {
            callAddDelGraphsInDashboard(
                API_ENDPOINTS.DASHBOARD_REMOVE_GRAPHS_API,
                "Removing Graph...", 
                dispatch,  
                {
                    dashboard_id:dashboardId,
                    graphs:graphIds,
                    dashboard_layout:dashboardLayout,
                    filter_definitions:dashboardFilters,
                    inPageFilters : inPageFilters,
                    created_by:getLocalStorageItem("userId")
                },
                successCallback
            );
        }
    },
    fetchDashboardData: (dispatch, apiDomain='', dashboardId, authKey='', cancelExecutor) => {
        if(cancelExecutor.current !== undefined){
            cancelExecutor.current();
        }
        // console.log("dispatched from dashboard api");
        dispatch(fetchDashboardGraphsData(apiDomain, dashboardId, authKey, cancelExecutor));
        // if(dashboardFetchTimeout!==null){
        //     clearTimeout(dashboardFetchTimeout);
        //     dashboardFetchTimeout=null;
        // }
        // dashboardFetchTimeout=setTimeout(()=>{
        //     if(cancelExecutor.current !== undefined){
        //         cancelExecutor.current();
        //     }
        //     // console.log("dispatched from dashboard api");
        //     dispatch(fetchDashboardGraphsData(apiDomain, dashboardId, authKey, cancelExecutor));
        // },0)

        
        // return Axios.get(apiDomain+API_ENDPOINTS.DASHBOARD_FETCH_DATA_API, { headers:{ 'isAuthRequired': true, 'authKey':authKey, 'path':{ dashboardId } } });
    },
    updateDashboard: (dispatch, dashboardId, dashboardData, cancelExecutor) => {
        if(dashboardUpdateTimeout!=null)
        {
            clearTimeout(dashboardUpdateTimeout);
            dashboardUpdateTimeout=null;
        }
        dashboardUpdateTimeout =  setTimeout(()=>{
           if(cancelExecutor.current !== undefined)
              cancelExecutor.current(); //Cancel the existing request for fetching data
           dispatch(updateDashboard(dashboardId, dashboardData, cancelExecutor)); //call the update api
        }, 500);
    },
    deleteDashboard: (dashboardId, dashboardName, successCallback) => {
        return (dispatch) => {
            confirmDashboardDel(dispatch, dashboardName, (e)=>{
                deleteDashboard(dispatch, dashboardId, successCallback);
            });
        }
    },
    updateDashboardFilters : (dashboardId, dashboardData, successCallback) => {
        return (dispatch) => {
            updateFiltersDashboard(dispatch, dashboardId, dashboardData, successCallback);
        }
    },
    fetchGraphDataByPaginationForDashboard : (apiDomain='', dashboardId='', authKey='', graphId='', page=1, limit=50, successCallback, failCallback, cancelExecutor) => {

        if(cancelExecutor.current !== undefined){
            if(cancelExecutor.current[graphId]){
                cancelExecutor.current[graphId]();
            }
        }
        Axios.get(
            apiDomain+API_ENDPOINTS.DASHBOARD_FETCH_GRAPH_DATA_FOR_PAGINATION_API,
            { 
                cancelToken: new CancelToken(function executor(c) {  
                    if(cancelExecutor.current !== undefined){
                        cancelExecutor.current = {[graphId]:c} 
                    }
                    else{
                        cancelExecutor.current = {...cancelExecutor.current, [graphId]:c}
                    }
                }),
                headers:{ 'isAuthRequired': true, 'authKey':authKey, 'path':{ dashboardId, graphId, page, limit } } 
            }).then(resp => {
                let tempData = _.get(_.find(_.get(resp, 'data.data.graph_results', []), o=>o.graph_id===graphId), 'graph_data.chart_data', []);
                successCallback && successCallback(tempData);
            }).catch(err => {
                if(!Axios.isCancel(err) || !JSON.stringify(err) === '{}'){
                    failCallback && failCallback(err);
                }
            })
    }
}