import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  Button,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import { DateRangePicker } from "react-date-range";
import {fromTodayStaticRanges, fromTodayInputRange} from "../../../utils/defaultDateRanges";
import ProductTable from '../../../components/ProductTable';
import { Formik } from "formik";
import * as Yup from "yup";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { format } from 'date-fns'
import { useDispatch, useSelector } from "react-redux";
import { getAssignedtoList } from "../../../services/assignedTo/actions";
import { createJobs, resetCreateJobs } from "../../../services/createJobs/actions";
import { showLoader , hideLoader} from "../../../services/loader/actions";
import { showSnackbarWithTimeout } from "../../../services/snackbar/actions";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  muiButtonRoot: {
    height: "32px",
    // borderRadius: "10px",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 1.5),
    "& h6": {
      fontWeight: 600,
      // textTransform: "capitalize",
      lineHeight: theme.typography.pxToRem(14),
      fontSize: theme.typography.pxToRem(14),
      // marginLeft: theme.spacing(0.5),
    },
  },
  textRight: {
    textAlign: "right",
  },
  subTitle: {
    color: "#757575",
  },
  inputStyle: {
    width: "100%",
  },

  "& .MuiDialog-paperFullWidth": {
    overflow: "inherit",
  },
  ".MuiDialogContent-root": {
    overflow: "inherit",
  },
  mt5: {
    marginTop: theme.spacing(3),
  },

  calendarWrapper: {
    position: 'absolute',
    top: '100%',
    right: '0%',
    zIndex: 5,
    padding: theme.spacing(2),
    background: theme.palette.almostBlack[100],
    boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
    borderRadius: '10px',
    '& .rdrDefinedRangesWrapper': {
      borderRadius: '10px 0px 0px 10px',
      '& .rdrStaticRanges': {
        borderRadius: '10px 0px 0px 0px',
        '& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
          borderRadius: '10px 0px 0px 0px',
          '& .rdrStaticRangeLabel': {
            borderRadius: '10px 0px 0px 0px',
          }
        }
      }
    },
    '& .rdrCalendarWrapper': {
      borderRadius: '0px 10px 10px 0px',
      '& .rdrDateDisplayWrapper': {
        borderRadius: '0px 10px 0px 0px',
      }
    }
  },
  dateButtonText: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: theme.typography.pxToRem(1),
  },
  datesButton: {
    width: '100%',
    padding: '16px 14px'
  },
  mb5: {
    marginBottom: theme.spacing(3)
  }
}));
const CreateJobs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { closeWindowFxn,onCancelClick, goToPage, paginationFooterDetails, totalData, limit, setPageLimit, page, headers, wholeData, data=[] } = props
  // const [priority, setPriority] = React.useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // console.log("props from createJobs", props)

  // const handleSelectChange = (event) => {
  //   setPriority(event.target.value);
  // };



  // function formatDateForLabel(dateString, isReturnAsDate) {
  //   //   console.log("dateString", dateString)
  //   let dt = new Date(dateString);
  //   let today = new Date();
  //   let todayStr =
  //     today.getDate() +
  //     "/" +
  //     (today.getMonth() + 1) +
  //     "/" +
  //     today.getFullYear();
  //   let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  //   let yesterdayStr =
  //     yesterday.getDate() +
  //     "/" +
  //     (yesterday.getMonth() + 1) +
  //     "/" +
  //     yesterday.getFullYear();
  //   let dtStr =
  //     dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
  //   if (_.isEqual(todayStr, dtStr) && !isReturnAsDate) {
  //     return "Today";
  //   } else if (_.isEqual(yesterdayStr, dtStr) && !isReturnAsDate) {
  //     return "Yesterday";
  //   } else {
  //     return dtStr;
  //   }
  // }


  // function dateLabel(dateObj) {
  //   if (
  //     formatDateForLabel(dateObj.startDate) ===
  //     formatDateForLabel(dateObj.endDate)
  //   ) {
  //     // console.log(formatDateForLabel(dateObj.endDate))
  //     return (
  //       <Typography variant="subtitle2" className={classes.dateButtonText}>
  //         {formatDateForLabel(dateObj.endDate)}
  //       </Typography>
  //     );
  //   } else {
  //     console.log(formatDateForLabel(dateObj.startDate, true))
  //     return (
  //       <>
  //         <Typography variant="subtitle2" className={classes.dateButtonText}>
  //           {formatDateForLabel(dateObj.startDate, true)}
  //         </Typography>
  //         -
  //         <Typography variant="subtitle2" className={classes.dateButtonText}>
  //           {formatDateForLabel(dateObj.endDate, true)}
  //         </Typography>
  //       </>
  //     );
  //   }
  // }

  const handleDateChange = (dateObj) => {
    setDates([{startDate: _.get(dateObj, 'dates.startDate', new Date()), endDate: _.get(dateObj, 'dates.endDate', new Date()), key: 'dates'}])
  };

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "dates",
    },
  ]);

const status = useSelector(state => state.createJobs);
  const onSubmit = (e) => {
    dispatch(showLoader('Loading Please wait'));
    let submitData = {
      job_start_date: `${format(dates[0].startDate, 'yyyy-MM-dd hh:mm:ss')}`,
      job_title: e.jobName,
      job_description: e.jobDesc,
      case_reference: e.caseReference,
      brief_case_notes: e.briefCaseNotes,
      recording_officer: e.recordingOfficer,
      product_list: data.map(p=>p.product_id),
      job_due_date: `${format(dates[0].endDate, 'yyyy-MM-dd hh:mm:ss')}`,
      job_priority: e.priority,
      assigned_watcher_id: assignId
    }
    // console.log(submitData);
    dispatch(createJobs(submitData, bpId));
  }

 
  useEffect ( ()=>{
   if(status.data !== undefined){
    if(status.data.message === "Success"){
      dispatch(hideLoader())
      dispatch(showSnackbarWithTimeout( 'Successfully Assigned!', 3000));
      dispatch(resetCreateJobs())
      closeWindowFxn()
    }
    else if(status.isFetchingCreateJobsFailed){
      dispatch(hideLoader())
      dispatch(showSnackbarWithTimeout( 'Something went wrong. Please try again', 3000));
      dispatch(resetCreateJobs())
    }
   }
  },[status, dispatch, closeWindowFxn])



const bp = useSelector(state => state.getBpId.data);
const [bpId, setBpId]= useState("")
useEffect(()=>{
  
  if(bpId!==undefined){
    setBpId(bp[0].bp_id)
  }
},[bp, bpId])
  
  const clientId = useSelector(state => state.clientId.data[0].client_id);
  useEffect(() => {
    dispatch(getAssignedtoList(clientId))
  }, [dispatch, clientId])

  const assignerList = useSelector(state => state.assignTo.data);
  const [assignOption, setAssignOptions] = useState([])
  const [assignId, setAssignId] = useState('')
  
  useEffect(() => {
    let tempList = []
    if (assignerList === undefined) {
      setAssignOptions([{ value: 'no data found', label: 'Data Loading...' }])
    } else if (assignerList.watcher_details !== undefined) {
      assignerList.watcher_details.forEach(row => {
        tempList.push({ value: row.user_id, label: row.display_name })
      })
      setAssignOptions(tempList);
    }
  }, [assignerList])

  const handleAssignerId = (e) => {
    setAssignId(e.target.value)
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          jobName: '',
          priority: 'high',
          assignTo: '',
          caseReference: '',
          recordingOfficer: '',
          briefCaseNotes: '',
          jobDesc: ''
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          jobName: Yup.string().required("Job name is required"),
          priority: Yup.string().required("Priority is required"),
          assignTo: Yup.string().required("Assign to is required"),
          caseReference: Yup.string().required("Case reference id is required"),
          recordingOfficer: Yup.string().required("Recording officer is required"),
          briefCaseNotes: Yup.string().required("Brief case notes is required"),
          jobDesc: Yup.string().required("Job description is required"),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container className={classes.mb5}>
                <Grid item lg={8}>
                  <Typography variant="h5">Create and Assign Jobs</Typography>
                  <Typography variant="body2" className={classes.subTitle}>
                    Jan 1,2020 05:11 PM {/* <span>123456</span> */}
                  </Typography>
                </Grid>
                <Grid item lg={4} className={classes.textRight}>
                  <Button color="primary" variant="contained" type="submit" disableElevation classes={{ root: classes.muiButtonRoot }} >
                    <Typography variant="h6" type="submit"> Submit </Typography>
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    disableElevation
                    classes={{ root: classes.muiButtonRoot }}
                    onClick={onCancelClick}
                  >
                    <Typography variant="h6" type="submit">
                      Cancel
                      </Typography>
                  </Button>

                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item lg={4}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      name="jobName"
                      id="outlined-required1"
                      label="Job Name"
                      variant="outlined"
                      error={errors.jobName && touched.jobName && true}
                      type="text"
                      value={values.jobName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.jobName && touched.jobName && errors.jobName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4}>
                  <FormControl variant="outlined" className={classes.inputStyle}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Priority
            </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="priority"
                      // onChange={handleSelectChange}
                      label=" Select Priority"
                      error={errors.priority && touched.priority && true}
                      value={values.priority}
                      onChange={(e) => {
                        handleChange(e)
                        // handleSelectChange(e)
                        setFieldValue('priority', e.target.value)
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                    </Select>
                    {/* <FormHelperText>{erors.priority && touched.priority && errors.priority}</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item lg={4}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      name="datePicker"
                      id="outlined-required2"
                      label="Job Start and End Date"
                      variant="outlined"
                      error={errors.datePicker && touched.datePicker && true}
                      type="text"
                      value={`${format(dates[0].startDate, 'dd/MM/yyyy')} - ${format(dates[0].endDate, 'dd/MM/yyyy')}`}
                      onChange={handleChange}
                      onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                      onBlur={handleBlur}
                      helperText={
                        errors.datePicker && touched.datePicker && errors.datePicker
                      }
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="calender"
                            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                            edge="end"
                            size="small"
                          >
                            <DateRangeIcon />
                          </IconButton>
                        )
                      }}
                    />
                    {isCalendarOpen ? (
                      <ClickAwayListener
                        onClickAway={() => setIsCalendarOpen(false)}
                      >
                        <DateRangePicker
                          className={classes.calendarWrapper}
                          onChange={(item) => {
                            handleDateChange(item);
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={dates}
                          staticRanges={fromTodayStaticRanges}
                          inputRanges={fromTodayInputRange}
                          minDate={new Date()}
                          direction="horizontal"
                        />
                      </ClickAwayListener>
                    ) : null}


                  </FormControl>
                </Grid>
                <Grid item lg={4}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      select
                      name="assignTo"
                      id="outlined-required3"
                      label="Assign To"
                      variant="outlined"
                      error={errors.assignTo && touched.assignTo && true}
                      value={values.assignTo}
                      onChange={(e) => {
                        handleChange(e);
                        handleAssignerId(e);
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.assignTo && touched.assignTo && errors.assignTo
                      }
                    >
                      {assignOption.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>

                      ))}
                    </TextField>

                  </FormControl>
                </Grid>
                <Grid item lg={4}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      name="caseReference"
                      id="outlined-required4"
                      label="Case Reference"
                      variant="outlined"
                      error={errors.caseReference && touched.caseReference && true}
                      value={values.caseReference}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.caseReference && touched.caseReference && errors.caseReference
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      name="recordingOfficer"
                      id="outlined-required5"
                      label="Recording Officer"
                      variant="outlined"
                      error={errors.recordingOfficer && touched.recordingOfficer && true}
                      value={values.recordingOfficer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.recordingOfficer && touched.recordingOfficer && errors.recordingOfficer
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      id="outlined-multiline-static1"
                      label="Brief Case Notes"
                      multiline
                      rows={4}
                      variant="outlined"
                      name="briefCaseNotes"
                      error={errors.briefCaseNotes && touched.briefCaseNotes && true}
                      value={values.briefCaseNotes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.briefCaseNotes && touched.briefCaseNotes && errors.briefCaseNotes
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6}>
                  <FormControl className={classes.inputStyle}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Job Description"
                      multiline
                      rows={4}
                      variant="outlined"
                      name="jobDesc"
                      error={errors.jobDesc && touched.jobDesc && true}
                      value={values.jobDesc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.jobDesc && touched.jobDesc && errors.jobDesc
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

      <Grid container className={classes.mt5}>
        <Grid item lg={12}>
          <Typography variant="h5">Products Added</Typography>
        </Grid>
        <ProductTable
          goToPage={goToPage}
          paginationFooterDetails={paginationFooterDetails}
          totalData={totalData}
          limit={limit}
          setPageLimit={setPageLimit}
          page={page}
          headers={headers}
          wholeData={wholeData}
          data={data}
          elevation={0}
          enableBorderBottom
          className={classes.mt5}
        // isLoading={props.isLoading} 
        />
      </Grid>

    </>
  );
};

export default CreateJobs;
