import {put, call, takeLatest} from 'redux-saga/effects';
import { ACTION_TYPES, SAGA_ACTIONS } from '../../constants';
import api from './api';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchProductsForJob(action){

    yield delay(300);
    yield put({type: ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_PENDING, key: 'products'});
    try{
        const products = yield call(api.fetchProductsForJob, action.query);
        // console.log("products fetched in saga",products)
        yield put({type: ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_FULFILLED, payload: products.data, key: 'products'})
    }
    catch(err){
        yield put({type: ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_REJECTED, payload: err, key: 'products'})
    }
}

function* fetchActivitiesForJob(action){

    yield delay(300);
    yield put({type: ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_PENDING, key: 'activities'});
    try{
        const products = yield call(api.fetchActivitiesForJob, action.query);
        // console.log("activities fetched in saga",products)
        yield put({type: ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_FULFILLED, payload: products.data.data, key: 'activities'})
    }
    catch(err){
        yield put({type: ACTION_TYPES.FETCH_PRODUCTS_FOR_JOB_REJECTED, payload: err, key: 'activities'})
    }
}

export function* watchFetchActivitiesForJob(){
    yield takeLatest(SAGA_ACTIONS.FETCH_ACTIVITIES_FOR_JOB, fetchActivitiesForJob)
}

export function* watchFetchProductsForJob(){
    yield takeLatest(SAGA_ACTIONS.FETCH_PRODUCTS_FOR_JOB, fetchProductsForJob)
}