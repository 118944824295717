import React, {useState} from 'react';
import ShareDialog from 'components/ShareDialog';

const withShareDialog = (Component) => (props)=> {

    const [openShareDialog, setOpenShareDialog] = useState(false);

    const toggleShareDialog = () => {
        setOpenShareDialog((prevState)=>!prevState);
    }

    return (
        <>
          <Component openSharingDialog={toggleShareDialog} {...props} />
          <ShareDialog open={openShareDialog} handleClose={toggleShareDialog} />      
        </>
    );
}

export default withShareDialog;
