import { ACTION_TYPES, SAGA_ACTIONS, FILTER_CONSTANTS } from '../../../constants';
import _ from 'lodash';

// old ds_id = 5ea932ef2e2241589d0b66d1

export const getSuggestions = (filterKey, offset = 0, keyword = "", dataSourceId) => {
    const productFilters = [FILTER_CONSTANTS.RETAILER, FILTER_CONSTANTS.PRODUCT_NAME, FILTER_CONSTANTS.BRAND, FILTER_CONSTANTS.STOCK, FILTER_CONSTANTS.RESELLER,FILTER_CONSTANTS.DISCOUNT];
    console.log('in action datasource id', dataSourceId);
    if(_.includes(productFilters , filterKey) ){
        return{
        isProduct : true,
        type: SAGA_ACTIONS.GET_SUGGESTIONS,
        filterKey: filterKey,
        query: { "column_name": filterKey, "value": keyword, "offset": offset, "limit": 10, ds_id: dataSourceId} 
        }
    }else {
        return {
            type: SAGA_ACTIONS.GET_SUGGESTIONS,
            filterKey: filterKey,
            query: { "column_name": filterKey, "column_value": keyword, "offset": offset, "limit": 10 }
        }
    }
   
}

export const getStyleCodeSuggestions = (offset, keyValue, productType = 'old') => ({
    type: SAGA_ACTIONS.GET_STYLE_CODE_SUGGESTIONS,
    payload: { search_key: productType === 'old' ? 'objectID' : 'stylecode', search_value: keyValue ? keyValue : '', offset: offset, limit: 10, product_type: productType }
})

export const getRetailerSuggestions = (offset, keyValue, limit) => ({
    type: SAGA_ACTIONS.GET_RETAILER_SUGGESTIONS,
    payload: { column_name: 'source', value: keyValue ? keyValue : '', offset: offset, limit: limit, ds_id: '5ea932ef2e2241589d0b66d1' }
})

export const getProductSuggessions = (offset, keyValue, limit) => ({
    type: SAGA_ACTIONS.GET_PRODUCT_SUGGESSIONS,
    payload: { column_name: 'product_name', value: keyValue ? keyValue : '', offset: offset, limit: limit, ds_id: '5ea932ef2e2241589d0b66d1' }
})

export const getResellerSuggessions = (offset, keyValue, limit) => ({
    type: SAGA_ACTIONS.GET_RESELLER_SUGGESSIONS,
    payload: { column_name: 'seller_name', value: keyValue ? keyValue : '', offset: offset, limit: limit, ds_id: '5ea932ef2e2241589d0b66d1' }
})

export const clearSuggestions = () => ({
    type: ACTION_TYPES.CLEAR_SUGGESTIONS
})