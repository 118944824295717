import {ACTION_TYPES} from '../../../constants';


const assignTo = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ASSIGN_TO_PENDING: {
            return {
                ...state,
                isFetchingAssignto:true,
                isFetchingAssigntoFailed:false,
                isFetchingAssigntoError:undefined,
                data:{}
            }
        }
        case ACTION_TYPES.GET_ASSIGN_TO_FULFILLED:{
            return {
                ...state,
                isFetchingAssignto:false,
                isFetchingAssigntoFailed:false,
                isFetchingAssigntoError:undefined,
                data:action.payload.data
            }
        }
        case ACTION_TYPES.GET_ASSIGN_TO_FULFILLED: {
            return {
                ...state,
                isFetchingAssignto:false,
                isFetchingAssigntoFailed:true,
                isFetchingAssigntoError:action.payload,
                data:{}
            }
        }
        default:
            return state;
    }
}

export default assignTo;