import { API_ENDPOINTS } from '../../constants';
import Axios from 'axios';

export default {
    fetchProductsForJob: (conditionsQuery) => {
        return Axios.post(API_ENDPOINTS.FETCH_PRODUCTS_FOR_JOB, conditionsQuery, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    fetchActivitiesForJob: (conditionsQuery) => {
        return Axios.post(API_ENDPOINTS.FETCH_ACTIVITIES_FOR_JOB, conditionsQuery, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    updateCounterfeitData: (query, bpId) => {
        return Axios.put(API_ENDPOINTS.UPDATE_COUNTERFEIT_DATA, query, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json', 'path': {bpId} } })
    },
    addActivity: (data, bpId, jobId) => {
        return Axios.post(API_ENDPOINTS.ADD_ACTIVITY, data, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/x-www-form-urlencoded', 'path': {bpId, jobId} } })
    }
}