import React from 'react';
import PropTypes from 'prop-types';
import { Icon, SvgIcon} from '@material-ui/core';

const GraphIcon = ({title, iconName, className, onClick, style ={}}) => { 
    switch(iconName) {
        case "sankey":
            return (
              <SvgIcon style={style} title={title} viewBox={"0 0 24 24"} fontSize="small" onClick={onClick} className={className}>
                <path fill="currentColor" d="M20 4V6H4V4H2V12H4V10C8.16 10 9.92 12.11 11.77 14.34S15.65 19 20 19V21H22V15H20V17C16.59 17 15.07 15.17 13.31 13.06C11.34 10.69 9.1 8 4 8H20V10H22V4Z" />
              </SvgIcon>
            )
        default:
          return (
            <Icon style={style} title={title} onClick={onClick} className={className}>{iconName}</Icon>
          )
    }
}

GraphIcon.propTypes = {
   title:PropTypes.string.isRequired,
   iconName:PropTypes.string.isRequired,
   onClick:PropTypes.func,
   className:PropTypes.string,
   style:PropTypes.object
}

export default GraphIcon;