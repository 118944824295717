import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import FilterPanel from './FilterPanel';
import { useSelector, useDispatch } from 'react-redux';
import { clearSuggestions} from '../../services/searchSuggestions/actions';
import _ from 'lodash';
import { clearAllFilters} from '../../services/filtersNew/actions';
import RangeFilter from './RangeFilter';
import { FILTER_CONSTANTS } from '../../constants';
import { setThisFilter, clearThisFilter } from '../../services/filtersNew/actions';
import { getSuggestions } from '../../services/filterSuggestions/actions';
import DateFilter from './DateRangeFilter';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        background: theme.palette.almostBlack[0],
        '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '& ::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px'
        }
    },
    heading:{
        // letterSpacing: theme.typography.pxToRem(1),
        // color: theme.palette.almostBlack[600],
        // padding: `${theme.spacing(1)}px 0px`,
        fontSize: theme.typography.pxToRem(18),
        letterSpacing: 1,
        color: theme.palette.almostBlack[700]
    },
    resetSpan: {
        padding: theme.spacing(0.5),
        color: theme.palette.almostBlack[800],
        borderRadius: '5px',
        transition: 'background 0.3s',
        cursor: 'pointer',
        // fontFamily: '"Poppins"',
        fontSize: theme.typography.pxToRem(10),
        '&:hover':{
            background: `${theme.palette.primary.main}11`,
            transition: 'background 0.3s'
        }
    },
}))

const Filters = ({extraFilterProps, showTheseFilters, searchSuggestionsProductType='old'}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const suggestions = useSelector(state => state.filterSuggestions)
    const filters = useSelector(state => state.filtersNew);
    const dataSourceId = useSelector(state => _.get(state, 'labelManagement.data.ds_id'));    

    const fetchSuggestions = (filterKey, keyValue="") => {
        const offset  = keyValue.length === 0 ? suggestions[filterKey].suggestions.length : 0
        dispatch(getSuggestions(filterKey, offset, keyValue, dataSourceId))
    }

    useEffect(()=>{
        dispatch(clearSuggestions());

        if(!_.isEmpty(showTheseFilters)){
            showTheseFilters.forEach(key=>{
                if([FILTER_CONSTANTS.JOB_NAME,FILTER_CONSTANTS.ASSIGNED_TO,FILTER_CONSTANTS.RETAILER,FILTER_CONSTANTS.RESELLER,FILTER_CONSTANTS.PRODUCT_NAME,FILTER_CONSTANTS.BRAND].includes(key)){
                    fetchSuggestions(key);
                }
            });
        }
        // fetchSuggestions(FILTER_CONSTANTS.JOB_NAME)
        // fetchSuggestions(FILTER_CONSTANTS.ASSIGNED_TO)
        // fetchSuggestions(FILTER_CONSTANTS.RETAILER);
        // fetchSuggestions(FILTER_CONSTANTS.RESELLER);
        // fetchSuggestions(FILTER_CONSTANTS.PRODUCT_NAME);
        // fetchSuggestions(FILTER_CONSTANTS.BRAND);
        // fetchSuggestions(FILTER_CONSTANTS.PRIORITY)

        return () => handleClearFilter();
    }, [])

    const handleChange = (filterKey, value, isNotCheckBoxType) => {
        if(_.isNil(isNotCheckBoxType) || isNotCheckBoxType===false){
            if( filters[filterKey].indexOf(value) !== -1){
                let tempArray = [...filters[filterKey]];
                _.remove(tempArray, (key)=>key===value);
                dispatch(setThisFilter(tempArray, filterKey))
            }
            else{
                dispatch(setThisFilter([...filters[filterKey], value], filterKey))
            }
        }
        else{
            dispatch(setThisFilter(value, filterKey))
        }
    }

    const handleClearFilter = () => dispatch(clearAllFilters())

    const handleClearThisFilter = (filterKey) => dispatch(clearThisFilter(filterKey))

    

    // const stockOptions = [
    //     {value: true, text: 'In Stock'},
    //     {value: false, text: 'Sold Out'},
    //     // {value: 'coming-soon', text: 'Coming Soon'}
    // ]
    // const priorityOptions = [
    //     {value: 'high', text: 'High'},
    //     {value: 'medium', text: 'Medium'},
    //     {value: 'low', text: 'Low'},
    //     // {value: 'coming-soon', text: 'Coming Soon'}
    // ]

    // const scrapperOptions = [
    //     {value: 'all', text: 'All'},
    //     {value: 'on', text: 'On'},
    //     {value: 'off', text: 'Off'}
    // ]

    const renderFilters = () => {
        return(<>
            {/* {renderProductFilter()} */}
            {/* {renderRetailerFilter()} */}
            {/* {renderStockFilter()} */}
            {/* {renderScrapperFilter()} */}
            {/* {renderDiscountFilter()} */}
            {/* {renderResellerFilter()} */}
            {renderTargetFilter()}
            {renderDiscountFilter()}
            {/* {renderJobFilter()} */}
            {/* {renderAssignedFilter()} */}
            {/* {renderPriorityFilter()} */}
        </>)
    }

    const renderFiltersOnCondition = (filterKey) => {
        switch(filterKey){
            case FILTER_CONSTANTS.JOB_STATUS: return renderJobStatusFilter()
            case FILTER_CONSTANTS.TARGET_DAYS: return renderTargetFilter()
            case FILTER_CONSTANTS.JOB_NAME: return renderJobNameFilter()
            case FILTER_CONSTANTS.ASSIGNED_TO: return renderAssignedFilter()
            case FILTER_CONSTANTS.PRIORITY: return renderPriorityFilter()

            case FILTER_CONSTANTS.DISCOUNT : return renderDiscountFilter()
            case FILTER_CONSTANTS.PRODUCT_NAME : return renderProductFilter();
            case FILTER_CONSTANTS.RETAILER : return renderRetailerFilter();
            case FILTER_CONSTANTS.STOCK : return renderStockFilter();
            case FILTER_CONSTANTS.RESELLER : return renderResellerFilter();
            case FILTER_CONSTANTS.BRAND : return renderBrandFilter();
            default: return null
        }
    }

    const renderJobStatusFilter = (filterKey=FILTER_CONSTANTS.JOB_STATUS) => <FilterPanel key={filterKey} isSearchNeeded={false} title='Job Status' options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />              
    // const renderJobStatusFilter = (filterKey=FILTER_CONSTANTS.JOB_STATUS) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Job Status' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />              
    // const renderTargetFilter = (filterKey=FILTER_CONSTANTS.TARGET_DAYS) => <RangeFilter key={filterKey} title='Target Days' min={suggestions[filterKey].suggestions[0]} max={suggestions[filterKey].suggestions[1]} step={1} minTitle={'From'} maxTitle={'To'} rangePrefix={'%'} rangeSelected={filters[filterKey]} onChangeCommitted={(val)=>handleChange(filterKey, val, true)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />
    
    const renderTargetFilter = (filterKey=FILTER_CONSTANTS.TARGET_DAYS) => <DateFilter key={filterKey} title='Target Days' initialDates={suggestions[filterKey].suggestions} rangeSelected={filters[filterKey]} onChangeCommitted={(val)=>handleChange(filterKey, val, true)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />
    
    const renderJobNameFilter = (filterKey=FILTER_CONSTANTS.JOB_NAME) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Job Name' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />              
    const renderAssignedFilter = (filterKey=FILTER_CONSTANTS.ASSIGNED_TO) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Assigned To' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />
    const renderPriorityFilter = (filterKey=FILTER_CONSTANTS.PRIORITY) => <FilterPanel key={filterKey} isSearchNeeded={false} title='Priority' noSort={true} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />              
    

    const renderDiscountFilter = (filterKey=FILTER_CONSTANTS.DISCOUNT) => <RangeFilter key={filterKey} title='Discount' min={suggestions[filterKey].suggestions[0]} max={suggestions[filterKey].suggestions[1]} step={1} minTitle={'From'} maxTitle={'To'} rangeSuffix={'%'} rangeSelected={filters[filterKey]} onChangeCommitted={(val)=>handleChange(filterKey, val, true)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />
    const renderProductFilter = (filterKey=FILTER_CONSTANTS.PRODUCT_NAME) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Product Name' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} optionKey="column_data" textKey="column_data" clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />              
    const renderRetailerFilter = (filterKey=FILTER_CONSTANTS.RETAILER) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} isPreLoaded={suggestions[filterKey].isAllLoaded} optionKey="column_data" textKey="column_data" isSearchNeeded={true} title='Retailer' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />              
    const renderStockFilter = (filterKey=FILTER_CONSTANTS.STOCK) => <FilterPanel key={filterKey} isSearchNeeded={false} title='Stock' options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />              
    const renderResellerFilter = (filterKey=FILTER_CONSTANTS.RESELLER) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} optionKey="column_data" textKey="column_data" isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Reseller' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />              
    const renderBrandFilter = (filterKey=FILTER_CONSTANTS.BRAND) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} optionKey="column_data" textKey="column_data" isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Brand' handleSearch={(keyValue)=>fetchSuggestions(filterKey, keyValue)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />              


    return(<>
        <Grid container className={classes.root} alignContent='flex-start'>
            <Grid item xs={12} container justify='space-between' alignItems='center'>
                <Typography variant="h6" className={classes.heading}>Filters</Typography>
                <Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleClearFilter}>RESET ALL</Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    showTheseFilters && !_.isEmpty(showTheseFilters)
                        ?   showTheseFilters.map(key => renderFiltersOnCondition(key))
                        :   renderFilters()
                }
                {
                    extraFilterProps && !_.isEmpty(extraFilterProps) ?  <FilterPanel {...extraFilterProps} /> : null
                }
                
            </Grid>
        </Grid>
    </>)
}

export default Filters;