import _ from 'lodash';

const colorList = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#33B2DF',
    '#546E7A',
    '#D4526E',
    '#13D8AA',
    '#A5978B',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
    '#2B908F',
    '#F9A3A4',
    '#90EE7E',
    '#FA4443',
    '#69D2E7'
]

const convertHexToRgba = (hexString) => {
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexString)){
        let tempString = hexString.slice(1);
        let tempStringArray = tempString.split('');
        if(tempStringArray.length === 3){
            tempStringArray = [tempStringArray[0], tempStringArray[0], tempStringArray[1], tempStringArray[1], tempStringArray[2], tempStringArray[2]];
        }
        tempString = '0x'+tempStringArray.join('');
        return `rgba(${(tempString>>16)&255}, ${(tempString>>8)&255}, ${tempString&255}, 1)`
    }
    else{
        return `rgba(255, 255, 255, 1)`
    }
}

export function getColor(noOfColors){
    const tempColorArray = [];
    for(let i = 0; i<noOfColors; i++){
        tempColorArray.push(convertHexToRgba(colorList[i%colorList.length]))
    }
    return tempColorArray
}

export const digitToString = digits => {

    function removeZeroes(numberWithZeroes) {
        const number = _.toString(numberWithZeroes);
        const decimalValue = number.split(".")[1];
        if (_.toNumber(decimalValue) === 0) {
            return number.split(".")[0];
        } else if (_.toNumber(decimalValue[1]) === 0) {
            return number.split(".")[0] + "." + decimalValue[0];
        } else {
            return numberWithZeroes;
        }
    }

    const number = _.toNumber(_.isString(digits) ? digits : _.toString(digits));
    if (number / 1000000000 >= 1) {
        return removeZeroes((number / 1000000000).toFixed(2)) + " B";
    } else if (number / 1000000 >= 1) {
        return removeZeroes((number / 1000000).toFixed(2)) + " M";
    } else if (number / 1000 >= 1) {
        return removeZeroes((number / 1000).toFixed(2)) + " K";
    } else if (number >= 0 && number / 1000 < 1) {
        return removeZeroes(number.toFixed(2));
    } else {
        return digits;
    }
};

export function truncate(input, limit=17) {
    if (!_.isNil(input) && input.length > limit)
       return input.substring(0,limit) + '...';
    else
       return input;
 };
