import {ACTION_TYPES} from '../../../constants';


const productsTable = (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_PRODUCTS_TABLE_PENDING: {
            return {
                ...state,
                isFetchingProducts:true,
                isFetchingProductsFailed:false,
                isFetchingProductsError:undefined,
                data:{}
            }
        }
        case ACTION_TYPES.GET_PRODUCTS_TABLE_FULFILLED:{
            return {
                ...state,
                isFetchingProducts:false,
                isFetchingProductsFailed:false,
                isFetchingProductsError:undefined,
                data:action.payload.data.data
            }
        }
        case ACTION_TYPES.GET_PRODUCTS_TABLE_REJECTED: {
            return {
                ...state,
                isFetchingProducts:false,
                isFetchingProductsFailed:true,
                isFetchingProductsError:action.payload,
                data:{}
            }
        }
        default:
            return state;
    }
}

export default productsTable;