import { ACTION_TYPES } from "../../../constants";


export const setStyleCodeFilter = (filter) => ({
    type: ACTION_TYPES.SET_STYLE_CODE_FILTER,
    payload: filter
})

export const setRetailerFilter = (filter) => ({
    type: ACTION_TYPES.SET_RETAILER_FILTER,
    payload: filter
})
export const setProductFilter = (filter) => ({
    type: ACTION_TYPES.SET_PRODUCT_FILTER,
    payload: filter
})

export const setResellerFilter = (filter) => ({
    type: ACTION_TYPES.SET_RESELLER_FILTER,
    payload: filter
})

export const setStockFilter = (filter) => ({
    type: ACTION_TYPES.SET_STOCK_FILTER,
    payload: filter
})

export const setPriceFilter = (filter) => ({
    type: ACTION_TYPES.SET_PRICE_FILTER,
    payload: filter
})

export const setScrapperFilter = (filter) => ({
    type: ACTION_TYPES.SET_SCRAPPER_FILTER,
    payload: filter
})

export const clearAllFilters = () => ({
    type: ACTION_TYPES.CLEAR_ALL_FILTERS
})

export const clearThisFilter = (filterKey) => ({
    type: ACTION_TYPES.CLEAR_THIS_FILTER,
    payload: filterKey
})

// export const setProductType = (filter) => ({
//     type: ACTION_TYPES.SET_PRODUCT_TYPE_FILTER,
//     payload: filter
// })