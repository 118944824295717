import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';


export default function SelectBox(props) {


    const { 
        label, inputClass, isLoading, disabled, isError, errTxt, helperText='', 
        options, onOpen, onBlur, onChange, name, value, size, startAdornment,
        freeSolo, //defaultValue
    } = props;
    const valueReturner = (value) => value ? value.id : "";
    const defaultOption = () => (!_.isEmpty(options) && !_.isEmpty(value)) ? _.filter(options, (option)=>option.id === value)[0] : null;//{name:"", id:""}; 

    return (
      <Autocomplete
        id={name}
        freeSolo={freeSolo || false}
        onOpen={onOpen}
        onBlur={onBlur}
        size={size || "small"}
        onChange={(_, value)=>{ onChange(valueReturner(value), _); }}
        getOptionSelected={(option, value) => option.name === _.get(value, 'name', '')}
        getOptionLabel={option => option.name}
        options={options}
        loading={isLoading}
        value={defaultOption()}
        disabled={isLoading || disabled}
        className={inputClass}
        renderInput={params => (
          <TextField {...params}
              error={isError}
              size={size || "small"}
              name={name}
              helperText={isError && errTxt ? errTxt : helperText}
              label={label} variant="outlined" margin="normal" 
              InputProps={{
                ...params.InputProps,
                disabled:isLoading || disabled,
                readOnly:disabled,
                startAdornment:startAdornment,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth />
        )}
      />
    );
  }