import {call, cancelled, put, takeEvery, takeLatest} from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../../../constants';
import api from '../api';
import _ from 'lodash';
import { hideLoader, showErrorMessage, showLoader } from 'services/loader/actions';
import { showSnackbarWithTimeout } from 'services/snackbar/actions';
import { getErrMsg } from 'utils';
import Axios from 'axios';

function* fetchAllTeams(action){
    const { filters = {}, successCallback, errCallback } = action.payload;
    const cancelSource = Axios.CancelToken.source();
    try{
        const resp = yield call(api.fetchAllTeams, filters, cancelSource);
        yield call(successCallback, resp);
    }
    catch(err){
       yield call(errCallback, err);
    }
    finally{
        if(yield cancelled()){
            cancelSource.cancel('cancelled');
        }
    }
}

function* fetchAllTeamsWatcher(){
    yield takeLatest(SAGA_ACTIONS.FETCH_ALL_TEAMS, fetchAllTeams);
}

function* createTeamUnit(action){
    yield put(showLoader("Please wait creating team unit..."));
    const {teamUnitName, parentTuCode, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.createTeamUnit, teamUnitName, parentTuCode);
        yield put(hideLoader());
        yield call(successCallback, resp);
        yield put(showSnackbarWithTimeout('Team unit created successfully!', 2500));
    }
    catch(err){
        yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* createTeamUnitWatcher(){
    yield takeEvery(SAGA_ACTIONS.CREATE_TEAM, createTeamUnit);
}

function* updateTeamUnit(action){
    yield put(showLoader("Please wait updating team unit..."));
    const {teamUnitCode, teamUnitName, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.updateTeamUnit, teamUnitCode, teamUnitName);
        yield put(hideLoader());
        yield call(successCallback, resp);
        yield put(showSnackbarWithTimeout('Team unit updated successfully!', 2500));
    }
    catch(err){
        yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* updateTeamUnitWatcher(){
    yield takeEvery(SAGA_ACTIONS.UPDATE_TEAM, updateTeamUnit);
}

function* deleteTeamUnit(action){
    yield put(showLoader("Deleting team unit..."));
    const {teamUnitCode, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.deleteTeamUnit, teamUnitCode);
        yield call(successCallback, resp);
        yield put(hideLoader());
        yield put(showSnackbarWithTimeout('Team Unit Deleted Successfully!', 2500));
    }
    catch(err){
       yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* deleteTeamUnitWatcher(){
    yield takeEvery(SAGA_ACTIONS.DELETE_TEAM, deleteTeamUnit);
}

function* addTeamUnitMembers(action){
    yield put(showLoader("Adding user..."));
    const {teamUnitCode, userIds, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.addTeamMembers, teamUnitCode, userIds);
        yield call(successCallback, resp);
        yield put(hideLoader());
        yield put(showSnackbarWithTimeout('User added Successfully!', 2500));
    }
    catch(err){
       yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* addTeamUnitMembersWatcher(){
    yield takeEvery(SAGA_ACTIONS.ADD_TEAM_MEMBERS, addTeamUnitMembers);
}

function* delTeamUnitMember(action){
    yield put(showLoader("Removing user..."));
    const {teamUnitCode, userId, successCallback, dialogCloseBtnCallback} = action.payload;
    try{
        const resp = yield call(api.deleteTeamMember, teamUnitCode, userId);
        yield call(successCallback, resp);
        yield put(hideLoader());
        yield put(showSnackbarWithTimeout('User Removed Successfully!', 2500));
    }
    catch(err){
       yield put(showErrorMessage(getErrMsg(err), "Close", dialogCloseBtnCallback));
    }
}

function* delTeamUnitMemberWatcher(){
    yield takeEvery(SAGA_ACTIONS.REMOVE_TEAM_MEMBER, delTeamUnitMember);
}

//all the saga watchers
export default [
    fetchAllTeamsWatcher(), createTeamUnitWatcher(),
    updateTeamUnitWatcher(), deleteTeamUnitWatcher(),
    addTeamUnitMembersWatcher(), delTeamUnitMemberWatcher()
];
