import React from 'react';
import {Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, Avatar, Icon, ListItemText, ListItemSecondaryAction, IconButton, makeStyles} from '@material-ui/core';
import _ from 'lodash';
import { API_ENDPOINTS } from '../../../../constants';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme=>({
    listRoot:{
        '&.MuiList-root':{
            '& :last-child .MuiListItem-divider':{
                borderBottom: 'none !important'
            }
        }
    },
    primaryTypography:{
        width: '90%',
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    closeButtonInDialog: {
        position: 'absolute',
        top: '6px',
        right: '6px'
    },
}))

const AttachmentsPopUp = (props) => {

    const {data, handleClose} = props;
    const classes = useStyles();
    const authToken = useSelector(state => _.get(state,'session.authToken', ''))

    function downloadURI(fileDetails) {
        var link = document.createElement('a');
        link.download = _.get(fileDetails, 'uploaded_file_name', '');
        link.target="_blank";
        link.href = API_ENDPOINTS.DOWNLOAD_ATTACHMENT_BY_PATH+'?path='+_.get(fileDetails, 'url', '')+'&Authorization=b '+authToken;
        link.click();
    }

    return(
        <Dialog open={!_.isEmpty(data)} maxWidth={'md'} fullWidth onClose={handleClose}>
            <DialogTitle>
                {_.get(data, 'activity_name', '')} Attachments
                <IconButton onClick={handleClose} size='small' className={classes.closeButtonInDialog}>
                    <Icon>close</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <List className={classes.listRoot}>
                    {
                        _.get(data, 'file_summary', []).map((item, idx)=>(
                            <ListItem key={_.get(item, 'file_id', 'list-item-from-file-attachments', idx)} disableGutters divider>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon>insert_drive_file</Icon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={_.get(item, 'uploaded_file_name', 'File')}
                                                secondary={_.get(data, 'created_at', '')}
                                                primaryTypographyProps={{className: classes.primaryTypography}} />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={()=>downloadURI(item)}>
                                        <Icon>save_alt</Icon>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default AttachmentsPopUp;