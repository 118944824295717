import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField, Chip, Icon, makeStyles, Dialog, Grid, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import DataSourceApi from '../../../../services/datasource/api.js';
import { COMPONENT_NAME_FOR_FILTERS } from '../../../../constants';
import { DateRangePicker, Calendar } from 'react-date-range';
import { format, endOfDay, startOfDay } from 'date-fns';
import { defaultDoubleInputRanges } from 'utils/defaultDateRanges.js';
//import {DATA_TYPES, DATA_CONDITIONS} from '../../../constants';

const useStyles = makeStyles(theme => ({
    calendarContainer: {
        // maxWidth: 230,
        '& .MuiPaper-root':{
            position: 'relative',
        },
        '& .MuiIcon-root': {
            // fontSize: theme.typography.pxToRem(16)
        },
        '& .MuiButtonGroup-root': {
            minHeight: 30,
            '& .MuiButton-outlined': {
                // height: '32px',
                background: theme.palette.almostBlack[0],
                // color: theme.palette.almostBlack[800]
            },
            '& .MuiButton-outlined:first-child': {
                // borderRadius: '10px 0px 0px 10px',
                color: theme.palette.almostBlack[800]
            },
            '& .MuiButton-outlined:last-child': {
                // borderRadius: '0px 10px 10px 0px',
            },
            '& .MuiButton-outlined:only-child': {
                // borderRadius: '10px',
            }
        }
    },
    calendarCloseButton:{
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: theme.spacing(0.25)
    },
    calendarWrapper: {
        // position: 'absolute',
        // top: '100%',
        // right: '0%',
        // zIndex: 5,
        padding: theme.spacing(4),
        background: theme.palette.almostBlack[100],
        boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
        // borderRadius: '10px',
        '& .rdrDefinedRangesWrapper': {
            borderRadius: '4px 0px 0px 4px',
            '& .rdrStaticRanges': {
                borderRadius: '4px 0px 0px 0px',
                '& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
                    borderRadius: '4px 0px 0px 0px',
                    '& .rdrStaticRangeLabel': {
                        borderRadius: '4px 0px 0px 0px',
                    }
                }
            }
        },
        '& .rdrCalendarWrapper': {
            borderRadius: '0px 4px 4px 0px',
            '& .rdrDateDisplayWrapper': {
                borderRadius: '0px 4px 0px 0px',
            }
        }
    },
    dateButtonText: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.pxToRem(11),
        letterSpacing: theme.typography.pxToRem(1),
        // fontFamily: '"Roboto"'
    },
    dateInputText: {
        margin: 0,
        cursor: 'pointer',
        '& .MuiOutlinedInput-input': {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.almostBlack[800],
            cursor: 'pointer'
        },
        '& .MuiIcon-root': {
            color: theme.palette.almostBlack[800],
            cursor: 'pointer'
        },
        '& .MuiOutlinedInput-root': {
            marginLeft: `0px !important`
        }
    }
}))

/* GET CONDITIONAL VALUE FIELD BASED ON COLUMN TYPE AND CONDITION */
const FilterValueField = React.memo(({ dataSourceIdx, filterIdx, fieldDataType, condition, component, value, isNumber, isDate, handleFilterValueChange, columnName, dataType, dataSourceId, multiple, label = "Value", disabled }) => {

    const classes = useStyles()
    const [options, setOptions] = useState([]);
    const filteredOptions = (items) => _.map(_.filter(items, item => !_.isNil(item.column_data)), item => item.column_data)
    useEffect(() => {
        DataSourceApi.searchDataSourceForColumnValues(dataSourceId, columnName, "", 0, (data) => setOptions(filteredOptions(data.items)))
    }, [columnName, dataSourceId]);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isDateRangeCalendarOpen, setIsDateRangeCalendarOpen] = useState(false);


    switch (component) {
        case COMPONENT_NAME_FOR_FILTERS.DATE_INPUT.id: {
            function dateString(dateObj) {
                if (dataType === 'DATE') {
                    return [`${format(dateObj, 'yyyy-MM-dd')}`]
                }
                else {
                    return [`${format(startOfDay(dateObj), 'yyyy-MM-dd HH:mm:ss.SSS')}`, `${format(endOfDay(dateObj), 'yyyy-MM-dd HH:mm:ss.SSS')}`]
                }
            }

            const handleDateChange = (dateObj) => { handleFilterValueChange(dataSourceIdx, filterIdx, dateString(dateObj)) };
            function dateLabel(dateObj) {
                return `${format(dateObj, 'dd/MM/yyyy')}`;
            }

            return (
                <FormControl margin="normal" className="filter-list-formcontrol">
                    <TextField
                        InputProps={{ endAdornment: <Icon>date_range</Icon> }}
                        variant='outlined' label='Date Input' fullWidth size='small'
                        value={_.isEmpty(value) ? 'Select a Date' : dateLabel(new Date(value[0]))} onClick={() => setIsCalendarOpen(!isCalendarOpen)} />

                    <Dialog open={isCalendarOpen} maxWidth='md' disableRestoreFocus className={classes.calendarContainer} onClose={() => setIsCalendarOpen(false)}>
                        <Calendar
                            className={classes.calendarWrapper}
                            onChange={item => { handleDateChange(item) }}
                            date={_.isEmpty(value) ? new Date() : new Date(value[0])}
                            direction="vertical" />
                    </Dialog>
                </FormControl>
            )
        }
        case COMPONENT_NAME_FOR_FILTERS.DATE_RANGE_INPUT.id: {

            const formatDate = d => format(d, 'yyyy-MM-dd');
            const formatDateWithTime = d => format(d, 'yyyy-MM-dd HH:mm:ss.SSS');

            function dateArrayObj(dateObj) {
                // console.log("dateObj from dateArrayObj", dateObj)
                if (dataType === 'DATE') {
                    return [formatDate(dateObj.startDate), formatDate(dateObj.endDate)]
                }
                else {
                    return [formatDateWithTime(startOfDay(dateObj.startDate)), formatDateWithTime(endOfDay(dateObj.endDate))]
                }
            }

            function dateLabel(dateObj) {
                return `${format(new Date(dateObj[0]), 'dd-MM-yyyy')} - ${format(new Date(dateObj[1]), 'dd-MM-yyyy')}`
            }

            const handleChange = dateObj => handleFilterValueChange(dataSourceIdx, filterIdx, dateArrayObj(dateObj.dates))

            return (
                <FormControl margin="normal" className="filter-list-formcontrol">
                    <TextField
                        InputProps={{ endAdornment: <Icon>date_range</Icon> }}
                        variant='outlined' label='Date Range' fullWidth size='small'
                        value={_.isEmpty(value) ? 'No Dates Selected' : `${dateLabel(value)}`} onClick={() => setIsDateRangeCalendarOpen(!isDateRangeCalendarOpen)} />

                    <Dialog open={isDateRangeCalendarOpen} maxWidth='md' disableRestoreFocus className={classes.calendarContainer} onClose={() => setIsDateRangeCalendarOpen(false)}>
                        <DateRangePicker
                            className={classes.calendarWrapper}
                            onChange={item => { handleChange(item) }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={_.isEmpty(value) ? [{ key: 'dates', startDate: new Date(), endDate: new Date() }] : [{ key: 'dates', startDate: new Date(value[0]), endDate: new Date(value[1]) }]}
                            // staticRanges={defaultStaticRanges}
                            inputRanges={defaultDoubleInputRanges}
                            // maxDate={new Date()}
                            direction="horizontal" />
                            <IconButton onClick={()=>setIsDateRangeCalendarOpen(false)} className={classes.calendarCloseButton}>
                                <Icon>close</Icon>
                            </IconButton>
                    </Dialog>
                </FormControl>
            )
        }
        case COMPONENT_NAME_FOR_FILTERS.NUMBER_RANGE_INPUT.id: {
            const handleValueChange = (v, idx) => {
                const tempValue = _.isEmpty(value) ? ['0', '0'] : _.isArray(value) ? value : [value];
                if (_.isEmpty(value[0])) {
                    tempValue[0] = '0';
                } else if (_.isEmpty(value[1])) {
                    tempValue[1] = '0';
                }
                tempValue[idx] = `${v}`;
                handleFilterValueChange(dataSourceIdx, filterIdx, tempValue)
            }
            return (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl margin="normal" className="filter-list-formcontrol">
                            <TextField
                                size="small" type='number' variant='outlined' label='From'
                                error={_.toNumber(value[0]) > _.toNumber(value[1])}
                                helperText={_.toNumber(value[0]) > _.toNumber(value[1]) ? '"From" value must be smaller than or equal to "To" value' : ''}
                                onChange={(e) => handleValueChange(e.target.value, 0)}
                                value={!_.isEmpty(value[0])?_.toNumber(value[0]):''} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl margin="normal" className="filter-list-formcontrol">
                            <TextField
                                size="small" type='number' variant='outlined' label='To'
                                error={_.toNumber(value[0]) > _.toNumber(value[1])}
                                helperText={_.toNumber(value[0]) > _.toNumber(value[1]) ? '"To" value must be greater than or equal to "From" value' : ''}
                                onChange={(e) => handleValueChange(e.target.value, 1)}
                                value={!_.isEmpty(value[1])?_.toNumber(value[1]):''} />
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }

        default:
            const getDefaultValue = () => {
                let tempVal = multiple && multiple === true ? value : _.isArray(value) ? value[0] : value;
                return _.isEmpty(tempVal) ? multiple ? [] : '' : tempVal;
            }
            return (
                <FormControl margin="normal" className="filter-list-formcontrol">
                    <Autocomplete
                        multiple={multiple && multiple === true}
                        // id='filter-value'
                        freeSolo
                        disabled={(component && component === COMPONENT_NAME_FOR_FILTERS.AUTOCOMPLETE.id) || (disabled && disabled === true)}
                        limitTags={2}
                        {...(isNumber && isNumber === true ? { onInputChange: (e,v)=>handleFilterValueChange(dataSourceIdx, filterIdx, _.isNumber(v)?`${v}`:v) } : {})}
                        options={options}
                        size="small"
                        getOptionLabel={option => _.toString(option)}
                        onChange={(e, v) => handleFilterValueChange(dataSourceIdx, filterIdx, _.isNumber(v)?`${v}`:v)}
                        value={getDefaultValue()}
                        // value={multiple && multiple === true ? value : _.isArray(value) ? value[0] : value}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        getLimitTagsText={(more) => <Chip variant="outlined" size="small" label={`+${more}`} />}
                        renderInput={params => (
                            <TextField {...params} {...(isNumber && isNumber === true ? { type: 'number' } : {})} variant='outlined' label={label} size='small' /* placeholder='Type to search values' */ />
                        )} />
                    {/* <TextField 
                        disabled={_.isEmpty(condition)} 
                        defaultValue={(value && value[0]) || ""} 
                        onChange={(e)=> { handleFilterValueChange(dataSourceIdx, filterIdx, e.target.value) }}
                        size="small" label="Value" variant="outlined" /> */}
                </FormControl>
            )
    }
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});

FilterValueField.propTypes = {
    dataSourceIdx: PropTypes.number.isRequired,
    filterIdx: PropTypes.number.isRequired,
    handleFilterValueChange: PropTypes.func.isRequired,
    fieldDataType: PropTypes.string,
    condition: PropTypes.string,
    value: PropTypes.array,
}

export default FilterValueField;