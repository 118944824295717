import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, Select, MenuItem, Button, Icon, ClickAwayListener, ButtonGroup } from '@material-ui/core';
import Filters from '../Filters';
import NewFilters from '../FiltersNew';
import ProductTable from '../ProductTable';
import { TIME_FILTERS } from '../../constants';
import _ from 'lodash';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { defaultStaticRanges, defaultInputRanges } from '../../utils/defaultDateRanges';
import { format } from 'date-fns';


const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        minHeight: '100vh',
        height: 'calc(100vh - 0px)',
        paddingTop: 64, //header-height 
        // maxHeight: '100%',
        // paddingTop: '65px',
        '& .MuiOutlinedInput-root': {
            marginLeft: '12px',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px',
            border: `1px solid ${theme.palette.almostBlack[300]}`,
        },
        '& .MuiOutlinedInput-root:hover': {
            '&  .MuiOutlinedInput-notchedOutline': {
                borderRadius: '4px',
                border: `1px solid ${theme.palette.almostBlack[300]}`,
            },
            '& .MuiSelect-root': {
                background: `rgba(0,0,0, 0.04)`,
                transition: `background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
            }
        }
    },
    h1: {
        fontSize: theme.typography.pxToRem(48)
    },
    filterSection: {
        padding: theme.spacing(2, 3, 2, 2),
        boxSizing: 'border-box',
        borderRight: `1px solid rgba(158, 156, 156, 0.23)`,
        background: theme.palette.almostBlack[0],
        height: '100%',
        overflowY: 'auto',
        // minWidth: '260px',
        maxWidth: '300px',
        '&::-webkit-scrollbar,::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px'
        }
    },
    contentSection: {
        padding: theme.spacing(4),
        height: '100%',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar,::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px'
        }
    },
    muiSelectOutlined: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.almostBlack[600],
        fontFamily: ['"Roboto"', '"sans-serif"'].join(','),
        letterSpacing: theme.typography.pxToRem(0.1),
        padding: `7px`,
        paddingRight: `${theme.spacing(4.5)}px !important`,
        border: `1px solid ${theme.palette.almostBlack[300]}`,
        borderRadius: `8px`,
        outline: 0,
        background: theme.palette.almostBlack[0],
        '&::after': {
            content: '" "',
            position: 'absolute',
            height: '94%',
            border: `1px solid ${theme.palette.almostBlack[300]}`,
            top: '0px',
            right: '26px'
        },
        '&.MuiSelect-select:focus': {
            borderRadius: '8px',
            background: theme.palette.almostBlack[0]
        }
    },
    muiSelectIconOutlined: {
        right: `3px`
    },
    tableTitleContainer: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& .MuiTypography-h5': {
            fontSize: theme.typography.pxToRem(22),
            letterSpacing: theme.typography.pxToRem(2),
            color: theme.palette.almostBlack[700]
        }
    },
    layoutTitleIcon: {
        fontSize: theme.typography.pxToRem(22),
        color: theme.palette.almostBlack[700],
        marginRight: theme.spacing(1.5)
    },
    muiButtonRoot: {
        // height: '32px',
        // borderRadius: '10px',
        marginLeft: theme.spacing(2),
        // padding: theme.spacing(0, 1.5),
        '& h6': {
            fontWeight: 600,
            textTransform: 'capitalize',
            lineHeight: theme.typography.pxToRem(14),
            fontSize: theme.typography.pxToRem(14),
            marginLeft: theme.spacing(0.5)
        }
    },
    calendarContainer: {
        maxWidth: 230,
        position: 'relative',
        '& .MuiIcon-root': {
            fontSize: theme.typography.pxToRem(16)
        },
        '& .MuiButtonGroup-root': {
            minHeight: 30,
            '& .MuiButton-outlined': {
                // height: '32px',
                background: theme.palette.almostBlack[0],
                // color: theme.palette.almostBlack[800]
            },
            '& .MuiButton-outlined:first-child': {
                // borderRadius: '10px 0px 0px 10px',
                color: theme.palette.almostBlack[800]
            },
            '& .MuiButton-outlined:last-child': {
                // borderRadius: '0px 10px 10px 0px',
            },
            '& .MuiButton-outlined:only-child': {
                // borderRadius: '10px',
            }
        }
    },
    calendarWrapper: {
        position: 'absolute',
        top: '100%',
        right: '0%',
        zIndex: 5,
        padding: theme.spacing(2),
        background: theme.palette.almostBlack[100],
        boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
        borderRadius: '10px',
        '& .rdrDefinedRangesWrapper': {
            borderRadius: '10px 0px 0px 10px',
            '& .rdrStaticRanges': {
                borderRadius: '10px 0px 0px 0px',
                '& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
                    borderRadius: '10px 0px 0px 0px',
                    '& .rdrStaticRangeLabel': {
                        borderRadius: '10px 0px 0px 0px',
                    }
                }
            }
        },
        '& .rdrCalendarWrapper': {
            borderRadius: '0px 10px 10px 0px',
            '& .rdrDateDisplayWrapper': {
                borderRadius: '0px 10px 0px 0px',
            }
        }
    },
    dateButtonText: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.pxToRem(11),
        letterSpacing: theme.typography.pxToRem(1),
        // fontFamily: '"Roboto"'
    }
}))

var clickedTimes = 0;

const DateRangeInput = ({ initialDates, handleDateChange }) => {

    const classes = useStyles();

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const [dates, setDates] = useState([
        {
            startDate: initialDates && initialDates.startDate ? initialDates.startDate : new Date(),
            endDate: initialDates && initialDates.endDate ? initialDates.endDate : new Date(),
            key: 'dates'
        }
    ]);



    const handleChange = (dateObj) => {
        clickedTimes = clickedTimes + 1;
        const hasStartDateChanged = format(dateObj.dates.startDate, "dd-MM-yyyy") !== format(dates[0].startDate, "dd-MM-yyyy");
        const hasEndDateChanged = format(dateObj.dates.endDate, "dd-MM-yyyy") !== format(dates[0].endDate, "dd-MM-yyyy");

        if (hasStartDateChanged || hasEndDateChanged) {
            setDates([{ key: 'dates', startDate: Object.values(dateObj)[0].startDate, endDate: Object.values(dateObj)[0].endDate }])
        }

        if ((dateObj.dates.key !== 'dates') || (hasStartDateChanged && hasEndDateChanged && clickedTimes >= 2) || (!hasStartDateChanged && clickedTimes >= 2) || (!hasEndDateChanged && clickedTimes >= 2)) {
            if(dateObj.dates.key !== 'custom range'){
                clickedTimes = 0;
                setIsCalendarOpen(false);
            }
        }
    }

    useEffect(() => handleDateChange && handleDateChange(dates[0]), [dates])

    useEffect(()=>{
        if(!isCalendarOpen){
            clickedTimes=0;
        }
    }, [isCalendarOpen, clickedTimes])

    function formatDateForLabel(dateString, isReturnAsDate) {
        //   console.log("dateString", dateString)
        let dt = new Date(dateString);
        let today = new Date()
        let todayStr = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
        let yesterday = new Date((new Date()).setDate((new Date()).getDate() - 1))
        let yesterdayStr = yesterday.getDate() + "/" + (yesterday.getMonth() + 1) + "/" + yesterday.getFullYear()
        let dtStr = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear()
        if (_.isEqual(todayStr, dtStr) && !isReturnAsDate) {
            return 'Today'
        }
        else if (_.isEqual(yesterdayStr, dtStr) && !isReturnAsDate) {
            return 'Yesterday'
        }
        else {
            return dtStr
        }
    }

    function dateLabel(dateObj) {
        if (formatDateForLabel(dateObj.startDate) === formatDateForLabel(dateObj.endDate)) {
            return (
                <Typography variant='subtitle2' className={classes.dateButtonText}>{formatDateForLabel(dateObj.endDate)}</Typography>
            )
        }
        else {
            return (<>
                <Typography variant='subtitle2' className={classes.dateButtonText}>{formatDateForLabel(dateObj.startDate, true)}</Typography>
                -
                <Typography variant='subtitle2' className={classes.dateButtonText}>{formatDateForLabel(dateObj.endDate, true)}</Typography>
            </>)
        }
    }

    return (<>
        {/* <Button onClick={()=>setIsCalendarOpen(!isCalendarOpen)} variant='outlined'>
                                {dateLabel(dates[0])}
                                <Icon>date_range</Icon>
                            </Button> */}
        <ButtonGroup variant='outlined' size='small' color='primary'>
            <Button onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
                {dateLabel(dates[0])}
            </Button>
            <Button onClick={() => setIsCalendarOpen(!isCalendarOpen)} size='small'>
                <Icon>{isCalendarOpen ? 'close' : 'date_range'}</Icon>
            </Button>
        </ButtonGroup>
        {
            isCalendarOpen
                ? <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)}>
                    <DateRangePicker
                        className={classes.calendarWrapper}
                        onChange={item => { handleChange(item) }}
                        // onRangeFocusChange={item => console.log("onshowndate change",item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={dates}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        maxDate={new Date()}
                        direction="horizontal" />
                </ClickAwayListener>
                : null
        }
    </>)
}

const Layout = ({ 
    buttonText, buttonIcon, page, newFilters, additionalTopFilter, hideButton,
    paginationFooterDetails, handleTimeChange, bodyScroll, fixedLayout, 
    initialTime, layoutTitle, layoutTitleIcon, headers, data, isLoading, goToPage, 
    limit, timeFilter, setPageLimit, onClickRow, isCheckBoxNeeded, onCheckBoxClick, 
    keyForCheckBoxSelectedList, keyForCheckBoxDisabling, handleCheckBoxClick, handleAllSelectCheckBoxClick, 
    exportList, exportFxn, sortSettings, handleSearchInputChange, searchQuery, noScrapperFilter, keyForEditToggle, 
    handleCellEdit, wholeData, extraFilterProps, showTheseFilters, clearSelection, handleSelectAllProducts, 
    searchSuggestionsProductType, groupDataBy, fxnToFormatGroupByKey, totalData, enableBorderBottom 
}) => {

    const classes = useStyles();

    return (<>
        <Grid container className={classes.layout} justify="center">
            <Grid md={2} item container className={classes.filterSection}>
                {
                    !_.isNil(newFilters) && newFilters === true
                        ? <NewFilters noScrapperFilter={noScrapperFilter} showTheseFilters={showTheseFilters} extraFilterProps={extraFilterProps} searchSuggestionsProductType={searchSuggestionsProductType} />
                        : <Filters noScrapperFilter={noScrapperFilter} showTheseFilters={showTheseFilters} extraFilterProps={extraFilterProps} searchSuggestionsProductType={searchSuggestionsProductType} />
                }
            </Grid>
            <Grid md={10} item className={classes.contentSection}>
                <Grid item container xs={12} className={classes.tableTitleContainer}>
                    <Grid item xs={4} alignItems='center' container>
                        <Icon className={classes.layoutTitleIcon}>{layoutTitleIcon}</Icon>
                        <Typography variant="h5" component="span" color="textPrimary">{layoutTitle}</Typography>
                    </Grid>
                    <Grid item xs={8} container justify="flex-end" alignItems="center" direction='row'>
                        {
                            !_.isNil(additionalTopFilter) && !_.isEmpty(additionalTopFilter) && additionalTopFilter.map((filter, idx) => filter)
                        }
                        {
                            handleTimeChange && timeFilter
                                ? <Select variant='outlined' value={timeFilter} onChange={(e) => handleTimeChange(e.target.value)} classes={{ outlined: classes.muiSelectOutlined, iconOutlined: classes.muiSelectIconOutlined }}>
                                    {
                                        Object.keys(TIME_FILTERS).map((filterOption) => (
                                            <MenuItem value={filterOption} key={filterOption}>{_.get(TIME_FILTERS, `${filterOption}.label`, '')}</MenuItem>
                                        ))
                                    }
                                </Select>
                                : null
                        }
                        <Grid item container /* xs={2} */ className={classes.calendarContainer} justify='flex-end'>

                            {
                                initialTime && handleTimeChange && <DateRangeInput initialDates={initialTime} handleDateChange={handleTimeChange} />
                            }


                        </Grid>


                        {
                            isCheckBoxNeeded && isCheckBoxNeeded === true && _.isEqual(hideButton, false)
                                ? <Button color='primary' variant='contained' startIcon={buttonIcon} disableElevation size='small' classes={{ root: classes.muiButtonRoot }} onClick={() => exportFxn(exportList)}>
                                    {/* {buttonIcon} */}
                                    {buttonText}
                                    {/* <Typography variant='h6'></Typography> */}
                                </Button>
                                : null
                        }

                    </Grid>
                </Grid>
                <ProductTable goToPage={goToPage}
                    handleSearchInputChange={handleSearchInputChange}
                    searchQuery={searchQuery}
                    paginationFooterDetails={paginationFooterDetails}
                    totalData={totalData}
                    limit={limit}
                    setPageLimit={setPageLimit}
                    page={page}
                    headers={headers}
                    keyForEditToggle={keyForEditToggle}
                    handleCellEdit={handleCellEdit}
                    wholeData={wholeData}
                    data={data}
                    fixedLayout={!_.isNil(fixedLayout) && fixedLayout === true}
                    bodyScroll={bodyScroll}
                    groupDataBy={groupDataBy}
                    fxnToFormatGroupByKey={fxnToFormatGroupByKey}
                    onClickRow={onClickRow}
                    isCheckBoxNeeded={isCheckBoxNeeded}
                    handleCheckBoxClick={handleCheckBoxClick}
                    handleAllSelectCheckBoxClick={handleAllSelectCheckBoxClick}
                    exportList={exportList}
                    sortSettings={sortSettings}
                    clearSelection={clearSelection}
                    enableBorderBottom={enableBorderBottom}
                    handleSelectAllProducts={handleSelectAllProducts}
                    keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
                    keyForCheckBoxDisabling={keyForCheckBoxDisabling}
                    onCheckBoxClick={onCheckBoxClick}
                    isLoading={isLoading} />
            </Grid>
        </Grid>
    </>)
}

export default Layout;