import React, {useRef} from 'react';
import ColorPickerButton from '../../../components/ColorPicker';
import PrimaryButton from '../../../components/PrimaryButton';
import {Grid, Icon, InputBase, Button, ButtonGroup, IconButton, Badge, Typography} from '@material-ui/core';
import ActionContainer from '../../../components/ActionContainer';
import classnames from 'classnames';

const GraphPageHeader = (props) => {
    const {
        title, graphBgColor, graphBorderColor, isFilterAvail,
        openBgColorPicker, openBorderColorPicker, toggleColorPicker,
        changeColorPicker, isVisibleColorPicks, onClickFilterBtn,
        onClickSaveBtn, addOrModifyGraphProp, onCancel, isEditMode, isEditAllowed
    } = props;

    const typeTimeOut = useRef(null);

    const onChangeGraphNameInput = (e) => {
        const value = e.target.value;
        if(typeTimeOut!=null){ 
            clearTimeout(typeTimeOut.current);
        }
        typeTimeOut.current = setTimeout(()=>{
          addOrModifyGraphProp({'graph_name' : value});
        }, 400);
    }

    const cancelBtnTxt = isEditMode ? "Cancel" : "Back";
    const okBtnTxt = isEditMode ? "Save" : "Edit";

    return(
        <Grid container className="graph-header">
            <Grid item xs={12} sm={12} md={6} lg={4} className="graph-title-container">
                <div className="graph-page-title">
                    <IconButton onClick={onCancel} size="small"><Icon className="v-middle">keyboard_backspace</Icon></IconButton> &nbsp;
                    {isEditMode ? <InputBase
                        className={classnames("graph-title-input")}
                        defaultValue={title}
                        placeholder="Graph Name"
                        inputProps={{ 'aria-label': 'Graph Name', readOnly:!isEditMode }}
                        onChange={onChangeGraphNameInput}
                    /> : 
                      <Typography variant="body1">{title}</Typography>
                    }
                </div>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12} className="graph-action-container">
                {
                    isVisibleColorPicks && isEditMode &&
                    <>    
                        <ButtonGroup size="small" className="graph-btn-group" aria-label="small outlined button group">
                            <ColorPickerButton 
                                className="color-picker-btn"
                                size="small"
                                open={openBgColorPicker}
                                handleOpen={()=>{toggleColorPicker("openBgColorPicker")}}
                                handleClose={()=>{toggleColorPicker("openBgColorPicker")}}
                                handleChange={(color)=>{ changeColorPicker('graphBgColor', color.rgb) }}
                                color={graphBgColor}>
                            </ColorPickerButton>
                            <ColorPickerButton 
                                icon="border_color"
                                className="color-picker-btn"
                                open={openBorderColorPicker}
                                handleOpen={()=>{toggleColorPicker("openBorderColorPicker")}}
                                handleClose={()=>{toggleColorPicker("openBorderColorPicker")}}
                                handleChange={(color)=>{ changeColorPicker('graphBorderColor', color.rgb) }}
                                color={graphBorderColor}>
                            </ColorPickerButton>
                            <Button onClick={onClickFilterBtn}>
                                {
                                  isFilterAvail ? 
                                    <Badge color="primary" size="small" variant="dot" className="filter-badge">
                                        <Icon fontSize="small">filter_list</Icon>
                                    </Badge>
                                  : <Icon fontSize="small">filter_list</Icon>
                                }
                            </Button>
                        </ButtonGroup>
                    </>
                }
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={4}>
                <ActionContainer align="right">
                    <Button onClick={onCancel} variant="outlined" size="small">{cancelBtnTxt}</Button>
                    {isEditAllowed && <PrimaryButton size="small" onClick={onClickSaveBtn}>{okBtnTxt}</PrimaryButton>}
                </ActionContainer>
            </Grid>
        </Grid>
       
    )
}

export default GraphPageHeader;
