import { API_ENDPOINTS } from '../../constants';
import Axios from 'axios';

export default {
    fetchLabelData: () => {
        return Axios.get(API_ENDPOINTS.LABEL_DATA, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    saveLabeldata: (formData) => {
        return Axios.put(API_ENDPOINTS.LABEL_DATA, formData, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    addActivityType: (activityType) => {
        return Axios.post(API_ENDPOINTS.ADD_ACTIVITY_TYPE, {activity_type:[activityType]}, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    deleteActivityType: (activityType) => {
        return Axios.delete(API_ENDPOINTS.DELETE_ACTIVITY_TYPE, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json', path: {activityType} }, data:{activity_type:activityType} });
    },
    addJobStatus: (jobStatus) => {
        return Axios.post(API_ENDPOINTS.ADD_JOB_STATUS, {job_status:[jobStatus]}, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    checkJobStatus: (jobStatus) => {
        return Axios.get(API_ENDPOINTS.CHECK_JOB_STATUS, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json', path: {jobStatus} }});
    },
    deleteJobStatus: (jobStatus) => {
        return Axios.delete(API_ENDPOINTS.DELETE_JOB_STATUS, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json', path: {jobStatus} }, data:{job_status:jobStatus} });
    }
}