import React from 'react';
import _ from 'lodash';
import { Avatar, Typography, Badge, Icon } from '@material-ui/core';
import { API_ENDPOINTS} from '../../../../../constants';

export const UserCellRenderer = (props) => {
    const {value, data} = props;
    const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
    const authToken = _.get(props, 'colDef.cellRendererParams.authToken');
    const fullName = _.join([value, _.get(data, 'last_name')], ' '); 
    const profilePic = _.get(data, 'profile_pic_url')    
    const getPicUrl =  (profilePic)=> `${API_ENDPOINTS.VIEW_IMAGE}${encodeURIComponent(profilePic)}&Authorization=Bearer ${authToken}`; 
    const profilePicUrl = profilePic && getPicUrl(profilePic);
    const isEmailVerified = _.get(data, 'is_email_verified');
    return (
        <div className={containerClassName}>
             <Badge 
                  anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'left',
                  }}
                  color={_.get(data, 'is_active') ? "secondary" : "error"}
                  overlap="circle"
                  variant="dot">
               <Avatar size="small" className="avatar-span" component="span" src={profilePic && profilePicUrl}>
                   {value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
               </Avatar>
           </Badge>
            <div className="m-details">
               <Typography variant="body2" component="div">
                   {fullName}
               </Typography>
               <Typography variant="body2" component="div" color="textSecondary">
                   {_.get(data, 'email')}
                   <Icon 
                       style={{color: isEmailVerified ? "green":"#FFAD36", fontSize:'16px'}} 
                       title={isEmailVerified? "Email address is verified.":"Email address is not verified!"}
                       fontSize="small">
                           {isEmailVerified?"verified":"new_releases"}
                   </Icon>
               </Typography>
            </div>
        </div>
    )
};


export const DeleteCellRenderer = (props) => {
    const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
    const onClickDelete = _.get(props, 'colDef.cellRendererParams.onClickDelete');

    const onClickAnchorEl = (event) => { 
        onClickDelete(event, _.get(props, 'data', {}));
    }

    return (
       <div className={containerClassName}>
          <a href="#no" onClick={onClickAnchorEl} title="Remove team member">
            <i className="material-icons">delete</i>
          </a>
       </div>
    )
}