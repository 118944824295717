import {ACTION_TYPES, SAGA_ACTIONS} from '../../../constants';

export const getStyleCodeSuggestions = (offset, keyValue, productType='old') => ({
    type: SAGA_ACTIONS.GET_STYLE_CODE_SUGGESTIONS,
    payload: {search_key: productType==='old'?'objectID':'stylecode', search_value: keyValue?keyValue:'', offset: offset, limit: 10, product_type: productType}
})

export const getRetailerSuggestions = (offset, keyValue,limit) => ({
    type: SAGA_ACTIONS.GET_RETAILER_SUGGESTIONS,
    payload: {column_name: 'source', value: keyValue?keyValue:'', offset: offset, limit: limit, ds_id: '5ea932ef2e2241589d0b66d1'}
})

export const getProductSuggessions = (offset, keyValue, limit) => ({
    type: SAGA_ACTIONS.GET_PRODUCT_SUGGESSIONS,
    payload: {column_name: 'product_name', value: keyValue?keyValue:'', offset: offset, limit: limit, ds_id: '5ea932ef2e2241589d0b66d1'}
})

export const getResellerSuggessions = (offset, keyValue, limit) => ({
    type: SAGA_ACTIONS.GET_RESELLER_SUGGESSIONS,
    payload: {column_name: 'seller_name', value: keyValue?keyValue:'', offset: offset, limit: limit, ds_id: '5ea932ef2e2241589d0b66d1'}
})

export const clearSuggestions = () => ({
    type: ACTION_TYPES.CLEAR_SUGGESTIONS
})