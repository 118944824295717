import { API_ENDPOINTS } from '../../constants';
import Axios from 'axios';

export default {
    suggestions: (query) => {
        return Axios.post(API_ENDPOINTS.FILTER_SUGGESTIONS, query, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    },
    searchSuggestionsInProductsSearchApi: (query) => {
        return Axios.post(API_ENDPOINTS.PRODUCTS_SEARCH, query, { headers:{ 'isAuthRequired': true, 'Content-Type':'application/json' } });
    }
}