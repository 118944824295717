import {ACTION_TYPES, LOADER_MODE} from '../../../constants';


const getDefaultState = () => ({
    showLoader:false,
    loaderMode:LOADER_MODE.LOADING,
    loaderTxt:"",
    loaderDescTxt:"",
    successBtnTxt:"",
    errorBtnTxt:"",
    successBtnCallback:undefined,
    errorBtnCallback:undefined,
    icon:""
})

const loader = (state = getDefaultState(), action) => {
    switch (action.type) {
        case ACTION_TYPES.SHOW_LOADER: {
            return {
                ...state,
                showLoader: true,
                loaderMode:LOADER_MODE.LOADING,
                loaderTxt:action.data.loaderTxt,
                loaderDescTxt:action.data.loaderDescTxt
            }
        }
        case ACTION_TYPES.HIDE_LOADER: {
            return {
                ...state,
                showLoader: false
            }
        }
        case ACTION_TYPES.SHOW_LOADER_SUCCESS: {
            return {
                ...state,
                showLoader: true,
                loaderMode:LOADER_MODE.SUCCESS,
                loaderTxt:action.data.loaderTxt,
                loaderDescTxt:action.data.loaderDescTxt,
                successBtnTxt:action.data.successBtnTxt,
                successBtnCallback:action.data.successBtnCallback
            }
        }
        case ACTION_TYPES.SHOW_LOADER_ERROR: {
            return {
                ...state,
                showLoader: true,
                loaderTxt:action.data.loaderTxt,
                loaderDescTxt:action.data.loaderDescTxt,
                loaderMode:LOADER_MODE.ERROR,
                errorBtnTxt:action.data.errorBtnTxt,
                errorBtnCallback:action.data.errorBtnCallback
            }
        }
        case ACTION_TYPES.SHOW_LOADER_CONFIRM: {
            return {
                ...state,
                showLoader: true,
                loaderTxt:action.data.loaderTxt,
                loaderDescTxt:action.data.loaderDescTxt,
                loaderMode:LOADER_MODE.CONFIRM,
                icon:action.data.icon,
                successBtnTxt:action.data.successBtnTxt,
                successBtnCallback:action.data.successBtnCallback,
                errorBtnTxt:action.data.errorBtnTxt,
                errorBtnCallback:action.data.errorBtnCallback
            }
        }
        default:
            return state;
    }
}

export default loader;