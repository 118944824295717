import React, {useRef, useEffect} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import classnames from 'classnames';
import {Grid, Typography} from '@material-ui/core';

var gridSize = 4;
var rowHeight = gridSize * 8;
var headerHeight = gridSize * 9;

const ResultTable = ({colDefs, rowData, recordsCount, isDisableScrollAnimate}) => {

    const defaultColDef = { resizable: true };
    const containerRef = useRef();

    const autoReSizeAllColumns = (gridColumnApi) => {
        let allColumnIds = [];
        gridColumnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds);
    }

    const onGridReady = params => {
        // const gridApi = params.api;
        const gridColumnApi = params.columnApi;
        autoReSizeAllColumns(gridColumnApi);
    }

    useEffect(()=>{
      if(!isDisableScrollAnimate)
       setTimeout(()=>{
        containerRef.current.scrollIntoView({behavior: 'smooth'});
       }, 200);
    }, [rowData, isDisableScrollAnimate]);

    return (
        <div ref={containerRef} className={classnames("data-view-table-div", "container-no-fluid")}>
          <Grid container className={classnames("p-left-20")} >
            <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <span className={classnames("v-middle", "result-title")}>Query Results</span> */}
            <div className={classnames("v-middle", "result-title")}>
              <Typography variant="subtitle1" className="title-color">
                Query Results
              </Typography>
              <Typography variant="caption" className="title-color">
                Showing {rowData.length} of {recordsCount} Records
              </Typography>
            </div>
              {/* <Icon className="v-middle">storage</Icon> &nbsp; */}
            </Grid>
          </Grid>
          <div>
              <div
                className={classnames("ag-theme-material", "ag-grid", "records-table")}
                style={{
                  height: '500px',
                  width: 'auto'
                }}>
                <AgGridReact
                  columnDefs={colDefs}
                  rowData={rowData}
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  headerHeight={headerHeight}
                  rowHeight={rowHeight}>
                </AgGridReact>
              </div>
          </div>
        </div>
    )
}

export default ResultTable;