import React, {useState, useImperativeHandle} from 'react';
import _ from 'lodash';
import { Avatar, Typography, Badge, Icon } from '@material-ui/core';
import { API_ENDPOINTS} from '../../../constants';

export const withCellRenderState = (CellRenderer) => {
    return React.forwardRef((props, ref) => {
         const [value, setValue] = useState(props.value);
         useImperativeHandle(ref, () => {
             return {
                 refresh: (params) => {
                     if (params.value !== value) {
                         setValue(params.value)
                     }
                     return true;
                 }
             };
         });
         return (
             <CellRenderer {...props}></CellRenderer>
         );
    });
 };
 
 export const ActionCellRenderer = (props) => {
     const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
     const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions');
 
     const onClickAnchorEl = (event) => { 
         onClickMoreOptions(event, _.get(props, 'data', {}));
     }
 
     return (
        <div className={containerClassName}>
           <a href="#no" onClick={onClickAnchorEl} title="Show Actions">
            <i className="material-icons">more_horiz</i>
           </a>
        </div>
     )
 }
 
export const RoleCellRenderer = (props) => {
     const {value} = props;
     const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
     const roleList = _.get(props, 'colDef.cellRendererParams.roleList');
     const displayName = _.join(
                           _.map(
                                _.filter(roleList, role => _.indexOf(value, _.get(role, 'role_id')) > -1), 
                                (role)=> _.startCase(_.get(role, 'role_name'))
                           ),
                           ', '
                        );
     return (
        <div className={containerClassName} title={displayName}>
           {displayName}
        </div>
     )
 }

 
export const StatusCellRenderer = (props) => {
    const {value} = props;
    const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
    return (
       <div className={containerClassName}>
          <span style={{backgroundColor:value?"#82c43c":'#fc5a5a'}}></span>
          {value ? "Active" : "Inactive"}
       </div>
    )
}

export const EmailCellRenderer = (props) => {
    const {value, data} = props;
    const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
    const isEmailVerified = _.get(data, 'is_email_verified');
    return (
       <div className={containerClassName}>
          {value}
          <Icon 
             style={{color: isEmailVerified ? "green":"#FFAD36"}} 
             title={isEmailVerified? "Email address is verified.":"Email address is not verified!"}
             fontSize="small">
                 {isEmailVerified?"verified":"new_releases"}
          </Icon>
       </div>
    )
}
  
export const NameCellRenderer = (props) => {
     const {value, data} = props;
     const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass');
     const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView');
     const authToken = _.get(props, 'colDef.cellRendererParams.authToken');
     const fullName = _.join([value, _.get(data, 'last_name')], ' '); 
     const profilePic = _.get(data, 'profile_pic_url')    
     const getPicUrl =  (profilePic)=> `${API_ENDPOINTS.VIEW_IMAGE}${encodeURIComponent(profilePic)}&Authorization=Bearer ${authToken}`; 
     const profilePicUrl = profilePic && getPicUrl(profilePic);

     if(isRenderMobView){
        const isEmailVerified = _.get(data, 'is_email_verified');
        return (
         <div className={containerClassName}>
              <Badge 
                   anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                   }}
                   color={_.get(data, 'is_active') ? "secondary" : "error"}
                   overlap="circle"
                   variant="dot">
                <Avatar size="small" className="avatar-span" component="span" src={profilePic && profilePicUrl}>
                    {value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
                </Avatar>
            </Badge>
             <div className="m-details">
                <Typography variant="body2" component="div">
                    {fullName}
                </Typography>
                <Typography variant="caption" component="div">
                    {_.get(data, 'email')}
                    <Icon 
                        style={{color: isEmailVerified ? "green":"#FFAD36", fontSize:'16px'}} 
                        title={isEmailVerified? "Email address is verified.":"Email address is not verified!"}
                        fontSize="small">
                            {isEmailVerified?"verified":"new_releases"}
                    </Icon>
                </Typography>
             </div>
         </div>
        )
     }
     else
       return (
         <div className={containerClassName}>
             <Avatar size="small" className="avatar-span" component="span" src={profilePic && profilePicUrl}>
                 {value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
             </Avatar>
             <span className="details-span">
                 {_.startCase(fullName)}
             </span>
         </div>
       );
 };