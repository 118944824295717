import React from 'react';
// import _ from 'lodash';
import { Dialog, DialogTitle, DialogContent, Grid, Typography, makeStyles, Icon, IconButton, Slide } from '@material-ui/core';
import DashboardViewer from '../DashboardViewer/index.js';

const useStyles = makeStyles(theme => ({
    muiDialogContainer: {
        alignItems: 'flex-end'
    },
    muiDialogPaper: {
        position: 'relative',
        boxSizing: 'border-box',
        '& .MuiDialogTitle-root': {
            boxShadow: '0px 0px 2px 0px #b7b7be',
            marginBottom: 2,
            paddingRight: theme.spacing(1)
        },
        '& .MuiDialogContent-root': {
            padding: theme.spacing(0)
        }
    },
    popUpTitle: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    closeButtonInDialog: {
        position: 'absolute',
        top: '6px',
        right: '6px'
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} timeout={1000} {...props} />;
});

const PopUpDashboard = (props) => {

    const { open, handleClose, title = 'Title', dashboardId='', filtersToAdd=[] } = props;
    const classes = useStyles();

    return (
        <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} fullScreen classes={{ paper: classes.muiDialogPaper, container: classes.muiDialogContainer }} >
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item sm={11} md={8} alignItems='center' container>
                        <Typography className={classes.popUpTitle} variant='h5'>{title}</Typography>
                    </Grid>
                    <Grid item sm={1} container alignItems='center' justify='flex-end'>
                        <IconButton onClick={handleClose} /* className={classes.closeButtonInDialog} */>
                            <Icon>close</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DashboardViewer isEditMode={false} isEmbedded={true} filtersToAdd={filtersToAdd} isPopUp={true} accessToken="" dashboardId={dashboardId} />
            </DialogContent>
        </Dialog>
    )
}

export default PopUpDashboard;