import {CHART_TYPE} from '../../constants';
import _ from 'lodash';

export const getGrphDataToRndrFrmResp = ({type}, resp)=>{
    const APEX_CHARTS = [CHART_TYPE.AREA, CHART_TYPE.LINE, CHART_TYPE.BAR, CHART_TYPE.COLUMN, CHART_TYPE.PIE, CHART_TYPE.DONUT];
    const DATINFI = [CHART_TYPE.KPI, CHART_TYPE.TABLE, CHART_TYPE.TEXT, CHART_TYPE.IMAGES];
    const GOOGLE_CHARTS = [CHART_TYPE.SANKEY];
    switch(true)
    {
        case _.indexOf([...APEX_CHARTS, ...GOOGLE_CHARTS], type) > -1 :
            return _.get(resp, 'data.chart_data', {});
        case _.indexOf(DATINFI, type) > -1 :
            return _.get(resp, 'data', {});
        default:
            return resp;
    }
}