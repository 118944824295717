import {ACTION_TYPES} from '../../../constants';

export const showLoader = (loaderText) => ({
    type: ACTION_TYPES.SHOW_LOADER,
    data: {loaderTxt:loaderText}
});

export const hideLoader = () => ({
    type: ACTION_TYPES.HIDE_LOADER
});

export const showSuccessMessage = (loaderTxt, successBtnTxt, successBtnCallback) => ({
    type: ACTION_TYPES.SHOW_LOADER_SUCCESS,
    data:{loaderTxt, successBtnTxt, successBtnCallback}
});

export const showErrorMessage = (loaderTxt, errorBtnTxt, errorBtnCallback) => ({
    type: ACTION_TYPES.SHOW_LOADER_ERROR,
    data:{loaderTxt, errorBtnTxt, errorBtnCallback}
});

export const showConfirmMessage = (loaderTxt, loaderDescTxt, successBtnTxt, 
    successBtnCallback, errorBtnTxt, errorBtnCallback, icon="delete") => ({
        type: ACTION_TYPES.SHOW_LOADER_CONFIRM,
        data:{loaderTxt, loaderDescTxt, successBtnTxt, successBtnCallback, icon, errorBtnTxt, errorBtnCallback}
});