import {put, call, all, takeLatest} from 'redux-saga/effects';
import {ACTION_TYPES, SAGA_ACTIONS, API_ENDPOINTS} from '../../constants';
import Axios from 'axios';
import { watchGetRetailerSuggestions, watchGetProductSuggessions, watchGetResellerSuggessions} from '../searchSuggestions/sagas';
import productApi from '../products/api';
import { watchFetchJobs, watchFetchJobByJobId } from '../jobs/sagas';
import { watchGetSuggestions } from '../filterSuggestions/sagas';
import { watchFetchActivitiesForJob, watchFetchProductsForJob } from '../viewJobData/sagas';
import {watchFetchlabel} from '../labelManagement/sagas';
import UserSagas from '../users/sagas';
import RoleSagas from '../roles/sagas';
import TeamSagas from '../teams/sagas';

function* workGetProductsTable(action) {
    yield put({type: ACTION_TYPES.GET_PRODUCTS_TABLE_PENDING});
    
    try{
        const products = yield call(productApi.getProductsTableData, action.query, action.cancelExecutor);
        yield put({type: ACTION_TYPES.GET_PRODUCTS_TABLE_FULFILLED, payload: products})
    }
    catch(err){
        yield put({type: ACTION_TYPES.GET_PRODUCTS_TABLE_REJECTED, payload: err})
        console.log(err)
    }
}


function* workGetClientId(){
    yield put({ type: ACTION_TYPES.GET_CLIENT_ID_PENDING });
    try {
      const resposeData = yield Axios.get(
        API_ENDPOINTS.GET_CLIENT_ID,
        { headers: { 'isAuthRequired': true, 'Content-Type': 'application/json' }}
      );
      // console.log(resposeData);
      yield put({
        type: ACTION_TYPES.GET_CLIENT_ID_FULFILLED,
        payload: resposeData.data,
      });
    } catch (error) {
      console.log(error);
    }
}

function* workGetAssignTo(action){
    yield put({ type: ACTION_TYPES.GET_ASSIGN_TO_PENDING });
    try {
      const resposeData = yield Axios.get(
        `${API_ENDPOINTS.GET_ASSIGN_TO}/${action.clientId}`,
        { headers: { 'isAuthRequired': true, 'Content-Type': 'application/json' }}
      );
      // console.log(resposeData);
      yield put({
        type: ACTION_TYPES.GET_ASSIGN_TO_FULFILLED,
        payload: resposeData.data,
      });
    } catch (error) {
      console.log(error);
    }
}

function* workGetBpId(){
    yield put({ type: ACTION_TYPES.GET_CLIENT_ID_PENDING });
    try {
      const resposeData = yield Axios.get(
        API_ENDPOINTS.GET_BP_ID,
        {headers: { 'isAuthRequired': true, 'Content-Type': 'application/json' }}
      );
      // console.log(resposeData);
      yield put({
        type: ACTION_TYPES.GET_BP_ID_FULFILLED,
        payload: resposeData.data,
      });
    } catch (error) {
      console.log(error);
    }
}

function* workCreateJobs(action){
    yield put({ type: ACTION_TYPES.CREATE_JOB_PENDING });
    let bpId = action.bpId;
    try {
      const resposeData = yield Axios.post(
        `${API_ENDPOINTS.CREATE_JOB}`,
        action.payload,
        {headers:{ 'isAuthRequired': true, 'path':{bpId}, 'Content-Type': 'application/json' }} 
      );
      // console.log(resposeData);
      yield put({
        type: ACTION_TYPES.CREATE_JOB_FULFILLED,
        payload: resposeData.data,
      });
    } catch (error) {
      console.log(error);
      yield put({
        type: ACTION_TYPES.CREATE_JOB_REJECTED,
        payload: error, 
      });
    }
}

function* watchGetProductsTable(){
    yield takeLatest(SAGA_ACTIONS.GET_PRODUCTS_TABLE, workGetProductsTable);
}
function* watchGetClientId(){
    yield takeLatest(SAGA_ACTIONS.GET_CLIENT_ID, workGetClientId);
}
function* watchGetAssignTo(){
    yield takeLatest(SAGA_ACTIONS.GET_ASSIGN_TO, workGetAssignTo);
}
function* watchGetBpId(){
    yield takeLatest(SAGA_ACTIONS.GET_BP_ID, workGetBpId);
}

function* watchCreateJobs(){
    yield takeLatest(SAGA_ACTIONS.CREATE_JOB, workCreateJobs);
}

export default function* rootSaga() {
    yield all([
        watchGetRetailerSuggestions(),
        watchGetProductsTable(),
        watchGetProductSuggessions(),
        watchGetResellerSuggessions(),
        watchFetchJobs(),
        watchGetSuggestions(),
        watchGetClientId(),
        watchGetAssignTo(),
        watchFetchActivitiesForJob(),
        watchFetchProductsForJob(),
        watchGetBpId(),
        watchCreateJobs(),
        watchFetchJobByJobId(),
        watchFetchlabel(),
        ...UserSagas,
        ...RoleSagas,
        ...TeamSagas
    ])
}

