import React from 'react';
import PropTypes from 'prop-types';
import GraphViewer from 'components/GraphViewer';

const GraphViewWithOptimizer = (props) => {
    return (
        <GraphViewer {...props}>
        </GraphViewer>
    );
}

GraphViewWithOptimizer.propTypes={
   isLoading:PropTypes.bool
}

export default React.memo(GraphViewWithOptimizer, (prevProps, nextProps)=>{ 
    //optimize the rendering logic with loading flag
    return prevProps.isLoading === nextProps.isLoading;
})