import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from '@material-ui/styles';
import {Provider} from 'react-redux';
import store from './store';
import Themes from './themes';
import NetworkService from './services/network';

//HERE IS NETWORK SERVICES
NetworkService.setupInterceptors(store);

const AppWrapper = ({children}) =>{
    return(
        <Provider store={store}>
            <ThemeProvider theme={Themes.light}>
                {children}
            </ThemeProvider>  
        </Provider>
    )
}

export default AppWrapper;

AppWrapper.propTypes = {
   children:PropTypes.node.isRequired
}