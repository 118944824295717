import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Grid, makeStyles, TextField, Typography, Chip, Icon, Button, Dialog, DialogTitle, DialogContent, DialogActions, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import classnames from 'classnames';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { showConfirmMessage, hideLoader, showErrorMessage, showLoader } from 'services/loader/actions';
import labelAdjectiveImg1 from '../../../images/counterfeit-toggle.png';
import LabelManagementApi from '../../../services/labelManagement/api';
import { updateLabelData } from 'services/labelManagement/actions';
import { PERMISSION_TYPE } from 'constants/modules';


const useStyles = makeStyles(theme => ({
    root: {
        // margin: 'auto',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
    },
    titleContainer: {
        padding: theme.spacing(0, 0, 2)
    },
    titleText: {
        letterSpacing: '1px',
        fontSize: 18,
        fontWeight: '500'
    },
    itemRow: {
        padding: theme.spacing(2, 0),
        // borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
        '& .MuiTypography-caption': {
            color: theme.palette.almostBlack[600]
        }
    },
    inputFieldContainer: {
        // maxWidth: 360
    },
    logoRatioClass: {
        width: 320,
        height: 80,
        // margin: '0 auto'
    },
    activityTypeChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    stepperRoot: {
        padding: theme.spacing(0, 0, 3),
        '& .MuiStep-vertical': {
            height: 20,
            display: 'flex',
            '& .MuiStepLabel-root': {
                display: 'flex',
                alignItems: 'center',
                '& .MuiStepLabel-labelContainer': {
                    width: '250px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiStepLabel-label': {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }
            }
        },
        '& .MuiStepConnector-vertical': {
            padding: theme.spacing(0.5, 0),
            marginLeft: 9,
            '& .MuiStepConnector-lineVertical': {
                borderLeftStyle: 'dashed',
                borderLeftWidth: 2
            }
        }
    },
    stepIcon: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        boxSizing: 'border-box'
    },
    firstStep: {
        position: 'relative',
        '&::after': {
            content: '"DEFAULT"',
            fontSize: 11,
            color: theme.palette.almostBlack[600],
            position: 'absolute',
            right: '2px',
            bottom: '0px'
        },
        '& $stepIcon': {
            borderColor: theme.palette.secondary.main
        },
        '& .MuiStepLabel-labelContainer': {
            borderBottom: `1px solid ${theme.palette.almostBlack[400]}`
        }
    },
    finalStep: {
        position: 'relative',
        '&::after': {
            content: '"END STATUS"',
            fontSize: 11,
            color: theme.palette.almostBlack[600],
            position: 'absolute',
            right: '2px',
            bottom: '0px'
        },
        '& $stepIcon': {
            borderColor: theme.palette.error.main
        },
        '& .MuiStepLabel-labelContainer': {
            borderBottom: `1px solid ${theme.palette.almostBlack[400]}`
        }
    },
    buttonContainer: {
        width: 278
    },
    deleteJobStatusIcon: {
        '& .MuiIcon-root': {
            fontSize: '20px'
        }
    },
    infoSpan: {
        fontSize: '16px',
        color: theme.palette.error.light,
        marginLeft: theme.spacing(1),
        cursor: 'pointer'
    },
    popperImage: {
        objectFit: 'cover',
    }
}))

const ActivityTypes = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {data, perms} = props;

    const isCreateAllowed = useMemo(()=>_.get(perms, PERMISSION_TYPE.CREATE, false), [perms]);
    const isDeleteAllowed = useMemo(()=>_.get(perms, PERMISSION_TYPE.DELETE, false), [perms]);

    const [anchorEl, setAnchorEl] = useState(null);

    const [activityTypes, setActivityTypes] = useState([]);
    const [jobStatuses, setJobStatuses] = useState(['NFA']);

    useEffect(()=>{
        setActivityTypes(_.get(data, 'activity_type', []));
    },[data])

    const [dialogProps, setDialogProps] = useState({ options: undefined, successCallBack: undefined, title: undefined, open: false });

    const handleAddNewActivityType = (newActivityType, newUpdatedArray) => {
        dispatch(showLoader('Adding activity type'));
        LabelManagementApi.addActivityType(newActivityType).then(resp=>{
            dispatch(updateLabelData('activity_type', newUpdatedArray));
            setActivityTypes(newUpdatedArray);
            setDialogProps({ ...dialogProps, open: false });
            dispatch(hideLoader());
        }).catch(err=>{
            dispatch(hideLoader());
            dispatch(showErrorMessage(`Something went Wrong!`, 'Close', ()=>dispatch(hideLoader())));
        })
    }

    const handleAddActivityBtnClick = () => {
        setDialogProps({ options: activityTypes, successCallBack: handleAddNewActivityType, title: 'Activity Type', open: true })
    }

    const handleClose = () => setDialogProps({ options: undefined, successCallBack: undefined, title: undefined, open: false });


    const manageDeleteAction = (v, list) => {
        dispatch(showLoader('Please wait...'))
        LabelManagementApi.deleteActivityType(v).then(resp=>{
            let tempList = [...list];
            _.remove(tempList, i => i === v);
            setActivityTypes(tempList);
            dispatch(updateLabelData('activity_type', tempList))
            dispatch(hideLoader());
        }).catch(err=>{
            dispatch(showErrorMessage('Something went wrong!', 'Close', ()=>dispatch(hideLoader())));
        })
    }

    const handleDeleteItem = (list, v, title) => {
        dispatch(showConfirmMessage(
            'Are you sure to delete "' + v + '" from ' + title + '?', 'Delete action is irreversible. So be cautionate of the action.', 
            'Delete', 
            () => manageDeleteAction(v, list), 
            'Cancel', 
            () => dispatch(hideLoader())));
    }

    return (<Grid container item xs={12}>
        <Grid container item xs={12} lg={8} className={classes.root}>
            <Grid container alignItems="center" justify='space-between'>
                <Grid item xs={6}>
                    <Typography variant='subtitle1' className={classes.titleText}>Activity Types</Typography>
                </Grid>
                {/* <Grid item xs={6} container justify='flex-end'>
                    {
                        isEditMode
                            ? <>
                                <Button disableElevation variant='outlined' size='small' color='primary' style={{ marginRight: 12 }} onClick={() => setIsEditMode(false)}>Cancel</Button>
                                <Button disableElevation variant='contained' size='small' color='primary'>Save</Button>
                            </>
                            : <Button disableElevation variant='contained' size='small' color='primary' onClick={() => setIsEditMode(true)}>Edit</Button>
                    }
                </Grid> */}
            </Grid>
        </Grid>
        <Grid container item xs={12} lg={8} className={classnames(classes.root)}>

            <Grid container alignItems='flex-start' className={classes.itemRow}>
                    {
                        activityTypes.map((item, idx) =>
                            <Chip color='primary' variant='outlined' className={classes.activityTypeChip} {...(isDeleteAllowed ? { onDelete: () => handleDeleteItem(activityTypes, item, 'Activity Types', setActivityTypes) } : {})} label={item} key={item + idx} />
                        )
                    }
                    {
                        isCreateAllowed ? <Chip color='primary' icon={<Icon>add</Icon>} className={classes.activityTypeChip} onClick={handleAddActivityBtnClick} label='Add New' clickable /> : null
                    }
            </Grid>
        </Grid>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Paper>
                    <img src={labelAdjectiveImg1} className={classes.popperImage} alt='Example Image for Label Adjective' />
                </Paper>
            </ClickAwayListener>
        </Popper>
        {
            dialogProps && dialogProps.open && <AddActivityTypeDialog open={dialogProps.open} handleClose={handleClose} activityTypes={dialogProps.options} title={dialogProps.title} successCallback={dialogProps.successCallBack} />
        }

    </Grid>);
}

export default ActivityTypes;

const AddActivityTypeDialog = (props) => {

    const { open, handleClose, activityTypes, successCallback, title } = props;
    const [error, setError] = useState('');
    const [value, setValue] = useState('');

    const isAlreadyAvailable = (v) => !_.isEmpty(activityTypes.filter(item => _.isEqual(_.lowerCase(item).replace(/\s+/g, ''), _.lowerCase(v).replace(/\s+/g, ''))));

    const isFirstTime = useRef(true);

    const validate = useCallback(() => {
        if (!isFirstTime.current) {
            if (_.isEmpty(value)) {
                setError(title + ' cannot be empty');
            }
            else {
                if (isAlreadyAvailable(value)) {
                    setError(title + ' cannot be duplicate')
                }
                else {
                    setError('')
                }
            }
        }
    }, [title, value, error, setError])

    useEffect(() => {
        if (!isFirstTime.current) {
            validate();
        } else {
            isFirstTime.current = false;
        }
    }, [value]);

    return (
        <Dialog open={open} onClose={handleClose} md={2} lg={2} fullWidth aria-labelledby="add-activity-form-dialog">
            <DialogTitle id='add-activity-form-dialog'>Add {title}</DialogTitle>
            <DialogContent>
                <TextField autoFocus variant='outlined' margin='none' error={!_.isEmpty(error)} value={value} helperText={error} size='small' onChange={e => setValue(e.target.value)} type='text' fullWidth />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={handleClose}>Cancel</Button>
                <Button color='primary' disabled={!_.isEmpty(error)} onClick={(_.isEmpty(error) && !_.isEmpty(value)) ? () => { successCallback(value, [...activityTypes, value]); handleClose(); } : () => null}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}