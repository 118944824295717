import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Grid, makeStyles, TextField, Typography, Icon, Button, Dialog, DialogTitle, DialogContent, DialogActions, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import classnames from 'classnames';
import File from 'components/File';
import { Form, Field } from 'formik';
import Validator from '../validator';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions';
import labelAdjectiveImg1 from '../../../images/counterfeit-toggle.png';
import LabelManagementApi from '../../../services/labelManagement/api'
import { fetchLabelData } from 'services/labelManagement/actions';
import { PERMISSION_TYPE } from 'constants/modules';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: 'auto',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
    },
    titleContainer: {
        padding: theme.spacing(0, 0, 2)
    },
    titleText: {
        letterSpacing: '1px',
        fontSize: 18,
        fontWeight: '500'
    },
    itemRow: {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
        '& .MuiTypography-caption':{
            color: theme.palette.almostBlack[600]
        }
    },
    inputFieldContainer: {
        // maxWidth: 360
    },
    logoRatioClass: {
        width: 320,
        height: 80,
        // margin: '0 auto'
    },
    faviconRatioClass:{
        width: 48,
        height: 48,
    },
    activityTypeChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    stepperRoot: {
        padding: theme.spacing(0, 0, 3),
        '& .MuiStep-vertical': {
            height: 20,
            display: 'flex',
            '& .MuiStepLabel-root': {
                display: 'flex',
                alignItems: 'center',
                '& .MuiStepLabel-labelContainer': {
                    width: '250px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiStepLabel-label': {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }
            }
        },
        '& .MuiStepConnector-vertical': {
            padding: theme.spacing(0.5, 0),
            marginLeft: 9,
            '& .MuiStepConnector-lineVertical': {
                borderLeftStyle: 'dashed',
                borderLeftWidth: 2
            }
        }
    },
    stepIcon: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        boxSizing: 'border-box'
    },
    firstStep: {
        position: 'relative',
        '&::after': {
            content: '"DEFAULT"',
            fontSize: 11,
            color: theme.palette.almostBlack[600],
            position: 'absolute',
            right: '2px',
            bottom: '0px'
        },
        '& $stepIcon': {
            borderColor: theme.palette.secondary.main
        },
        '& .MuiStepLabel-labelContainer': {
            borderBottom: `1px solid ${theme.palette.almostBlack[400]}`
        }
    },
    finalStep: {
        position: 'relative',
        '&::after': {
            content: '"END STATUS"',
            fontSize: 11,
            color: theme.palette.almostBlack[600],
            position: 'absolute',
            right: '2px',
            bottom: '0px'
        },
        '& $stepIcon': {
            borderColor: theme.palette.error.main
        },
        '& .MuiStepLabel-labelContainer': {
            borderBottom: `1px solid ${theme.palette.almostBlack[400]}`
        }
    },
    buttonContainer: {
        width: 278
    },
    deleteJobStatusIcon: {
        '& .MuiIcon-root': {
            fontSize: '20px'
        }
    },
    infoSpan: {
        fontSize: '16px',
        color: theme.palette.error.light,
        marginLeft: theme.spacing(1),
        cursor: 'pointer'
    },
    popperImage: {
        objectFit: 'cover',
    },
    textField:{
        marginBottom:theme.spacing(2.2),
        '& .Mui-disabled':{
          color:'inherit',
          '& .MuiOutlinedInput-notchedOutline':{
            borderColor:'transparent'
          }
        }
      }
}))

const BasicDetails = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {data, perms} = props;

    const isEditAllowed = useMemo(()=>_.get(perms, PERMISSION_TYPE.EDIT, false), [perms]);

    const [initVals, setInitVals] = useState({});
    const [isEdited, setIsEdited] = useState(false);

    useEffect(()=>{
        // setInitVals({labelTitle:'CounterfeitX2', labelName:'counterfeit', logoImg:'', logoFavicon:''})
        let tempVals = {labelTitle: _.get(data, 'company_name', ''), labelName: _.get(data, 'company_adjective', ''), logoImg: _.get(data, 'logo', ''), logoFavicon: _.get(data, 'favicon', '')}
        setInitVals({...tempVals})
    },[data])

    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditMode, setIsEditMode] = useState(true);

    const handleOpenPopper = (e) => setAnchorEl(anchorEl ? null : e.currentTarget);

    const [dialogProps, setDialogProps] = useState({ options: undefined, successCallBack: undefined, title: undefined, open: false });

    const handleSave = (newData) => {

        const formData = new FormData();

        formData.append('company_name', _.get(newData, 'labelTitle', ''));
        formData.append('company_adjective', _.get(newData, 'labelName', ''));
        formData.append('logo', _.get(newData, 'logoImg'));
        formData.append('favicon', _.get(newData, 'logoFavicon'));

        dispatch(showLoader('Please wait...'))
        LabelManagementApi.saveLabeldata(formData).then(resp=>{
            dispatch(fetchLabelData())
            dispatch(hideLoader());
        }).catch(err=>{
            dispatch(showErrorMessage(`Something went Wrong!`, 'Close', ()=>dispatch(hideLoader())));
        })
    }

    const handleClose = () => setDialogProps({ options: undefined, successCallBack: undefined, title: undefined, open: false });

    const checkForChange = (old, newVal) => {
        if(_.isEqual(old, newVal)){
            setIsEdited(false)
        }
        else{
            setIsEdited(true)
        }
    }

    if(_.isEmpty(initVals)){
        return null
    }
    else{
        return (<Grid container item xs={12}>
            <Validator initVals={initVals} onSubmit={handleSave} checkForChange={(currVal)=>checkForChange(initVals, currVal)}>
                {({ isSubmitting, values, resetForm }) => (
                    <Form id="brandForm">
                        <Grid container item xs={12} lg={8} className={classes.root}>
                            <Grid container alignItems="center" justify='space-between'>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle1' className={classes.titleText}>Label Management</Typography>
                                </Grid>
                                {isEditAllowed && <Grid item xs={6} container justify='flex-end'>
                                    {
                                        isEditMode
                                            ? <>
                                                <Button disableElevation disabled={!isEdited} variant='outlined' size='small' color='primary' type='reset' style={{ marginRight: 12 }} onClick={() => {resetForm(); setIsEdited(false)}}>Cancel</Button>
                                                <Button disableElevation disabled={!isEdited} variant='contained' size='small' color='primary' type='submit'>Save</Button>
                                            </>
                                            : <Button disableElevation variant='contained' size='small' color='primary' onClick={() => setIsEditMode(true)}>Edit</Button>
                                    }
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={8} className={classnames(classes.root)}>

                            <Grid container alignItems='flex-start' className={classes.itemRow}>
                                <Grid item xs={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2'>Company Logo</Typography>
                                        </Grid>
                                        <Grid item xs={10} container>
                                            <Typography variant='caption'>The logo will be used in Header. So use an image with a minimum resolution of 320x80 px and Aspect ratio of 4:1 </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field type="text" name="logoImg">
                                        {({ field, form }) => (
                                            <File
                                                disabled={(!isEditMode || !isEditAllowed)}
                                                value={form.values.logoImg} label="Active"
                                                field={field} form={form} acceptsExts={["png"]}
                                                resolution={[320,80]}
                                                placeholder={"Choose Logo"} imgRatioClass={classes.logoRatioClass} />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <Grid container alignItems='flex-start' className={classes.itemRow}>
                                <Grid item xs={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2'>Company Name</Typography>
                                        </Grid>
                                        <Grid item xs={10} container>
                                            <Typography variant='caption'>Also will be used in Page Title. </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} container align='flex-start' className={classes.inputFieldContainer}>
                                    <Field type='text' name='labelTitle'>
                                        {({ field, form }) => (
                                            <TextField variant='outlined' margin='none' size='small' fullWidth name={field.name}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                value={field.value}
                                                className={classes.textField}
                                                disabled={(!isEditMode || !isEditAllowed)}
                                                helperText={
                                                    form.errors[field.name]
                                                }
                                                error={Boolean(form.errors[field.name] && form.touched[field.name])} />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <Grid container alignItems='flex-start' className={classes.itemRow}>
                                <Grid item xs={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12} container alignItems='center'>
                                            <Typography variant='subtitle2' component='span'>Label Adjective</Typography>
                                            <Icon onClick={handleOpenPopper} className={classes.infoSpan}>info_outlined</Icon>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant='caption' component='span'>To be used in places like toggling between objective products only and all products. </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} container align='flex-start' className={classes.inputFieldContainer}>
                                    <Field type='text' name='labelName'>
                                        {({ field, form }) => (
                                            <TextField variant='outlined' margin='none' size='small' fullWidth name={field.name}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                value={field.value}
                                                className={classes.textField}
                                                disabled={(!isEditMode || !isEditAllowed)}
                                                helperText={
                                                    form.errors[field.name] &&
                                                    form.touched[field.name] &&
                                                    String(form.errors[field.name])
                                                }
                                                error={Boolean(form.errors[field.name] && form.touched[field.name])} />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                            <Grid container alignItems='flex-start' className={classes.itemRow}>
                                <Grid item xs={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2'>Favicon</Typography>
                                        </Grid>
                                        <Grid item xs={10} container>
                                            <Typography variant='caption'>Use 48x48px resolution image. Image must be one of png, jpg, ico formats.</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field type="text" name="logoFavicon">
                                        {({ field, form }) => (
                                            <File
                                                disabled={(!isEditMode || !isEditAllowed)}
                                                value={form.values.logoFavicon} label="Active"
                                                field={field} form={form} acceptsExts={["png"]}
                                                resolution={[48, 48]}
                                                placeholder={""} imgRatioClass={classes.faviconRatioClass} />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>

                        </Grid> 
                    </Form>
                )}
            </Validator>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper>
                        <img src={labelAdjectiveImg1} className={classes.popperImage} alt='Example Image for Label Adjective' />
                    </Paper>
                </ClickAwayListener>
            </Popper>
            {
                dialogProps && dialogProps.open && <AddActivityTypeDialog open={dialogProps.open} handleClose={handleClose} activityTypes={dialogProps.options} title={dialogProps.title} successCallback={(v) => dialogProps.successCallBack(v)} />
            }

        </Grid>);
    }

}

export default BasicDetails;

const AddActivityTypeDialog = (props) => {

    const { open, handleClose, activityTypes, successCallback, title } = props;
    const [error, setError] = useState('');
    const [value, setValue] = useState('');

    const isAlreadyAvailable = (v) => !_.isEmpty(activityTypes.filter(item => _.isEqual(_.lowerCase(item), _.lowerCase(v))));

    const isFirstTime = useRef(true);

    const validate = useCallback(() => {
        if (!isFirstTime.current) {
            if (_.isEmpty(value)) {
                setError(title + ' cannot be empty');
            }
            else {
                if (isAlreadyAvailable(value)) {
                    setError(title + ' cannot be duplicate')
                }
                else {
                    setError('')
                }
            }
        }
    }, [title, value, error, setError])

    useEffect(() => {
        if (!isFirstTime.current) {
            validate();
        } else {
            isFirstTime.current = false;
        }
    }, [value]);

    return (
        <Dialog open={open} onClose={handleClose} md={2} lg={2} fullWidth aria-labelledby="add-activity-form-dialog">
            <DialogTitle id='add-activity-form-dialog'>Add {title}</DialogTitle>
            <DialogContent>
                <TextField autoFocus variant='outlined' margin='none' error={!_.isEmpty(error)} value={value} helperText={error} size='small' onChange={e => setValue(e.target.value)} type='text' fullWidth />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={handleClose}>Cancel</Button>
                <Button color='primary' onClick={(_.isEmpty(error) && !_.isEmpty(value)) ? () => { successCallback([...activityTypes, value]); handleClose(); } : () => null}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}