import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import FilterPanel from './FilterPanel';
import { useSelector, useDispatch } from 'react-redux';
import { getStyleCodeSuggestions, clearSuggestions, getRetailerSuggestions, getProductSuggessions, getResellerSuggessions } from '../../services/searchSuggestions/actions';
import _ from 'lodash';
import { setStyleCodeFilter, clearAllFilters, setRetailerFilter,setProductFilter,setResellerFilter, setStockFilter, setScrapperFilter, setPriceFilter, clearThisFilter } from '../../services/filters/actions';
import RangeFilter from './RangeFilter';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        background: theme.palette.almostBlack[0],
        '& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
            width: '10px',
            background: 'transparent'
        },
        '& ::-webkit-scrollbar-thumb': {
            background: theme.palette.almostBlack[400],
            height: '10px'
        }
    },
    heading:{
        letterSpacing: theme.typography.pxToRem(1),
        color: theme.palette.almostBlack[600],
        padding: `${theme.spacing(1)}px 0px`
    },
    resetSpan: {
        padding: theme.spacing(0.5),
        color: theme.palette.almostBlack[800],
        borderRadius: '5px',
        transition: 'background 0.3s',
        fontFamily: '"Poppins"',
        cursor: 'pointer',
        '&:hover':{
            background: `${theme.palette.primary.main}11`,
            transition: 'background 0.3s'
        }
    },
}))

const Filters = ({noScrapperFilter, extraFilterProps, showTheseFilters, searchSuggestionsProductType='old'}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const styleCodeSuggestions = useSelector(state => state.searchSuggestions.styleCode.suggestions);
    const styleCodeLoading = useSelector(state => state.searchSuggestions.styleCode.isLoading);
    const retailerLoading = useSelector(state => state.searchSuggestions.retailer.isLoading);
    const productLoading = useSelector(state => state.searchSuggestions.product.isLoading);
    const resellerLoading = useSelector(state => state.searchSuggestions.reseller.isLoading);
    const retailerSuggestions = useSelector(state => state.searchSuggestions.retailer.suggestions);
    const productSuggestions = useSelector(state => state.searchSuggestions.product.suggestions);
    const resellerSuggestions = useSelector(state => state.searchSuggestions.reseller.suggestions);
    const isAllProductsLoaded = useSelector(state => state.searchSuggestions.product.isAllLoaded);
    const isAllRetailersLoaded = useSelector(state => state.searchSuggestions.retailer.isAllLoaded);
    const isAllResellersLoaded = useSelector(state => state.searchSuggestions.reseller.isAllLoaded);
    const isStyleCodesLoaded = useSelector(state => state.searchSuggestions.styleCode.isAllLoaded);
    const selectedStyleCodes = useSelector(state => state.filters.objectID);
    const selectedRetailers = useSelector(state => state.filters.retailer);
    const selectedProducts = useSelector(state => state.filters.product);
    const selectedReseller = useSelector(state => state.filters.reseller);
    const selectedStocks = useSelector(state => state.filters.stock);
    const selectedScrapper = useSelector(state => state.filters.scrapper); 
    const selectedPriceRange = useSelector(state => state.filters.price);

    useEffect(()=>{
        // console.log("from filter suggestions",styleCodeSuggestions);
            dispatch(clearSuggestions());
            dispatch(getStyleCodeSuggestions(0, '', searchSuggestionsProductType));
            dispatch(getRetailerSuggestions(0, '', 10));
            dispatch(getProductSuggessions(0, '', 10));
            dispatch(getResellerSuggessions(0, '', 10));
    }, [])

    const handleStyleCodeSelect = (objectID) => {
        if(selectedStyleCodes.indexOf(objectID) !== -1){
            let tempArray = [...selectedStyleCodes];
            _.remove(tempArray, (key)=>key===objectID);
            dispatch(setStyleCodeFilter([...tempArray]))
        }
        else{
            dispatch(setStyleCodeFilter([...selectedStyleCodes, objectID]))
        }
    }

    const handleRetailerSelect = (retailer) => {
        if(selectedRetailers.indexOf(retailer) !== -1){
            let tempArray = [...selectedRetailers];
            _.remove(tempArray, (key)=>key===retailer);
            dispatch(setRetailerFilter([...tempArray]))
        }
        else{
            dispatch(setRetailerFilter([...selectedRetailers, retailer]));
        }
    }

    const handleProductSelect = (retailer) => {
        if(selectedRetailers.indexOf(retailer) !== -1){
            let tempArray = [...selectedRetailers];
            _.remove(tempArray, (key)=>key===retailer);
            dispatch(setProductFilter([...tempArray]))
        }
        else{
            dispatch(setProductFilter([...selectedRetailers, retailer]));
        }
    }
    const handleResellerSelect = (retailer) => {
        if(selectedRetailers.indexOf(retailer) !== -1){
            let tempArray = [...selectedRetailers];
            _.remove(tempArray, (key)=>key===retailer);
            dispatch(setResellerFilter([...tempArray]))
        }
        else{
            dispatch(setResellerFilter([...selectedRetailers, retailer]));
        }
    }

    const handleStockSelect = (stock) => {
        if(selectedStocks.indexOf(stock) !== -1){
            let tempArray = [...selectedStocks];
            _.remove(tempArray, (key)=>key===stock);
            dispatch(setStockFilter([...tempArray]))
        }
        else{
            dispatch(setStockFilter([...selectedStocks, stock]));
        }
    }

    const handleScrapperSelect = (scrapper) => {
            dispatch(setScrapperFilter(scrapper));
    }

    const handlePriceChange = (priceRange) => {
        dispatch(setPriceFilter(priceRange))
    }


    const handleClearFilter = () => {
        dispatch(clearAllFilters())
    }

    const handleClearThisFilter = (filterKey) => {
        dispatch(clearThisFilter(filterKey))
    }

    const handleStyleCodeSearch = (keyValue) => {
        dispatch(getStyleCodeSuggestions(0, keyValue, searchSuggestionsProductType))
    }

    const stockOptions = [
        {value: true, text: 'In Stock'},
        {value: false, text: 'Sold Out'},
        // {value: 'coming-soon', text: 'Coming Soon'}
    ]
    const priorityOptions = [
        {value: 'high', text: 'High'},
        {value: 'medium', text: 'Medium'},
        {value: 'low', text: 'Low'},
        // {value: 'coming-soon', text: 'Coming Soon'}
    ]

    const scrapperOptions = [
        {value: 'all', text: 'All'},
        {value: 'on', text: 'On'},
        {value: 'off', text: 'Off'}
    ]

    const renderFilters = () => {
        return(<>
            {renderProductFilter()}
            {renderRetailerFilter()}
            {renderStockFilter()}
            {renderScrapperFilter()}
            {renderDiscountFilter()}
            {renderResellerFilter()}
            {renderTargetFilter()}
            {renderJobFilter()}
            {renderAssignedFilter()}
            {renderPriorityFilter()}
        </>)
    }

    const renderFiltersOnCondition = (filterKey) => {
        switch(filterKey){
            case 'discount': {
                return renderDiscountFilter()
            }
            case 'product':{
                return renderProductFilter()
            }
            case 'retailer':{
                return renderRetailerFilter()
            }
            case 'stock':{
                return renderStockFilter()
            }
            case 'scrapper':{
                return renderScrapperFilter()
            }
            case 'reseller':{
                return renderResellerFilter()
            }
            case 'Target Days' :{
                return renderTargetFilter()
            }
            case 'Job Filter' : {
                return renderJobFilter()
            }
            case 'assigned' : {
                return renderAssignedFilter()
            }
            case 'priority' : {
                return renderPriorityFilter()
            }

           
            default: {
                return null
            }
        }
    }

    const renderProductFilter = () => <FilterPanel key='ProdcutFilter' title='Product Name' capitaliseText={true} isPreLoaded={isAllProductsLoaded} isSearchNeeded={true} handleSearch={(keyValue)=>dispatch(getProductSuggessions(0, keyValue ,-1))} clearFilter={handleClearFilter} loadMoreFxn={()=>dispatch(getProductSuggessions(0,'',productSuggestions.length))} options={productSuggestions} selectedOptions={selectedProducts} handleCheckBoxClick={handleProductSelect} optionKey='column_data' textKey='column_data' handleClearThisFilter={()=>handleClearThisFilter('product')} isLoading={productLoading} />
    const renderRetailerFilter = () => <FilterPanel key='RetailerFilter' title='Retailers' capitaliseText={true} isPreLoaded={isAllRetailersLoaded} isSearchNeeded={true} handleSearch={(keyValue)=>dispatch(getRetailerSuggestions(0, keyValue , -1))} clearFilter={handleClearFilter} loadMoreFxn={()=>dispatch(getRetailerSuggestions(0, '',retailerSuggestions.length))} options={retailerSuggestions} selectedOptions={selectedRetailers} handleCheckBoxClick={handleRetailerSelect} optionKey='column_data' textKey='column_data' handleClearThisFilter={()=>handleClearThisFilter('retailer')} isLoading={retailerLoading}  hasExtraHeight={true} />
    const renderResellerFilter = () => <FilterPanel key='resellertFilter' title='Reseller' capitaliseText={true} isPreLoaded={isAllResellersLoaded} isAllResellersLoaded={isAllResellersLoaded} isSearchNeeded={true} handleSearch={(keyValue)=>dispatch(getResellerSuggessions(0, keyValue, -1))} clearFilter={handleClearFilter} loadMoreFxn={()=>dispatch(getResellerSuggessions(0,'',resellerSuggestions.length))} options={resellerSuggestions} selectedOptions={selectedReseller} handleCheckBoxClick={handleResellerSelect} optionKey='column_data' textKey='column_data' handleClearThisFilter={()=>handleClearThisFilter('reseller')} isLoading={resellerLoading}  />

   
   
    const renderStockFilter = () => <FilterPanel key='stockFilter' title='Stock' capitaliseText={true} noSort={true} isPreLoaded={false} isSearchNeeded={false} clearFilter={handleClearFilter} options={stockOptions} selectedOptions={selectedStocks} handleCheckBoxClick={handleStockSelect} optionKey='value' textKey='text' handleClearThisFilter={()=>handleClearThisFilter('stock')}  />
    const renderScrapperFilter = () => <FilterPanel key='scrapperFilter' title='Scrapper' noSort={true} isRadio={true} isPreLoaded={false} isSearchNeeded={false} clearFilter={handleClearFilter} options={scrapperOptions} selectedOptions={selectedScrapper} handleCheckBoxClick={handleScrapperSelect} optionKey='value' textKey='text' handleClearThisFilter={()=>handleClearThisFilter('scrapper')}  />
    const renderDiscountFilter = () => <RangeFilter key='discountFilter' title={'Discount'} min={0} max={1000} step={10} minTitle={'Min'} maxTitle={'Max'} rangePrefix={'€'} rangeSelected={selectedPriceRange} onChangeCommitted={handlePriceChange} handleClearThisFilter={()=>handleClearThisFilter('price')} />
    const renderJobFilter = () => <FilterPanel key='jobFilter' isFetching={styleCodeLoading} isPreLoaded={isStyleCodesLoaded} isSearchNeeded={true} title='Job Name' handleSearch={handleStyleCodeSearch} clearFilter={handleClearFilter} loadMoreFxn={()=>dispatch(getStyleCodeSuggestions(styleCodeSuggestions.length, '', searchSuggestionsProductType))} options={styleCodeSuggestions} selectedOptions={selectedStyleCodes} handleCheckBoxClick={handleStyleCodeSelect} optionKey={searchSuggestionsProductType==='new'?'tss_style_code':'objectID'} textKey={searchSuggestionsProductType==='new'?'tss_style_code':'objectID'} handleClearThisFilter={()=>handleClearThisFilter('objectID')} isLoading={styleCodeLoading}  />              
    const renderAssignedFilter = () => <FilterPanel key='assignedFilter' isFetching={styleCodeLoading} isPreLoaded={isStyleCodesLoaded} isSearchNeeded={true} title='Assigned Filter' handleSearch={handleStyleCodeSearch} clearFilter={handleClearFilter} loadMoreFxn={()=>dispatch(getStyleCodeSuggestions(styleCodeSuggestions.length, '', searchSuggestionsProductType))} options={styleCodeSuggestions} selectedOptions={selectedStyleCodes} handleCheckBoxClick={handleStyleCodeSelect} optionKey={searchSuggestionsProductType==='new'?'tss_style_code':'objectID'} textKey={searchSuggestionsProductType==='new'?'tss_style_code':'objectID'} handleClearThisFilter={()=>handleClearThisFilter('objectID')} isLoading={styleCodeLoading}  />              
    const renderPriorityFilter = () => <FilterPanel key='priorityFilter' title='Priority' capitaliseText={true} noSort={true} isPreLoaded={false} isSearchNeeded={false} clearFilter={handleClearFilter} options={priorityOptions} selectedOptions={selectedStocks} handleCheckBoxClick={handleStockSelect} optionKey='value' textKey='text' handleClearThisFilter={()=>handleClearThisFilter('stock')}  />

    const renderTargetFilter = () => <RangeFilter key='targetFilter' title={'Target Days'} minTitle={'From'} maxTitle={'To'} rangePrefix={'Days'} rangeSelected={[0 , 90]} onChangeCommitted={handlePriceChange} handleClearThisFilter={()=>handleClearThisFilter('price')} />

    return(<>
        <Grid container className={classes.root}>
            <Grid item xs={12} container justify='space-between' alignItems='center'>
                <Typography variant="h6" className={classes.heading}>FILTERS</Typography>
                <Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleClearFilter}>RESET ALL</Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    showTheseFilters && !_.isEmpty(showTheseFilters)
                        ?   showTheseFilters.map(key => renderFiltersOnCondition(key))
                        :   renderFilters()
                }
                {
                    extraFilterProps && !_.isEmpty(extraFilterProps) ?  <FilterPanel {...extraFilterProps} /> : null
                }
                
            </Grid>
        </Grid>
    </>)
}

export default Filters;