import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import PermSelectBox from '../PermSelectBox';

SharedList.propTypes = {
  permissions:PropTypes.array,
  isMobileView:PropTypes.bool
};

const useStyles = makeStyles((theme) => ({
    listRoot: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    listItem:{
      paddingLeft:0,
      paddingRight:0
    },
    listItemText:{
      paddingRight:60
    },
    listItemAction:{
      right:theme.spacing(1)
    }
}));

function SharedList({permissions, isMobileView}) {

    const classes = useStyles();

    const onChangePermSelBox = ()=>{

    }

    return (
        <div>
           <List className={classes.listRoot}>
            <ListItem alignItems="flex-start" className={classes.listItem}>
              <ListItemAvatar>
                <Avatar alt="D" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary="Dummy Record"
                className={classes.listItemText}
                secondary={
                  <React.Fragment>
                    dummy@email.com
                  </React.Fragment>
                }
              />
             <ListItemSecondaryAction className={classes.listItemAction}>
                <PermSelectBox 
                  permissions={permissions}
                  className={classes.selectBox}
                  menuIconClassName={classes.selectListIco}
                  showIconOnly={isMobileView}
                  onChange={onChangePermSelBox}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start"  className={classes.listItem}>
              <ListItemAvatar>
                <Avatar alt="D" src="/static/images/avatar/2.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary="Dummy Record 2"
                className={classes.listItemText}
                secondary={
                  <React.Fragment>
                    {"dummy2@email.com"}
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction className={classes.listItemAction}>
                <PermSelectBox 
                  permissions={permissions}
                  showIconOnly={isMobileView}
                  onChange={onChangePermSelBox}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
    );
}

export default SharedList;