import React from 'react';
import { TableCell, withStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    imageDiv: {
        width: '50px',
        height: '50px',
        border: `1px solid ${theme.palette.almostBlack[700]}`,
        marginRight: theme.spacing(2),
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cellWithImageDiv: {
        display: 'flex',
        alignItems: 'center',
    }
}))

const CellWithImage = () => {

    const classes = useStyles();

    return(<>
        <div className={classes.imageDiv}>
            <Typography variant='subtitle1'>Shoe</Typography>
        </div>
    </>)
}

const CustomTableCell = withStyles(theme => ({
    head: {
        // padding: '2px',
        boxSizing: 'border-box',
        '& .MuiCheckbox-root':{
            padding: '2px'
        },
        
    },
    body: {
        height: '30px',
        boxSizing: 'border-box',
        borderBottom: 'none',
        padding: '4px',
        '& .MuiCheckbox-root':{
            padding: '2px'
        },
        '& .MuiCheckbox-colorSecondary.Mui-disabled':{
            color: 'rgba(0,0,0,0.06)'
        },
        '&.MuiTableCell-paddingCheckbox': {
            paddingLeft: '8px'
        }
    }
  }))((props) => {
    
    const classes = useStyles();
    
    return(
    <TableCell classes={props.classes} {...props}>
        {props.isImage ? 
            <div className={classes.cellWithImageDiv} >
                <CellWithImage />
                {props.children}
            </div>
        : props.children}
    </TableCell>
  )});

  export default CustomTableCell;