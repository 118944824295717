import {SAGA_ACTIONS, ACTION_TYPES} from '../../constants';

export const fetchProductsForJob = (query = '') => ({
    type: SAGA_ACTIONS.FETCH_PRODUCTS_FOR_JOB,
    query
});

export const fetchActivitiesForJob = (query = '') => ({
    type: SAGA_ACTIONS.FETCH_ACTIVITIES_FOR_JOB,
    query
});

export const clearJobData = () => ({
    type: ACTION_TYPES.CLEAR_JOB_DATA
})