import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import {Icon, Typography, Hidden} from '@material-ui/core';
import Pluralize from 'pluralize';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    panelDetails:{
        borderTop:'1px solid #f3ebeb'
    }
}));


const FilterExpansionPanel = ({panelTitle, expanded, onChange, panelIcon, filterLen, children}) => {
    const classes = useStyles();
    return(
        <ExpansionPanel expanded={expanded} onChange={onChange}>
            <ExpansionPanelSummary
                expandIcon={<Icon>keyboard_arrow_down</Icon>}
                aria-controls={`${panelTitle} panel`}>
                <Typography className={classes.heading}>
                    <Icon className={classnames("v-middle", "filter-list-ico")}>{panelIcon}</Icon>
                    <span className={classnames("filter-list-title", "v-middle")}>{panelTitle}</span>
                </Typography>
                <Hidden smDown>
                    <Typography className={classes.secondaryHeading}>{`${Pluralize('filter', filterLen, true)} applied`}</Typography>
                </Hidden>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.panelDetails}>
                {children}
            </ExpansionPanelDetails>
      </ExpansionPanel>
    );
}

FilterExpansionPanel.propTypes = {
    panelTitle:PropTypes.string.isRequired,
    panelIcon:PropTypes.string.isRequired,
    filterLen:PropTypes.number.isRequired,
    expanded:PropTypes.bool,
    children:PropTypes.node.isRequired,
    onChange:PropTypes.func
}

export default FilterExpansionPanel;
